import * as React from 'react'
import moment from "moment";
import { Link } from "react-router-dom";
import { contentList } from '../services/api';
import MessageFull from './MessageFull';

class Messages extends React.Component {
    constructor(props) {
        super(props);

        const STORAGE_KEY = "menu-data";

        let menuData = JSON.parse(localStorage.getItem(STORAGE_KEY))

        let section = menuData.design.filter(obj => {
            if ( obj.format && obj.format.title && obj.format.title.text ) {
                return obj.format.title.text === "Notificações"
            }
        })[0] || 0

        this.state = {
            isLoading: false,
            sectionId: section.idSection,
            data: []
        };
    }

    _getContent(id) {
        this.setState({ isLoading: true })
        contentList(id).then(res => {
            if (res.data.success == false) {
                this.setState({ isLoading: false })
                return
            }
            this.setState({
                isLoading: false,
                data: res.data.content
            })
        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }

    componentDidMount() {
        this._getContent(this.state.sectionId)
    }

    renderMessageFull(item) {
        return <MessageFull content={item} />
    }


    render() {
        return (
            <div className="messages-container">
                {
                    this.state.data.map((item, index)  => {
                        return (
                            <div key={index} className="messages-box-container" style={{ borderLeft: '10px solid rgba(0,0,0,0.3)' }}>
                                {/* <Link to={`/messages/${item.idContentItem}`} > */}
                                    <div className="box-read-time">{moment(item.initDate).utc().format('YYYY-MM-DD HH:mm:ss')}</div>
                                    <div className="messages-box-title">
                                        {item.text}
                                    </div>
                                    <div className="messages-box-text">
                                        {item.description}
                                    </div>
                                {/* </Link> */}
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default Messages