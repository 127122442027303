import React, { useEffect } from "react";
import CarouselList from "../../CarouselList";

import "../../../assets/css/MenuRow.scss";
import { getColors, getDesign } from "../../../services/utils";

export default function MenuCarousel({ content }) {
  const getFooterSize = () =>
    getColors("footer").showfooter === "true" ||
    getColors("footer").showfooter === true
      ? { paddingBottom: "5rem" }
      : {};

    const highlightedStyle = { 
      justifyContent: 'space-around' ,
      ...getDesign(1, 'general'), 
      ...getFooterSize(), 
      padding: '0px', 
      minHeight: 'min-content' 
    }

  return (
    <div className="post-list-container" style={{ ...getFooterSize(), ...highlightedStyle }}>
      <CarouselList carousel={content} designSection={1} />
    </div>
  );
}
