import React, { useCallback, useEffect, useRef } from "react";
import "./App.css";
import { BrowserRouter as Router, HashRouter } from "react-router-dom";
import RouterContainer from "./components/RouterContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useClearCacheCtx } from "react-clear-cache";
function App() {
  const { isLatestVersion, emptyCacheStorage, loading } = useClearCacheCtx();  
  const _isMounted = useRef();

  const emptyCache = useCallback(() => {
    if (_isMounted.current) {
      if (!isLatestVersion) {
        emptyCacheStorage();
      }
    }
  }, [isLatestVersion, emptyCacheStorage]);

  useEffect(() => {
    _isMounted.current = true;
    emptyCache();
    return () => {
      _isMounted.current = false;
    };
  }, [emptyCache]);
  
  return (
    <>
      {!loading ? (
        <HashRouter>
          <div className="App">
            <RouterContainer />
            <ToastContainer />
          </div>
        </HashRouter>
      ) : (
        <div className="loader loader-center loader-big" style={{ marginTop: '30vh' }}></div>
      )}
    </>
  );
}

export default App;
