import React from 'react';
import Style from './style.js'
import CartTotal from '../../Components/CartTotal';
import ProgressBar from '../../Components/ProgressBar';
import CartList from '../../Components/CartList';
import PaymentSelector from '../Payment/Components/PaymentSelector';

const ShowCartReview = ( { stage, itens, redirectClickHandler, data, paymentData, nextCallback, goTo}) => {


    return (
        <>
            <ProgressBar stage={stage} firstPinClick={goTo.Itens} secondPinClick={goTo.Payment} />
            <div style={Style.reviewDataWrapperWithOverflow} >
                <CartList itens={itens} 
                    redirectClickHandler={redirectClickHandler}
                />
            </div>
            <PaymentSelector>
                {paymentData.getIcon( {blur: false, onClickHandler: () => { } } )}
            </PaymentSelector>
            
            <CartTotal 
                data={data} 
                nextCallback={nextCallback}
            />
        </>
    )
}

export default ShowCartReview