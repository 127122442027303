
import React from "react";
import { Form, Card, Image, Row, Col } from "react-bootstrap";
import styles from "../ActivateAccount/styles.module.scss";
import "./Obrigado.css";
import BreadCrumbPhase4 from '../../assets/images/breadcrumb04.png';

export default function Obrigado() {
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) || {};

  return (
    <div className={styles.activeAccountPage}>
        <Row className={styles.rowCentered}>
            <Col className="activate-account-col" lg="6" md="6" xs="12">
                <Card className="activate-account-card">
                    <Card.Body className="activate-account-body">
                      <Form>
                        <Form.Group>
                        <Form.Text className={styles.titleText}>
                            Criação de Usuário Realizada
                        </Form.Text>
                          <center><Image src={BreadCrumbPhase4} className={styles.imageStyle} fluid /></center>
                          <Form.Text className={styles.normalText}>
                            <br></br>
                              <h5>Parabéns! Seu usuário foi criado com sucesso!</h5><br></br>
                              Você receberá um email para a cadastramento de sua senha, o que permitirá que você realize o acesso ao App {appDetails.name}.<br></br>
                              Ao receber o email, siga os passos informados e realize o acesso ao App com o link abaixo.<br></br>
                              <br></br>
                              <b>{appDetails.name}</b><br></br>
                              <a href={`http://${appDetails.bucket}`}>{`http://${appDetails.bucket}`}</a> <br></br>
                              <br></br>                              
                          </Form.Text>
                        </Form.Group>
                      </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
  );
}