import React, { useState, useEffect, useCallback,useRef } from 'react';
import { getToken, getUserInfo, setUserInfo} from '../services/api';
import { getColors, getDesign } from '../services/utils';
import jwtDecode from 'jwt-decode';
import {PointsFooter} from '../components/Points'
import Cup from '../assets/images/championsCup.svg';
import UserIcon from '../assets/images/no-gender-user.svg';
import { Button ,Modal } from 'react-bootstrap';
import ImageUpload from '../components/ImageUpload';
import { BsCamera } from 'react-icons/bs';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputMask from 'react-input-mask';
import Form from "react-bootstrap/Form";
import { Formik } from 'formik';
import * as yup from 'yup';
import "moment/locale/pt-br";
import PointDetail from '../components/PointDetail';
import { getGamigication} from '../services/utils';
import { Link } from 'react-router-dom';
import UserPhoto from '../components/UserPhoto';

const Profile = (props) => {
    const form = useRef(null)
    const [isGamification, setGamification] = useState()
    const [show, setShow] = useState(false)
    const [userId, setUserId] = useState();
    const [loading, setLoading] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [error, setError] = useState(false)
    const [totalPoints, setTotalPoints] = useState(0)
    const [feedbackSuccess, setFeedbackSuccess] = useState(false)
    const [feedbackFail, setFeedbackFail] = useState(false)
    const [errMessage, setErrMessage] = useState('');
    useEffect(() => {
        setGamification(getGamigication)
    },[])
   
    const [user, setUser] = useState({
        "idUser": 1,
        "idCompany": 1,
        "email": "",
        "name": "",
        "phone": "",
        "cpf": "",
        "userType": 1,
        "userLicenceTermsAccepted": 1,
        "communicationAccepted": 1,
        "emailNotification": "",
        "smsNotification": "",
        "gestCompany": "",
        "gestCnpj": "",
        "admin": 0,
        "active": 1,
        "photo": ""
    })

    const [userForPhoto, setUserForPhoto] = useState({
        "idUser": 1,
        "idCompany": 1,
        "email": "",
        "name": "",
        "phone": "",
        "cpf": "",
        "userType": 1,
        "userLicenceTermsAccepted": 1,
        "communicationAccepted": 1,
        "emailNotification": "",
        "smsNotification": "",
        "gestCompany": "",
        "gestCnpj": "",
        "admin": 0,
        "active": 1,
        "photo": ""
    })

    const getProfileInfo = () => {
        let token = {}
        try {
            token = jwtDecode(getToken())
        }catch{
            console.log('error on token get')
        }
        return token
    }
  
    const _getUserInfo = useCallback((id)=> {
        setLoading(true)
        if(id){
            getUserInfo(id).then(res => {
                if(res.data.success === false){
                    setLoading(false);
                    setError(true);
                    return
                }
                let userData = {
                    active: res.data.user.active,
                    admin: res.data.user.admin,
                    askPWA: res.data.user.askPWA,
                    communicationAccepted: res.data.user.communicationAccepted,
                    emailNotification: res.data.user.emailNotification,
                    smsNotification: res.data.user.smsNotification,
                    cpf: res.data.user.cpf,
                    department: res.data.user.department,
                    email: res.data.user.email,
                    guestCnpj: res.data.user.guestCnpj,
                    guestCompany: res.data.user.guestCompany,
                    name: res.data.user.name,
                    phone: res.data.user.phone,
                    photo: res.data.user.photo,
                    role: res.data.user.role,
                    userLicenceTermsAccepted: res.data.user.userLicenceTermsAccepted,
                    userType: res.data.user.userType
                }
                setUser(userData)
                setUserForPhoto(userData)
                setLoading(false)
                setError(false)
            }).catch(err => {
                setLoading(false)
                setError(true)
            })
        }
       
    },[])

    const callbackImage = (url) =>{
        let obj = {...userForPhoto,photo:url}
        saveProfile(obj)
        handleModal()
        setUser({...user, photo:url});
    }

    const schema = yup.object().shape({
        name: yup.string().required(),
        cpf: yup.string().matches(
            /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}/gm,'CPF inválido'
        ),
        phone:yup.string().matches(
            /^\([1-9]{2}\) (?:[2-8]|[1-9][1-9])[0-9]{3}\-[0-9]{4}$/gm
        )
    });
  
    const saveProfile = async (data) => {
        setLoadingSave(true)
        await setUserInfo(userId, data).then(res => {    
            if(res && res.data.success === true){
                setFeedbackSuccess(true)
                setTimeout(()=>{
                    setFeedbackSuccess(false)
                    setUserForPhoto(user)
                },5000)
            }else{
                setErrMessage(res.data.message || 'Erro ao salvar dados, tente novamente mais tarde.')
                setFeedbackFail(true)
                setTimeout(()=>{
                    setFeedbackFail(false)
        
                },10000)
            }
            
        
        }).catch(err => {
            setErrMessage('Erro ao salvar dados, tente novamente mais tarde.')

            setFeedbackFail(true)
            setTimeout(()=>{
                setFeedbackFail(false)
    
            },10000)
        })
        
        setLoadingSave(false)
    }
  
 
    useEffect(()=>{
        const _user = getProfileInfo();
        setUserId(_user.idUser)
        _getUserInfo(_user.idUser)
    },[_getUserInfo])

    const handleModal = () => 
    {
        setShow(!show)
    }

    const handleOnClick = (event) => {
        //.current is verification that your element has rendered
        if(form.current){
            form.current.scrollIntoView({ 
               behavior: "smooth", 
               block: "nearest"
            })
        }
    }
    
    return (
        <div className="profile-container" style={{ backgroundColor: '#E5E5E5' , overflowY:'auto'}}>
            <div className="profile-card" style={{flexDirection: 'column', alignItems:'center'}}>
                <div className="profile-photo-perfil">
                    <UserPhoto photo={user.photo}/>
                </div>
                <span style={{fontSize: 18, fontWeight: 700, marginBottom: -5}}>{ user.name }</span>
                {
                  isGamification ? (
                    <>
                        <PointsFooter  
                            points={totalPoints}  
                            style={{color:'#666666', fontSize:12, fontWeight:600 }}
                        />
                        <div 
                            onClick={handleOnClick} 
                            className="btn botao-login btn-edit-perfil" 
                            style={{ backgroundColor: getColors('menu').secondColor, marginBottom:10,  marginTop:10  }}
                        >
                            Editar perfil
                        </div>
                    </>
                  ) : null 
                }
            </div>
            <PointDetail id={userId} setTotalPoints={setTotalPoints} exist={isGamification}/>
            <section style={{marginTop: 10, paddingBottom:"30%"}}>
                <span className="title-profile-edit">
                    Dados pessoais
                </span>
                <div className="profile-card section-profile">
                    <div className="conteudo-icon" style={{position:'relative'}} >
                        <Button title="Carregar Foto" className="button-carrega-modal-avatar-perfil"  onClick={handleModal}>Teste</Button>
                        <div className="editar-perfil-icon" id="root">
                            <UserPhoto photo={user.photo} onClick={handleModal}/>
                            
                            <Modal 
                                show={show}
                            >
                                <ImageUpload closeHandle={handleModal} callbackImage={callbackImage} />
                            </Modal> 
                        </div>
                        <BsCamera size={30} className="bs-camera" />
                        <div style={{paddingTop: 16, fontSize: 16,  fontWeight: 600, color: '#666', display: 'flex'}}>Editar Foto</div>
                    </div>
                    {
                        loading ? 
                        <div className="loader loader-center loader-big"></div>
                        :
                        error ?
                        <strong style={{ color: 'orangered' }}> Error retrieving user data </strong>
                        : 
                        <Formik
                            validationSchema={schema}
                            onSubmit={(values) => {
                                setUser(
                                    {
                                        ...user,
                                        name: values.name,
                                        email: values.email,
                                        cpf: values.cpf,
                                        phone:values.phone,
                                        communicationAccepted : values.notificacao ? 1 : 0,
                                        emailNotification : values.notificacaoEmail ? 1 : 0 ,
                                        smsNotification : values.smsNotification ? 1 : 0 ,
                                    }
                                )
                                saveProfile( {
                                    ...user,
                                    name: values.name,
                                    email: values.email,
                                    cpf: values.cpf,
                                    phone:values.phone,
                                    communicationAccepted : values.notificacao ? 1 : 0,
                                    emailNotification : values.notificacaoEmail ? 1 : 0 ,
                                    smsNotification : values.smsNotification ? 1 : 0 ,
                                });
                            }}
                            initialValues={{
                                name: user.name,
                                email: user.email,
                                cpf: user.cpf,
                                phone:user.phone,
                                notificacao: user.communicationAccepted === 1,
                                notificacaoEmail: user.emailNotification === 1,
                                notificacaoSms: user.smsNotification === 1,
                              
                            }}                           
                            >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                setFieldValue,
                                errors,
                            }) => (
                                <Form noValidate  onSubmit={handleSubmit}>
                                    <div ref={form}  className="profile-edit section-form">
                                        <Form.Group className="profile-edit-item section-form-edit">
                                            <Form.Label className="profile-edit-label">Nome</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={values.name}
                                                className="form-control edit-login-input" 
                                                onChange={handleChange} 
                                                style={{ marginBottom: 10 }}
                                                onBlur={handleBlur}
                                                isInvalid={touched.name && errors.name}
                                            />
                                        </Form.Group>
                                        <Form.Group  className="profile-edit-item section-form-edit">
                                            <Form.Label className="profile-edit-label"> E-mail</Form.Label>
                                            <Form.Control  
                                                disabled
                                                name="email"
                                                value={values.email}
                                                className="form-control edit-login-input button-disabled" 
                                                style={{ marginBottom: 10,opacity: 0.5,cursor: "no-drop" }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="profile-edit-item section-form-edit">
                                            <Form.Label className="profile-edit-label">CPF</Form.Label>
                                            <CpfCnpj
                                                name="cpf"
                                                value={values.cpf}
                                                onBlur={handleBlur}
                                                className={`form-control edit-login-input ${touched.cpf && errors.cpf ? 'is-invalid' : ''}`}
                                                placeholder="Digite o CPF"
                                                onChange={handleChange} 
                                                style={{ marginBottom: 10 }}
                                                isInvalid={touched.cpf && errors.cpf}
                                            />
                                        </Form.Group>
                                        <Form.Group className="profile-edit-item section-form-edit">
                                            <Form.Label className="profile-edit-label">Telefone</Form.Label>
                                            <InputMask
                                                mask="(99) 99999-9999" 
                                                value={values.phone}
                                                className={`form-control edit-login-input ${touched.phone && errors.phone ? 'is-invalid' : ''}`}
                                                style={{ marginBottom: 10 }}
                                                onChange={(e) => setFieldValue('phone',e.target.value)} 
                                                isInvalid={touched.phone && errors.phone}

                                            />
                                        </Form.Group>
                                        <div className="profile-edit-item section-form-edit">
                                            <Form.Group controlId="formBasicCheckbox">
                                                <Form.Check
                                                    name="notificacao"
                                                    className="espaco-checkbox-principal"
                                                    type="checkbox" label="Aceito receber notificações." 
                                                    onChange={handleChange} 
                                                    checked={values.notificacao}
                                                    onBlur={handleBlur}

                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicCheckbox">
                                                <Form.Check 
                                                    className="espaco-checkbox"
                                                    name="notificacaoEmail"
                                                    disabled={user.communicationAccepted === 0 || user.smsNotification === 1}
                                                    type="checkbox" label="Notificar por e-mail." 
                                                    onChange={handleChange} 
                                                    checked={values.notificacaoEmail}
                                                    onBlur={handleBlur}

                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicCheckbox">
                                                <Form.Check
                                                    name="notificacaoSms"
                                                    className="espaco-checkbox td-mg"
                                                    disabled={user.communicationAccepted === 0 || user.smsNotification === 0}
                                                    type="checkbox" 
                                                    label="Notificar por SMS." 
                                                    onChange={handleChange} 
                                                    checked={values.notificacaoSms}
                                                    onBlur={handleBlur}

                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="edit-button-group form-edit-button">
                                            <button  type="submit" disabled={loadingSave}
                                            style={{  position: 'relative', backgroundColor:  getColors('menu').secondColor, color: '#fff', borderColor: getColors('menu').secondColor}} className="btn form-edit-button-flex">
                                            { !loadingSave ?  <span>Salvar</span>  : <div className="loader" style={{display: 'inline-block', position:'relative', right:0}}  /> }
                                            </button>
                                        </div>
                                        {feedbackSuccess && 
                                            <div className="edit-button-group">
                                                <button 
                                                style={{ flex: 1, position: 'relative'}} className="btn btn-outline-success" disabled>
                                                    <span>Perfil atualizado com sucesso</span> 
                                                </button>
                                            </div>
                                        }
                                        {feedbackFail && 
                                            <div className="edit-button-group">
                                                <button 
                                                style={{ flex: 1, position: 'relative'}} className="btn btn-outline-danger" disabled>
                                                    <span>{errMessage}</span> 
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </Form>
                            ) }
                        </Formik>
                    }
                </div>
            </section>
        </div>
    )
}

export default Profile