const Style = {
    reviewDataWrapperWithOverflow:{
        padding: '10px',
        position: 'relative',
        width: '100%',
        overflowY: 'auto',
        height: '100%',     
        display: 'flex',
        justifyContent: 'center',
    }
}

export default Style;