import { getColors } from '../../../../services/utils';

const getStyle = (getDesign) => {
    const Style = {
        lojinhaScreenWrapper:{
            ...getDesign("general"),
            backgroundColor: '#E5E5E5',
            width: '100%',
            minHeight: 'calc(100vh - 54px)',
            maxHeight: 'calc(100vh - 54px)',
            position: 'relative',
            display: 'flex',
            zIndex: 1500,
        },
        lojinhaHeaderSearchWrapper:{
        }
    }
    return Style;
}


export default getStyle;