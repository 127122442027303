import React from 'react'
import playCircleTwotone from "@iconify-icons/ant-design/play-circle-twotone";
import { getDesign } from "../../services/utils";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { FaCheck } from 'react-icons/fa';
import ProgressBar from '../ProgressBar';

const VideoCard = ({pathname, id,  title, description, index, imgsrc, concluded, progress, idSection, duration }) => {

    const titleStyle = {
        color : getDesign(idSection,"firstItemText","color",true) ? getDesign(idSection,"firstItemText","color",true) :  '#9d52bd', 
        fontWeight:'bold'
    }

    return (
        <Link
            to={ pathname }
            key={id}
            style={{ color: "transparent" }}
            className="ytplaylist-content  ytplayList-box-shadow"
        >
            <div className="ytplaylist-body">
                <div className="ytplaylist-icon-play">
                    <Icon icon={playCircleTwotone} color="#fff" width="70" />
                </div>
            <div className="ytplaylist-image" style={{ overflow: "hidden" }} >
                <img src={imgsrc} style={{ width: "100%", margin: "-10% 0", objectFit: "cover", }} alt="img" />
            </div>
            <div className="ytplaylist-data" style={{ width: "100%", color:'#999', ...getDesign(idSection,"firstItemText") }} >
                <span className="ytplaylist-subtitle" style={titleStyle} >
                    {title}
                </span>
                <span className="ytplaylist-description">
                    {description}
                </span>
                {
                  (duration > 0) ? (
                    <ProgressBar percent={progress} completed={concluded} legend={false}/>
                  ) : null
                }                              
            </div>
          </div>
        </Link>
      );
}

export default VideoCard