import React, {useState, useEffect} from 'react';
import '../../assets/css/CardGrid.css';
import {useHistory} from 'react-router-dom';
import Rate from '../Rate';
import styles from './styles.module.scss'

import {getDesign, getColors} from '../../services/utils';

const Card = ({item, idSection, link = null}) => {
  const history = useHistory();
  function handleClick(item) {
    item = link ? link : `/content/${item.idContentItem}?idSection=${idSection}`;
    history.push(item);
  }
  const image = (item.imageSecondary && item.imageSecondary !== 'null') ? item.imageSecondary : item.image
  return (
    <>
      <div onClick={() => handleClick(item)} className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 alignCards" style={{padding: 0, margin: 0}}>
        <div className="card cardGrid" 
          style={{
            height: 300, 
            border: `3px solid ${getDesign(idSection, 'general', 'backgroundColor',true)}`, 
            backgroundColor: `${getDesign(idSection, 'general', 'backgroundColor',true)}` 
          }} 
        >
          <div
            className="front"
            style={{
              backgroundImage: item.imageStyle !== 'icon' ? `url( ${item.image} )` : '',
              backgroundColor: `${item.color}`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: item.imageStyle === 'icon' ? 'contain' : 'cover',
            }}
          />
          <div className={styles.fixCss} >
            {('hybrid' === item.imageStyle && (image !== 'null')) && (
              <div className="div-card-hibridy">
                <img src={image} style={{width: 'auto', height: 'auto'}}/>
              </div>
            )}
            {('icon' === item.imageStyle && (image !== 'null')) && (
              <div className="div-card-hibridy">
                <img src={image} style={{maxHeight: '56px', maxWidth: '57px', width: 'auto', height: 'auto'}}/>
              </div>
            )}
            <div className={styles.titleFrontCard}>
              <div className={styles.titleSpan} style={{...getDesign('itemText', idSection)}} >
                <span>{item.text}</span>
              </div>

              {item.description && (
                <div style={{fontSize: '1.1rem', ...getDesign('itemDescription', idSection)}} className="subtitle-span">
                  <span>{item.description !== 'null' && item.description}</span>
                </div>
              )}
            </div>

            {typeof item.rating !== 'undefined' && (
              <div>
                <Rate
                  classNameContainer={styles.rating}
                  rateAction={{
                    justifyContent: 'center', 
                    position: 'absolute', 
                    height: 50, 
                    zIndex: 101, bottom: 0, width: '100%'}}
                  rateContainer={{justifyContent: 'flex-start', position: 'relative', height: 40, zIndex: 101, bottom: 0, width: '100%'}}
                  stars={item.rating}
                  numberVotes={item.numberVotes}
                  id={item.idContentItem}
                  click={true}
                  classNameRatingContainer={styles.classNameRatingContainer}
                />
              </div>
            )}
            {item.progress ? (
              <>
                <div className="div-progress">
                  <div className={'card-progress'} style={{width: `${item.progress}%`}} />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
