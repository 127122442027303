import React, { Component } from 'react'
import '../index.css'
import { withRouter, Redirect } from "react-router-dom";
import { getSectionByType } from '../services/utils'
import MenuPrincipal from './MenuPrincipal';

class MenuExpand extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redirect: false,
            expanded: false,
            searchExpanded: false,
            onFocus: false
        }
    }

    expand(){
        this.setState({ expanded: !this.state.expanded })
    }

    _getMenuData(){
        return getSectionByType(1)
    }

    render(){
        if (this.state.redirect) {
            return <Redirect to='/login' />;
        }

        let menuData = this._getMenuData()

        return(
            <div style={{ height: 0, zIndex: 100 }}>
                <MenuPrincipal
                    expanded={this.state.expanded}
                    expand={()=> this.setState({ expanded: !this.state.expanded })}
                    menuData={menuData.menuOption}
                />
            </div>
        )
    }
}

const withRouterAndRef = Wrapped => {
    const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
      <Wrapped ref={forwardRef} {...otherProps} />
    ))
    const WithRouterAndRef = React.forwardRef((props, ref) => (
      <WithRouter {...props} forwardRef={ref} />
    ))
    const name = Wrapped.displayName || Wrapped.name
    WithRouterAndRef.displayName = `withRouterAndRef(${name})`
    return WithRouterAndRef
  }

export default withRouterAndRef(MenuExpand)