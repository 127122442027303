import { getColors } from '../../../../../../services/utils';

const getStyle = (getDesign) => {
    const Style = {
        lojinhaPaymentWays:{
            backgroundColor: '#fff',
            width: '100%',
            height: '115px',
            padding: '0px 10px',
            textAlign: 'center',
            marginBottom: '10px',
        },
        lojinhaPaymentWaysSpan:{
            ...getDesign('itemText'),
            fontSize: '13px',
            fontWeight: '700', 
        },
        lojinhaPaymentWaysList:{
            marginTop: '5px',
        }
    }
    return Style;
}

export default getStyle;