import { width } from 'dom-helpers';
import React from 'react';
import { getColors, getDesign} from '../services/utils';

const ModalContinueMedia = ( {startMedia, continueMedia, mediaType, idParentSection, show}) => {

    const restartMediaText = {
        audio: 'Ouvir desde o inicío?',
        video: 'Assistir desde o inicío?',
    }

    const continueMediaText = {
        audio: 'Continuar de onde parou?',
        video: 'Continuar de onde parou?',
    }

    const Style = {
        WrapperPage:{
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: 'absolute',
            backgroundColor: '#ffffff80',
            zIndex: '5',
            display: 'flex',
            justifyContent: 'center',
        },
        Wrapper:{
            backgroundColor: '#fff',
            width: 'calc(100vw - 40px)',
            maxWidth: '400px', 
            maxHeight: '185px',
            margin: '20px',
            borderRadius: '6px',
            border: `1px solid ${getColors('menu').firstColor}`,
        },
        Header:{
            backgroundColor: getColors('menu').firstColor,
            borderRadius: '6px 6px 0 0',
        },
        messageWrapper:{
            padding: '20px',
        },
        buttonsWrapper:{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 20px 0px 20px',
        },
        button:{
            ...getDesign(idParentSection,'itemDescription'),
            backgroundColor: getColors('menu').secondColor,
            borderRadius: '6px',
            border: 'none',
            padding: '8px 10px',
            marginBottom: '20px',
            color: '#fff',
        }
    }
    
    return show ? (
        <div style={Style.WrapperPage}>
            <div style={Style.Wrapper}>
                <div className="blast-topbar" style={Style.Header}></div>
                <div style={Style.buttonsWrapper}>
                    <button style={Style.button} onClick={startMedia}>
                        {restartMediaText[mediaType]}
                    </button>
                    <button style={Style.button} onClick={continueMedia}>
                        {continueMediaText[mediaType]}
                    </button>
                </div>
            </div>
        </div>
    ) : (<></>)

}


export default ModalContinueMedia;