import React from "react";
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';

import { getUploadList } from '../services/api'
import { getFooterSize, getDesign, getColors } from '../services/utils';
import { updateFooterStatus, updateHeaderStatus } from '../actions';

import downloadIcon from '../assets/images/download-icon.svg';
import Loader from './Loader';
import '../assets/css/UploadUser.scss'
import { recordTracking } from '../services/api'
import moment from "moment";
class UploadUser extends React.Component {
  constructor(props) {
    super(props)
    this._isMounted = true
    this.state = {
      data: [],
      isLoading: false,
      idContentItem: 0,
      timeStart: moment().utc().format('YYYY-MM-DD HH:mm:ss')
    }
  }

  async getUploadById(id) {
    
    this.setState({ isLoading: true })

    const parentSection =  new URLSearchParams(this.props.location.search).get("idSection")

    const getDataById = (data) => {
      return data.find(({ idUserUpload }) => idUserUpload === Number(id))
    }
    const result  = await getUploadList(parentSection);
    if (this._isMounted) {
			try {
        if(!result.data?.success){
          this.setState({ isLoading: false })
          return;
        }
        const list = getDataById(result.data.data)
        this.setState({ data: list, isLoading: false })
		
			} catch (err) {
        console.log('Error:', err)
        this.setState({  isLoading: false })
			} 
		}
    
  }

  checkFileType(file) {
    return file ? file.includes('image') ? 'image' : file.includes('video') ? 'video' : 'others' : 'others'
  }
  _recordTracking(){
    let pathParams =  new URLSearchParams(this.props.location.search)
    let parentSection = pathParams.get("idSection")
    recordTracking(this.state.timeStart, this.state.idContentItem, 1, parentSection).then(res => {
        }).catch(err => {
            this.setState({ isLoading: false })
        })
  }
  componentDidMount() {
    const { match: { params: { id } }, updateHeaderStatus, updateFooterStatus, idSection } = this.props 
    this.setState({idContentItem:id })
    this.getUploadById(id)
    this.setState({timeStart:moment().utc().format('YYYY-MM-DD HH:mm:ss')});
    updateFooterStatus(getDesign(idSection, 'general')?.showBottomMenu || 'true')
    updateHeaderStatus(getDesign(idSection, 'general')?.showTopBar || 'true')
  }
  componentWillUnmount(){
    this._recordTracking();
    this._isMounted = false;
  }

  render() {
    const {  idSection } = this.props
    const { data: { title, name, description, mimetype, url } } = this.state

    return (
      <div className="upload-user" style={{ ...getDesign(idSection, 'general'), ...getFooterSize() }}>
        {
          this.state.isLoading ?
            <Loader />
            :
            <div className="upload-user__content">
              <i className="upload-user__genericIcon"></i>
              <div className="upload-user__header">
                <h3 style={getDesign(idSection, 'itemText')}>{title}</h3>
                <p style={getDesign(idSection, 'itemDescription')}>por {name}</p>
              </div>

              <div className="upload-user__image">
                {
                  this.checkFileType(mimetype) === 'image' &&
                  <img src={url} alt="" />
                }
                {
                  this.checkFileType(mimetype) === 'video' &&
                  <ReactPlayer
                    url={url}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                }
              </div>

              <div className="upload-user__info">
                <p style={getDesign(idSection, 'itemDescription')}>{description}</p>
              </div>
              {
                this.checkFileType(mimetype) === 'others' &&
                <div className="upload-user__file">
                  <p>Para baixar o arquivo anexado ao post clique no botão abaixo.</p>
                  <a href={url} download style={{ backgroundColor: getColors('menu').firstColor, color: 'white' }}>
                    <img width="16" src={downloadIcon} alt="" />
                    DOWNLOAD
                  </a>
                </div>
              }
            </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(UploadUser);