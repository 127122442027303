import { getColors } from '../../../../../../services/utils';

const Style = {
    buttonAddToCart:{
        backgroundColor: getColors('menu').secondColor,
        color: '#fff',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '0',
        border: 'none',
        width: '100%',
        height: '60px',
        fontWeight: '700',
        fontSize: '18px',
    }
}

export default Style;