import React, { useEffect, useState } from "react";
import {Rating} from "./Rating";
import { getDesignById, getFooterSize } from '../services/utils'
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';
import CommentsChat from "./CommentsChat";

function ContentSinglePage({data, updateHeaderStatus, updateFooterStatus, idSection}) {

  function _getDesign(item, only = false, valueOnly = false){
    let design = getDesignById(idSection)
  
    if(!design[item]){return null} // return null if theres no item specified
  
    if(only){ // parameter only means it will only return the property specified
      if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified
  
      design = { [only]:design[item][only] }
      return design
    }
  
    return design[item]
  }

  useEffect(() => {
    updateFooterStatus(_getDesign('general')?.showBottomMenu || 'true')
    updateHeaderStatus(_getDesign('general')?.showTopBar || 'true')
  }, [])

  const [ isDesktop, setIsDesktop ] = useState(window.innerWidth > 768)
  window.addEventListener("resize", () => setIsDesktop(window.innerWidth > 768))

  return (
    <div style={{ backgroundColor: data.backgroundColor, padding: isDesktop ? '2rem 0 7rem' : '0' }}>
      <div
        style={ 
          isDesktop 
            ? 
              { 
                boxShadow: '0px 2px 2px #aaa', 
                margin: '0 14%', 
                padding: '2rem 2rem 0', 
                minHeight: 'calc(100vh - 54px )', 
                ...getFooterSize() 
              } 
            : 
              { 
                margin: '0', 
                padding: '2rem 1rem 0',  
                minHeight: 'calc(100vh - 54px )', 
                ...getFooterSize() 
              } 
        }
      >
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '0 12px 0 0' }}><Rating stars={data.rating} numberVotes={data.numberVotes} /></div>
        <div dangerouslySetInnerHTML={{__html: data.content}} />
        <Rating id={data.idContentItem} userStar={data.vote} />
        {
          data.idChatRoom &&
          <CommentsChat room={data.idChatRoom} />
        }
        
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(ContentSinglePage);