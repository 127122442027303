import React, { useState, useEffect } from 'react';
import './style.css'
import { getCartItensLojinha, removeProductsToCartLojinha } from '../../../../services/api.js';
import Loader from '../../../../components/Loader';
import PageDefault from '../../Components/PageDefault';
import { useHistory } from 'react-router-dom';
import ShowCartItens from '../../Components/ShowCartItens';
import ShowCartPayment from '../../Components/ShowCartPayment';
import ShowCartReview from '../../Components/ShowCartReview';
import ShowCartFinish from '../../Components/ShowCartFinish';

const Cart = () => {

    const detalhesIniciais = {
        idShoppingCart: '', 
        idUser: '', 
        totalPrice: '', 
        numItens: 0
    }

    const Phase = {
        Loading: 0,
        Cart: 1,
        Payment: 2,
        Finish: 3,
        Over: 4,
    }
    
    const history = useHistory();
    const [cartItens, setCartItens] = useState([]);
    const [cartDetail, setCartDetails] = useState(detalhesIniciais);
    const [stage, setStage] = useState(Phase.Loading);
    const [paymentData, setPaymentData] = useState();
    const [paymentStatus, setPaymentStatus] = useState(false);

    function getPaymentData(PaymentObject){
        setPaymentData(PaymentObject);
    }
    
    async function getCartItens(){
        const result = await getCartItensLojinha();
        if(result.status === 200 && result.data.success){
            setCartItens([
                ...result.data.itens,
            ]);
            setCartDetails(
                result.data.shoppingCart,
            );
        }
    }
    
    async function removeItem(idOffer){
        const result = await removeProductsToCartLojinha(idOffer);
        if(result.status === 200 && result.data.sucess){
            getCartItens();
        }

    }

    function redirect(produto){
        const stringDados = JSON.stringify(produto);
        localStorage.setItem('produtoSelecionado', stringDados);
        history.push('/detalheslojinha');
    }

    async function next(){
        if(stage === Phase.Loading){ setStage(Phase.Cart) }
        else if(stage === Phase.Cart){ setStage(Phase.Payment) }
        else if(stage === Phase.Payment){
            if( Boolean(paymentData) && paymentData.isValid()){
                setStage(Phase.Finish) 
            }
        }
        else if(stage === Phase.Finish){ 
            
            const paymentResult = await paymentData.getPayment();
            setPaymentStatus(paymentResult);
            setStage(Phase.Over);
            getCartItens();
              
        }
        else if(stage === Phase.Over){ }
    }

    const goTo = {
        Itens: () => {
            setStage(Phase.Cart)
        },
        Payment: () => {
            setStage(Phase.Payment)
        }
    }

    useEffect( () => {
        getCartItens();
    },[]);

    useEffect( () => {
        if(stage === Phase.Loading && cartDetail !== detalhesIniciais){
            next();
        }
    },[cartItens]);

    return (
        <PageDefault quantidadeItensCarrinho={cartDetail.numItens}>
            <div className="lojinha-content-cart-wrapper">
                { stage === Phase.Loading && ( <Loader 
                /> ) }
                { stage === Phase.Cart && ( <ShowCartItens 
                    stage={stage}
                    itens={cartItens} 
                    removeClickHandler={removeItem} 
                    redirectClickHandler={redirect}
                    data={cartDetail} 
                    nextCallback={next}
                /> ) }
                { stage === Phase.Payment && ( <ShowCartPayment 
                    stage={stage}
                    data={cartDetail} 
                    nextCallback={next} 
                    getPaymentData={getPaymentData}
                    goTo={goTo}
                /> ) }
                { stage === Phase.Finish && ( <ShowCartReview 
                    stage={stage}
                    itens={cartItens}
                    redirectClickHandler={redirect}
                    nextCallback={next} 
                    data={cartDetail}
                    paymentData={paymentData}
                    goTo={goTo}
                /> )}

                { stage === Phase.Over && ( <ShowCartFinish 
                    stage={stage}
                    result={paymentStatus}
                    errorHandler={goTo.Payment}
                /> )}

            </div>
        </PageDefault>
    )
}

export default Cart;