import { getColors } from '../../../../../../services/utils';

const getStyle = (getDesign) => {
    const Style = {
        lojinhaCartFinishContent:{
            ...getDesign('itemText'),
            width: '240px',
            textAlign: 'center',
            padding: '10px 0px',
        },
        lojinhaCartErrorButton:{
            ...getDesign('itemText'),
            color: '#fff',
            textAlign: 'left',
            backgroundColor: getColors('menu').firstColor,
            height: '100%',
            border: 'none',
            padding: '10px',
            borderRadius: '6px',
        }
    }
    return Style;
}

export default getStyle;