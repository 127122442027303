
import { getColors } from '../../../../services/utils';

const getStyle = (getDesign) => {
    const Style = {
        lojinhaProgressBar:{
            height: '80px',
            width: '100%',
            marginBottom: '10px',
            marginTop: '35px',
            maxWidth: '700px',
        },
        lojinhaProgressPinWrapper:{
            zIndex: '10',
            position: 'absolute',
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
        lojinhaProgressBarWrapper:{
            zIndex: '9',
            position: 'absolute',
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        },
        lojinhaProgressBarImg:{
            height: '44px',
            position: 'relative',
        },
        lojinhaProgressBarText:{
            display: 'flex',
        },
        lojinhaProgressBarTextDivOn:{
            ...getDesign('itemDescription'), 
            color: getColors('menu').firstColor, 
            fontWeight: 700, 
            textAlign: 'center', 
            width: 'calc(100% / 3)',
            lineHeight: '94%',
        },
        lojinhaProgressBarTextDivOff:{
            ...getDesign('itemDescription'), 
            color: '#b4b4b4', 
            textAlign: 'center',
            width: 'calc(100% / 3)',
            lineHeight: '94%',
        }
    }
    return Style;
}

export default getStyle;