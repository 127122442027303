/* eslint-disable */
import React, { Component } from 'react';
import io from 'socket.io-client';
import { getToken, chatLoadRoom, chatSaveRoom } from '../services/api';
import '../assets/css/CommentsChat.css';
import { getColors, getDesignById } from '../services/utils';
import SendLogo from '../assets/images/send-live.svg';
import jwtDecode from 'jwt-decode';

class CommentsChat extends Component {

  constructor(props) {
    super(props)

    this.state = {
      user: {},
      messages: [],
      newMessage: '',
      isDesktop: false,
      isLoading: false,
      error: false
    }

    this.socket = io('https://chat.bedigital.net.br:3002');
    this.messagesToSave = [];
    this.setNewMessage = this.setNewMessage.bind(this);
    this.handleSendMsg = this.handleSendMsg.bind(this);
    this.resize = this.resize.bind(this);
  }

  getProfileInfo() {
    let token = {}
    try {
      token = jwtDecode(getToken())
    } catch {
      console.error('error on token get')
    }
    return token
  }

  resize() {
    this.setState({ isDesktop: window.innerWidth > 800 });
  }

  async _chatLoadRoom(room) {
    this.setState({ isLoading: true });
    await chatLoadRoom(room).then(res => {
      if (res && res.data && res.data.success == false) {
        console.error("error: ", res.data);
        this.setState({ isLoading: false, error: true });
        return
      } else {
        if(res.data.data.length > 0) {
          this.setState({ messages: res.data.data[res.data.data.length - 1].messages });
        }
      }
    }).catch(err => {
      console.error('catch err', err);
      this.setState({ isLoading: false, error: true })
    })
  }

  async componentDidMount() {
    this.resize();
    window.addEventListener("resize", this.resize);
    this.setState({
      user: this.getProfileInfo()
    })
    if(this.props.room !== null && this.props.room !== undefined) {
      await this._chatLoadRoom(this.props.room);
      this.socket.on('connect', async () => {
        this.socket.emit('room', this.props.room);
      });

      this.socket.on('NEW_MESSAGE_FROM_GEN_SERVER', message => {
        this.AddNewMsg(message);
      })
    }
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  _getDesign(item, only = false, valueOnly = false) {
    let design = getDesignById(this.props.idSection)

    if (!design[item]) { return null } // return null if theres no item specified

    if (only) { // parameter only means it will only return the property specified
      if (valueOnly) { return design[item][only] } // parameter valueOnly means it will only return the property value if specified

    design = { [only]: design[item][only] }
        return design
    }

    return design[item]
}

  scrollToBottom = () => {
    this.msgEnd.scrollIntoView({ behavior: 'smooth' })
  }

  handleKeyDown = (event) => {
    if (event.key == "Enter") {
      this.handleSendMsg(event)
    }
  }

  AddNewMsg(message) {
    let m = this.state.messages
    m.push(message)
    this.setState(
      {
        messages: m
      }, () => { })
  }

  render() {
    const { isDesktop } = this.state
    let msgList;

    msgList = this.state.messages.map((m, k) => (

        <div className="chat-container-mine" key={k}>
          <div className={1 == 1 ? 'chat-mine-comments' : 'chat-mine-comments chat-mine-comments-arrow'} style={{ justifyContent: 'center', backgroundColor: getColors('menu').firstColor }} key={`chat-${m.user}-${m.moment}`}>
            <span className="msg" style={{ fontSize: 16, ...this._getDesign('itemText') }}>
              {m.message}
              <div style={{ fontSize: '0.7rem', textAlign: 'end', color: 'lightgray' }}>
              {m.user} - {m.moment}
              </div>
            </span>
          </div>
        </div>
    ));

    return (
      <div className="chat-comments-container" style={{ backgroundColor: getColors('menu').secondColor }}>
        <div className="chat-comments-msg">
          <h3 className="title">comentários</h3>
          <div className="chat-comments-chatbox">
            {msgList}
            <div ref={msgEnd => { this.msgEnd = msgEnd; }} />
          </div>
        </div >
        <div className="bottomBar">
          <input
            className="chatInput"
            style={{ backgroundColor: getColors('menu').fourthColor, ...this._getDesign('itemText') }}
            type="text"
            placeholder="Digite um comentário..."
            onChange={this.setNewMessage}
            onKeyDown={this.handleKeyDown}
            value={this.state.newMessage} />
          <img onClick={this.handleSendMsg} src={SendLogo} className="chat-send-logo"
            alt="send" />
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    this.socket.close();
  }

  setNewMessage(event) {
    this.setState({
      newMessage: event.target.value
    })
  }

  sendMsgToserver(usr, msg) {
    if (msg !== '') {
      let now = new Date();
      let msgObj = {
        "moment": `${this.dateFormat(now.getDate())}-${this.dateFormat(now.getMonth())}-${now.getFullYear()} ${this.dateFormat(now.getHours())}:${this.dateFormat(now.getMinutes())}:${this.dateFormat(now.getSeconds())}`,
        "idUser": this.state.user.idUser,
        "user": usr,
        "message": msg,
        "room": this.props.room
      };
      this.socket.emit('NEW_MESSAGE_FROM_GEN_CHAT', msgObj);
      this.prepareDataToSave(msgObj);
    }
  }

  handleSendMsg(event) {
    event.preventDefault();
    this.sendMsgToserver(this.state.user.name, this.state.newMessage)
    this.setState({
      newMessage: ''
    });
  }

  dateFormat(num) {
    return (num < 10) ? `0${num}` : num;
  }

  prepareDataToSave(msg) {
    let d = new Date();

    const data = {
      "data": [
        {
          "date": `${this.dateFormat(d.getDate())}-${this.dateFormat(d.getMonth())}-${d.getFullYear()}`,
          "messages": [
            { "moment": msg.moment, "user": this.state.user.idUser, "message": msg.message }
          ]
        }
      ]
    };

    if (msg.user !== 'Bot' || msg.user !== '') {
      this._chatSaveRoom(data);
    }
  }

  _chatSaveRoom(data) {
    chatSaveRoom(this.props.room, data).then(res => {
      if (!res || res.data.success === false) {
        console.error('success false')
        return
      }
    }).catch(err => {
      console.error('save chat err ', err);
    })
  }

}

export default CommentsChat;