import React, { useState, useEffect } from 'react';
import './style.css'
import PageDefault from '../../Components/PageDefault';
import ProductDetails from '../../Components/ProductDetails';
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';
import { getCartItensLojinha } from '../../../../services/api.js';

const Detail = () => {

    const dadosIniciais = {
        aquired: '',
        content: '',
        description: '',
        idOffer: '', 
        idProduct: '', 
        imageURL: '', 
        price: '', 
        title: '', 
    }

    const [choosen, setChoosen] = useState(dadosIniciais);
    const hasChoosen = (choosen !== dadosIniciais);
    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    
    const [isAcquired, setIsAcquired] = useState(false);
    const [isInCart,  setIsInCart] = useState(false);

    async function getChoosen(){
        const objString = await localStorage.getItem('produtoSelecionado');
        try{
            const json = JSON.parse(objString);
            setChoosen(json);
        }catch(e){}
    }
    async function getCartProducts(){
        const result = await getCartItensLojinha();
        if(result.status === 200 && result.data.success){
            result.data.itens.map( (item) => {
                if(item.idOffer === choosen.idOffer){
                    setIsInCart(true);
                }
            });
        }
    }

    useEffect( () => {
        getChoosen();
    } , []);

    useEffect( () => {
        setIsAcquired(Boolean(choosen.aquired));
        getCartProducts();
    }, [choosen])

    return (
        <PageDefault headerClickPath="/cartlojinha">
            <div className="lojinha-detail-content-wrapper">
                { hasChoosen && (<ProductDetails choosen={choosen} getDesign={getDesign} isAcquired={isAcquired} isInCart={isInCart} />)}
            </div>
        </PageDefault>
    );
}

export default Detail;