import React from "react";
import { contentList } from '../services/api';
import { getDesignById, getColors } from '../services/utils';
import { Link } from 'react-router-dom';
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
import NotFoundIcon from '../assets/images/notfound.svg';
import SearchIcon from '../assets/images/search-icon.svg';
import InputIcon from '../assets/images/input.svg';
import {Rating} from "./Rating";

class MenuFind extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };


    }

    componentDidMount() {

    }

    getContentCard(item) {
        switch (item.idContentType) {
            case 3:
                return (
                    <div className="menufind-item-content-default">
                        <img className="menufind-item-image3" src={item.image} alt="img" />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ fontWeight: 'bold' }}>
                                {item.text}
                            </div>
                            <div className="menufind-item-description5">
                                {item.description}
                            </div>
                        </div>
                        <div className="menufind-star-container">
                            <Rating stars={item.vote || null} />
                        </div>
                    </div>
                )
            case 2:
            case 5:
            case 7:
                return (
                    <div className="menufind-item-content">
                        <div style={{ position: 'relative', borderRadius: 5, overflow: 'hidden' }}>
                            <img className="menufind-item-image5" src={item.image} alt="img" />
                            <div className="menufind-star-container">
                                <Rating stars={item.vote || null} />
                            </div>
                            <div className="gradient-support-menu-find" />
                        </div>
                        <div style={{ fontWeight: 'bold', marginTop: 5 }}>
                            {item.text}
                        </div>
                        <div className="menufind-item-description5">
                            {item.description}
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="menufind-item-content-default">
                        <div style={{ position: 'relative', borderRadius: 5, overflow: 'hidden', marginRight: 10 }}>
                            <img className="menufind-item-image" style={{ marginRight: 0 }} src={item.image} alt="img" />
                            <div className="gradient-support-menu-find" />
                        </div>
                        <div>
                            {item.text}
                        </div>
                        <div style={{ position: 'absolute', bottom: 10, right: -5, zIndex: 10}}>
                            <Rating stars={item.vote || null} />
                        </div>
                    </div>
                )

        }
    }


    render() {
        const { data, isLoading, wordRequired, needPressEnter } = this.props
        return (
            <div className='menufind-container'>
                {
                    isLoading ?
                        <div className="loader loader-center loader-big" style={{ marginTop: '30vh' }}></div>
                        :
                        data && data.length > 0 ?
                        <div>
                            {
                                data.map((item, index) => {
                                    return (
                                        <div className="menufind-item" key={index}>
                                            <Link to={`/content/${item.idContentItem}/?idSection=${item.idSection}`} onClick={() => this.props.expand()}>
                                                {this.getContentCard(item)}
                                            </Link>
                                        </div>
                                    )
                                })
                            }

                            <div className="pages-container" style={{ marginTop: 13 }}>
                                {
                                    this.props.page >= 6 &&
                                    <div
                                        className={"page-item"}
                                        style={{ backgroundColor: getColors('menu').fourthColor }}
                                        onClick={() => this.props.searchByPage(1)}
                                    >
                                        1...
                            </div>
                                }
                                {
                                    [...Array(this.props.totalPages < 5 ? this.props.totalPages : 5)].map((e, i) => {
                                        if (this.props.page > 2) {
                                            if (this.props.page >= this.props.totalPages) {
                                                i = (this.props.page - 5) + i
                                            } else {

                                                i = (this.props.page - 3) + i
                                            }
                                        }

                                        if (this.props.totalPages > i) {
                                            return (
                                                <div
                                                    index={i}
                                                    className={this.props.page !== (i + 1) ? "page-item" : "page-item page-item-selected"}
                                                    onClick={() => this.props.searchByPage(i + 1)}
                                                >
                                                    {i + 1}
                                                </div>
                                            )
                                        }
                                    })
                                }

                                {
                                    this.props.totalPages > 10 && this.props.page <= (this.props.totalPages - 3) &&
                                    <div
                                        className={"page-item"}
                                        onClick={() => this.props.searchByPage(this.props.totalPages)}
                                    >
                                        ...{this.props.totalPages}
                                    </div>
                                }

                            </div>
                        </div>
                        :
                        wordRequired ?
                            <div className="menu-find-no-data" style={{ top: 'calc(20% - 50px)' }}>
                                <div className="menu-find-input-icon">
                                    <div className="menu-find-input-icon-ellipsis">
                                        ...
                                    </div>
                                </div>
                                <span style={{ marginTop: 5 }}>
                                    Digite pelo menos 3 letras para procurar.
                                </span>
                            </div>
                            :
                            needPressEnter?
                                <div className="menu-find-no-data">
                                   <img src={SearchIcon} style={{ height: 75 }} alt="img" />
                                </div>
                            :
                            <div className="menu-find-no-data">
                                <img src={NotFoundIcon} style={{ height: 75 }} alt="img" />
                                <span style={{ marginTop: 5 }}>
                                    Nenhum conteúdo encontrado.
                                </span>
                            </div>
                }
            </div>
        );
    }
}

export default MenuFind;
