import { getColors } from '../../../../../../services/utils';

const getStyle = (getDesign, blur) => {

    const Style = {
            lojinhaPaymentWaysItem:{
                maxWidth: 'calc(100% / 3)',
                backgroundColor: 'transparent',
                border: 'none',
                opacity: (blur) ? 0.4 : 1,
            },
            lojinhaPaymentWaysItemText:{
                ...getDesign('itemDescription'),
                fontWeight: 700,
            }
    }
    
    return Style;
}


export default getStyle;