import React, { useState } from 'react';

import iconClose from '../assets/images/close.svg'

import '../assets/css/OnBoard.css'

export default function OnBoard({ url }) {
  const [showOnBoard, setShowOnBoard] = useState(true)

  const renderOnboard = () => (
    <>
      <img className="onboard__icon-close" onClick={() => setShowOnBoard(false)} src={iconClose} alt="iconClose" />
      <iframe className="onboard__iframe" src={url} title="OnBoard" />
    </>
  )

  return (
    <>
      { showOnBoard && renderOnboard() }
    </>
  );
}