import React from 'react';
import Style from './style';
import ProgressBar from '../../Components/ProgressBar';
import CartTotal from '../../Components/CartTotal';
import Payment from '../../Components/Payment';

const ShowCartPayment = ({stage, data, nextCallback, getPaymentData, goTo}) => {

    return (
        <>
            <ProgressBar stage={stage} firstPinClick={goTo.Itens}/>
            <div style={Style.lojinhaCartDynamicInfo}>
                <Payment getPaymentData={getPaymentData}/>
            </div>
            <CartTotal 
                data={data} 
                nextCallback={nextCallback} 
            />
        </>
    )
}

export default ShowCartPayment;