import React from 'react';
import { getSectionById, getDesign, transformText } from '../../services/utils';
import style from './styles.module.scss';
import { Icon } from '@iconify/react';
import playCircle from '@iconify/icons-mdi/play-circle';
import Rate from "./Rate";

class ItemMenu extends React.Component {

    render() {

        var design = getDesign(this.props.idSection,'general');
 
         return(
 
         <div>
             <div 
             className={style.BackgroundSpaceImage} 
             style={{ 
                 backgroundImage: 'url(' + design.image + ')'
             }}
             >
         </div>
 
        <div className={style.listPlugs}>
        {   
            getSectionById(this.props.idSection).menuOption.map((item, index) => {
             return (
                <a index={index} 
                   key={index} 
                   to={`/${transformText(item.text)}/${item.sectionTo}`} 
                   className={style.wrapper+' listPlugsItem'} 
                   style={{ display: 'inline-block', paddingTop: '20px', paddingBottom: '20px' }}
                   href={`/${transformText(item.text)}/${item.sectionTo}`} 
                 >
 
                 { item.imageStyle !== 'tile' ? (
                         <div className={style.imgWrapper} 
                              style={{ 
                                  backgroundColor: item.color, 
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  justifyContent: 'center', 
                                  borderRadius: '7px', 
                                  backgroundImage: item.imageStyle === 'hybrid' ? `url( ${item.image} )` : '', 
                                  backgroundRepeat: 'no-repeat', 
                                  backgroundSize: 'contain', 
                                  backgroundPosition: 'center center' 
                             }} >
                             <img className={style.imgSecondary} 
                                  src={item.imageStyle === 'hybrid' ? item.imageSecondary : item.image} 
                                  alt="" 
                                  style={{  width: '50px', height: '50px' }} />   
                         </div>
                 ) : (   <div className={style.imgWrapper} 
                              style={{ backgroundColor: item.color, borderRadius: '7px' 
                         }}>
                             <img className={style.img} 
                                  src={item.image} 
                                  alt=""  
                             />
                         </div>
                     )
                 }
                
                <div className={style.textWrapper}>
                    <div className={style.titleText} >
                        <div className={style.title} >
                            {item.text}
                        </div>
                    </div>
                    
                     <div className={style.rating_rate}>
                     <Rate 
                         id={''} 
                         stars={'5'} 
                         rateContainer= {{backgroundColor:'yellow'}} 
                         styleFeedBack={{color:'#fff'}} 
                         numberVotes={4} 
                         avalie ={'avalie este conteúdo'} 
                         click={false} 
                     />
                     </div>
                </div>
                <div className={style.iconWrapper}>
                    <Icon 
                         icon={playCircle} 
                         style={{ fontSize: 50, opacity: 1, width: '100%', height: '100%' }} 
                     />
                </div> 
            </a> 
            )})}   
        </div>
     </div>
         )
     }
 }

export default ItemMenu