
const Style = {
    lojinhaCartFinishScreen:{
        width: '100%',
        height: '100%',
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
    },
    lojinhaCartFinishWrapper:{
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        fontWeight: '700',
        maxWidth: '800px',
    },
    lojinhaCartFinishContent:{
        width: '240px',
        textAlign: 'center',
        padding: '10px 0px',
    }
}

export default Style;