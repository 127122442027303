import React, { useState, useEffect} from 'react'; 
import getStyle from './style.js';
import PropTypes from 'prop-types';
import Bar from './Components/Bar';
import Pin from './Components/Pin';
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';

const PinStatus = {
    Off: 0,
    Hollow: 1,
    Full: 2
}

const ProgressBar = ( { stage, firstPinClick, secondPinClick, thirdPinClick} ) => {

    
    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign);
    
    const [firstPin, setFirstPin] = useState(PinStatus.Off);
    const [secondPin, setSecondPin] = useState(PinStatus.Off);
    const [thirdPin, setThirdPin] = useState(PinStatus.Off);
    
    const Phase = {
        Loading: 0,
        Cart: 1,
        Payment: 2,
        Finish: 3,
        Over: 4,
    }

    useEffect( () => {
        if(stage === Phase.Loading){
            setFirstPin(PinStatus.Off)
            setSecondPin(PinStatus.Off)
            setThirdPin(PinStatus.Off)
        }
        else if(stage === Phase.Cart){
            setFirstPin(PinStatus.Hollow)
            setSecondPin(PinStatus.Off)
            setThirdPin(PinStatus.Off)
        }
        else if(stage === Phase.Payment){
            setFirstPin(PinStatus.Full)
            setSecondPin(PinStatus.Hollow)
            setThirdPin(PinStatus.Off)
        }
        else if(stage === Phase.Finish){
            setFirstPin(PinStatus.Full)
            setSecondPin(PinStatus.Full)
            setThirdPin(PinStatus.Hollow)
        }
        else if(stage === Phase.Over){
            setFirstPin(PinStatus.Full)
            setSecondPin(PinStatus.Full)
            setThirdPin(PinStatus.Full)
        }

    },[stage]);


    return (
        <div style={Style.lojinhaProgressBar}>
            <div style={Style.lojinhaProgressBarImg}>
                <div style={Style.lojinhaProgressPinWrapper}>
                    <Pin status={firstPin} onClick={firstPinClick}/>
                    <Pin status={secondPin} onClick={secondPinClick}/>               
                    <Pin status={thirdPin} onClick={thirdPinClick}/>  
                </div>
                <div style={Style.lojinhaProgressBarWrapper}>
                    <Bar status={firstPin} />
                    <Bar status={secondPin} />    
                </div>
            </div>
            <div style={Style.lojinhaProgressBarText}>
                <div style={ Boolean(firstPin) ? Style.lojinhaProgressBarTextDivOn : Style.lojinhaProgressBarTextDivOff}>
                    Carrinho
                </div>
                <div style={ Boolean(secondPin) ? Style.lojinhaProgressBarTextDivOn : Style.lojinhaProgressBarTextDivOff}>
                    Forma de Pagamento
                </div>
                <div style={ Boolean(thirdPin) ? Style.lojinhaProgressBarTextDivOn : Style.lojinhaProgressBarTextDivOff}>
                    Confirmar
                </div>
            </div>
        </div>
    )
}

ProgressBar.propTypes = {
    stage: PropTypes.number,
}
  
ProgressBar.defaultProps = {
    stage: 0,
}

export default ProgressBar
