import React from 'react';

import MenuRow from './MenuRow'
import MenuMosaic from './MenuMosaic'
import MenuFullScreen from './MenuFullScreen'
import MenuCarousel from './MenuCarousel'

export default function HomeMenu({ type, content }) {

  const getHomeByType = (homeType) => {
    switch(homeType) {
      case "row": return <MenuRow content={content}/>
      case "mosaic": return <MenuMosaic content={content} />
      case "fullscreen": return <MenuFullScreen content={content} />
      case "carousel": return <MenuCarousel content={content} />
      default: return <MenuRow />
    }
  }

  return getHomeByType(type)
}