import React, { useEffect, useState } from "react";
import "../index.css";
import "../assets/css/CardPlayer.css";
import { contentList } from "../services/api";
import { getFooterSize, getDesign } from "../services/utils";
import Loader from "./Loader";
import { updateFooterStatus, updateHeaderStatus } from "../actions";
import { connect } from "react-redux";
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const PdfViewer = ({ idSection, parentContentData = null }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [contentData, setContentData] = useState({});

  const _getContent = async (id) => {
    contentList(id).then((res) => {
      if (res.data.success === false) {
        setError(true)
        return;
      }
      setContentData(res.data?.content?.[0] || {})
    }).catch(err => {
      console.log('error', err)
      setError(true)
    }).finally(() => {
      setLoading(false)
    })
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  useEffect(() => {
    if(parentContentData == null) {
      _getContent(idSection)
    } else {
      setContentData(parentContentData)
      setLoading(false)
    }
  }, [])

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 54px )',
        ...getDesign(idSection, "general"),
        ...getFooterSize(),
      }}
      className="pdf-viewer-container"
    >
      {
        loading ? 
          <Loader customTextStyle={{color: 'white'}} /> :
          error ? <div style={{ color: 'white' }}> Erro ao carregar PDF </div> :
          <>
            <Document
              loading={() => <div style={{color: 'white'}}>Carregando PDF...</div>}
              file={contentData.contentLocation} 
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Outline />
              <Page
              className="pdf-viewer-page-canvas"
              pageNumber={pageNumber} />
            </Document> 
            
            <div className="pdf-viewer-page">
                <div className="pdf-viewer-page-arrow" onClick={() => pageNumber > 1 && setPageNumber(pageNumber - 1)}>
                  <ArrowForwardIosIcon style={{ transform: 'rotate(180deg)' }} />
                </div>
                <div style={{ margin: '0px 5px' }}>{pageNumber} de {numPages}</div>
                <div className="pdf-viewer-page-arrow" onClick={() => pageNumber < numPages && setPageNumber(pageNumber + 1)}>
                  <ArrowForwardIosIcon />
                </div>
            </div>
          </>
      }
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(PdfViewer);
