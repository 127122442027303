import React from 'react';
import Style from './style.js';

const Button = ({ children, clickHandler}) => {
    
    return (
        <button style={Style.buttonAddToCart} onClick={clickHandler}>
            {children}
        </button>
    );
}

export default Button