import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { contentList } from '../services/api';
import '../assets/css/YTPlaylist.css';
import { setCacheData } from '../services/api';
import { getDesignById, getFooterSize } from '../services/utils';
// import { Icon } from '@iconify/react';
// import playCircle from '@iconify/icons-mdi/play-circle';
import Loader from '../components/Loader';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';

import { Icon } from '@iconify/react';
import playCircleTwotone from '@iconify-icons/ant-design/play-circle-twotone';
import VideoCard from '../components/VideoCard';

class LiveList extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = true;
		this.state = {
			isDesktop: false,
			isLoading: false,
			data: [],
			sectionType: 0,
			noData: false,
		};
		this.resize = this.resize.bind(this);
	}

	resize() {
		this.setState({ isDesktop: window.innerWidth > 900 });
	}

	async _getContent(id) {
		this.setState({ isLoading: true });

		const result = await contentList(id);
		if (this._isMounted) {
			try {
				if (!result.data.success) {
					this.setState({ isLoading: false, noData: true });
					return;
				}
				this.setState({
					isLoading: false,
					data: result.data.content,
					sectionType: result.data.idSectionType,
					noData: false,
				});

				setCacheData(result.data.content, 'LiveList');
			} catch (err) {
				this.setState({ isLoading: false, noData: true });
			}
		}
	}

	getVideoId(item) {
		return item.substr(item.length - 11);
	}

	componentDidMount() {
		this.resize();
		window.addEventListener('resize', this.resize);
		this._getContent(this.props.idSection);
		updateFooterStatus(this._getDesign('general')?.showBottomMenu || 'true');
		updateHeaderStatus(this._getDesign('general')?.showTopBar || 'true');
	}

  componentWillUnmount(){
    this._isMounted = false;
    window.removeEventListener('resize', this.resize)
  }
	_getDesign(item, only = false, valueOnly = false) {
		let design = getDesignById(this.props.idSection);

		if (!design[item]) {
			return null;
		} // return null if theres no item specified

		if (only) {
			// parameter only means it will only return the property specified
			if (valueOnly) {
				return design[item][only];
			} // parameter valueOnly means it will only return the property value if specified
			design = { [only]: design[item][only] };
			return design;
		}
		return design[item];
	}

	render() {
		const { isDesktop } = this.state;
		return (
			<div
				className="container-fluid cards-container ytplaylist"
				style={{ ...this._getDesign('general'), ...getFooterSize() }}
			>
				{this.state.noData ? (
					<div className="post-list-no-data">
						<span> Nenhum conteúdo disponível </span>
					</div>
				) : this.state.isLoading ? (
					<Loader />
				) : (
					<div className={`${isDesktop ? 'video-list' : 'row ytplaylist-row'}`}>
						{this.state.sectionType == 11 ? (
							this.state.data.length > 0 ? (
								this.state.data.map((item, index) => {
									const currentMoment = moment();
									const itemMoment = moment(item.initDate);
									const isLive = currentMoment.isSame(itemMoment, 'day');
									return (
										<Link
											to={`/content/${item.idContentItem}/?idSection=${this.props.idSection}`}
											key={index}
											className="ytplaylist-content"
										>
											<div className="ytplaylist-body">
												{isLive && (
													<>
														<div className="ytplaylist-icon-play">
															<Icon
																icon={playCircleTwotone}
																color="#fff"
																width="70"
															/>
														</div>
														<div
															className="ytplaylist-title"
															style={{
																width: '100%',
																height: 50,
																backgroundColor: 'rgba(255,0,0,1)',
																position: 'absolute',
																top: 0,
																color: 'white',
															}}
														>
															<span>AO VIVO</span>
														</div>
													</>
												)}
												<div
													style={{
														width: '100%',
														backgroundColor: 'lightgray',
													}}
												>
													<img
														src={item.image}
														style={{ width: '100%', minHeight: 245 }}
														alt=""
													/>
												</div>
											</div>
										</Link>
									);
								})
							) : (
								<div style={this._getDesign('itemText')} className="noContent">
									Não há conteúdo a ser visualizado
								</div>
							)
						) : this.state.sectionType == 5 ? (
							this.state.data.length > 0 ? (
								this.state.data.map((item, index) => {
									

									return (
										<VideoCard
											pathname={`/content/${item.idContentItem}/?idSection=${this.props.idSection}`}
											index={index}
                      						key={item.idContentItem}
											concluded={item.completed}
											progress={item.progress}
											idSection={this.props.idSection}
											imgsrc={item.image}
											title={item.text}
											description={item.description}
											duration={item.duration}
										/>
									);
								})
							) : (
								<div style={this._getDesign('itemText')} className="noContent">
									Não há conteúdo a ser visualizado
								</div>
							)
						) : (
							''
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
	updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(LiveList);
