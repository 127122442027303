import React from 'react'; 
import Style from './style';
import { ReactComponent as LineProgressOff } from '../../../../../../assets/images/line-progress-off.svg';
import { ReactComponent as LineProgressOn } from '../../../../../../assets/images/line-progress-on.svg';



const PinStatus = {
    Off: 0,
    Hollow: 1,
    Full: 2
}

const Bar = ( { status } ) => {

    let bar = ( <LineProgressOff  style={Style.lineOff}/> );

    if( status === PinStatus.Full) { bar = ( <LineProgressOn  style={Style.lineOn} /> )}

    return (<div style={Style.lojinhaProgressBarSpace}>
                {bar}                    
            </div>);
}

export default Bar;