import React, { useState, useEffect } from 'react';
import AudioListCard from '../AudioListCard';
import { contentList } from '../../services/api';
import { getDesignById, getFooterSize } from '../../services/utils';
import Loader from '../Loader';
import style from './style.module.scss'

const PodcastList = ({ idSection }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const getContent = (id) => {
        setIsLoading(true);
        contentList(id).then(res => {
        if (res.data.success === false) {
            setIsLoading(false);
            return
          }

          setIsLoading(false);
          setData(res.data.content);

        }).catch(err => {
            setIsLoading(false);
        })
    }

    const getDesign = (item, only = false, valueOnly = false) => {
        let design = getDesignById(idSection)
    
        if (!design[item]) { return null } // return null if theres no item specified
    
        if (only) { // parameter only means it will only return the property specified
            if (valueOnly) { return design[item][only] 
        } // parameter valueOnly means it will only return the property value if specified
    
            design = { [only]: design[item][only] }
            return design
        }
    
        return design[item]
    }
    
    useEffect(() => {
        getContent(idSection);
    }, [])
    
    return (
        <div className={style.cardPlayerContainer} style={{...getDesign('general'), ...getFooterSize()}}>
            <div className="card-deck">
                <div className="row rowPlayer" style={{marginRight: 'auto', marginLeft: 'auto', alignContent: 'space-around', justifyContent: 'space-around'}}>
                    {
                        isLoading ?
                        <></>
                        :
                            data.map((item, key) => {
                                return (
                                    <AudioListCard index={key} key={key} idSection={idSection} item={item}/>
                                )
                            })
                    }
                </div>
            </div>
        </div>
    )
}

export default PodcastList;