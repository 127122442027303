import React from "react";
import NotFoundLogo from '../assets/images/not-found.svg';
import Footer from "./Footer";

function NotFound() {
  return (
    <div className="not-found-wrapper">
        <div className="not-found-container"> 
            <img src={NotFoundLogo} style={{ height: 100, width: 100 }} alt="img" />
            <strong>Page Not Found</strong>
        </div>
        {/* <Footer /> */}
    </div>
  );
}

export default NotFound;
