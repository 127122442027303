import React, { useState } from "react"; // TODO item não utilizado
import ReactStars from 'react-stars'; // TODO item não utilizado
import { getColors } from '../services/utils'; // TODO item não utilizado


function LoaderWhite({ customStyle = {}}) {
        return (
            <div className="loader-container" style={customStyle}>
                <div style={{ 
                    borderTopColor: 'white',
                    position: 'initial',
                    margin: 'initial'
                    }} 
                    className="loader loader-center loader-big">
                </div>
                <span style={{
                    color: 'white',
                    marginTop: 10,
                    padding: "0px 5px",
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    borderRadius: 5,
                }}>Carregando...
                </span>
            </div>
        );
    
}

export default LoaderWhite;
