import React from "react"
import { getDesign,} from "../../../../services/utils";

const MosaicText = ({index, idSection, item}) => {

    function getFirstDesign(idSection, firstItem, item) {
        if (getDesign(idSection, firstItem) === null) {
          return getDesign(idSection, item);
        } else {
          return getDesign(idSection, firstItem);
        }
    }

    const textStyle = index === 0 ? {
        ...getFirstDesign(idSection, "firstItemText", "itemText"),
        backgroundColor: null
        }   : {
            ...getDesign(idSection, "itemText"),
            backgroundColor: null
        }
        
    const descriptionStyle = index === 0
        ? {
            ...getDesign(idSection, "firstItemDescription"),
            backgroundColor: null
        }
        : {
            ...getDesign(idSection, "itemDescription"),
            backgroundColor: null
        }

    const hasDescription = item.description && item.description !== "null";

    return (
        <div className="mosaic-text">
            <div style={textStyle}>
                <span> {item.text} </span>
            </div>
            <div style={descriptionStyle} >
                <span>
                    {" "}
                    { hasDescription && item.description }
                </span>
            </div>
        </div>
    )
}

export default MosaicText