import { getColors } from '../../../../../../services/utils';

const getStyle = (getDesign) => {
    const Style = {
        lojinhaCartFinishContent:{
            ...getDesign('itemText'),
            width: '240px',
            textAlign: 'center',
            padding: '10px 0px',
        },
        check:{
            fill: getColors('menu').firstColor,
        }
    }
    return Style;
}

export default getStyle;