import React, { useEffect, useState} from 'react';
import { Link }  from 'react-router-dom';
import { getDesignById } from '../../services/utils';
import style from './styles.module.scss';
import {  ReactComponent as ActivatedEye } from '../../assets/images/activatedEye.svg'
import { ReactComponent as DeactivatedEye } from '../../assets/images/deactivatedEye.svg'

const PostListCard = ({ index, idSection, item }) => {

    function _getDesign(item, only = false, valueOnly = false){
        let design = getDesignById(idSection)
    
        if(!design[item]){return null} // return null if theres no item specified
    
        if(only){ // parameter only means it will only return the property specified
          if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified
    
          design = { [only]:design[item][only] }
          return design
        }
    
        return design[item]
    }

    const [widthSize, setWidthSize] = useState(window.innerWidth);

    const updateWidthSize = () => {
        setWidthSize(window.innerWidth)
    }

    window.addEventListener('resize', updateWidthSize);

    const firstItemTextColor =  _getDesign('firstItemText') ? _getDesign('firstItemText').color : '#999999'
    const viewed = Boolean(item.visits);
    const viewedColor = (viewed) ? { color: firstItemTextColor } : { color: '#999999'};
    const viewedFillColor = (viewed) ? { fill: firstItemTextColor } : { fill: '#999999'};
    const first = (index === 0);
    const textStyleMain = _getDesign("firstItemText")

    const textStyle = (first && widthSize < 500) ? { ...textStyleMain, ...viewedColor } : viewedColor;
    const wrapper = first ? style.mainWrapper : style.wrapper
    const imgWrapper = first ? style.mainImgWrapper : style.imgWrapper
    const img = first ? style.mainImg : style.img
    const textWrapper = first ? style.mainTextWrapper : style.textWrapper

    return(
        <Link key={index} to={`/content/${item.idContentItem}?idSection=${idSection}`} className={wrapper}>
            <div className={imgWrapper}>
                <img className={img} src={`${item.image}`} alt="" />
            </div>
            <div className={textWrapper}>
                <div style={ textStyle } className={style.titleText} >
                    <span >{item.text}</span>
                </div>
                <div className={style.infoWrapper}>
                    <span style={viewedColor} className="post-list-knowmore">
                        Saiba Mais
                    </span>
                    <span style={viewedColor}>
                        { viewed ? (<ActivatedEye style={viewedFillColor}/>) : (<DeactivatedEye style={viewedFillColor}/>) }
                    </span>
                </div>
            </div>
        </Link>
    )
}

export default PostListCard