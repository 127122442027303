import React from 'react';
import getStyle from './style.js';
import { ReactComponent as CheckIcon} from '../../../../../../assets/images/check-icon.svg';
import useDesignByIdSectionType from '../../../../hooks/useDesignByIdSectionType';

const Ok = () => {

    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign);

    return (
        <>
            <div style={Style.lojinhaCartFinishContent}>
                Pagamento autorizado!
            </div>
            <div style={Style.lojinhaCartFinishContent}>
                <CheckIcon style={Style.check}/>
            </div>
            <div style={Style.lojinhaCartFinishContent}>
                Seu produto já está disponível para uso no seu App
            </div>
        </>
    )
}

export default Ok