import { getColors } from '../../../../../../services/utils';
const Style = {
    lineOff:{
        width:'100%'
    },
    lineOn:{
        fill: getColors('menu').firstColor, 
        width:'100%',
    },
    lojinhaProgressBarSpace:{
        width: 'calc(100% / 3)',
        display: 'flex',
        alignItems: 'center'
    }
}

export default Style;