import { getColors } from '../../../../services/utils';

const getStyle = (getDesign) => {
    
    const Style = {

        lojinhaHomeContainer:{
            backgroundColor: 'red',
            width: '100wh',
            height: '100vh',
        },

        lojinhaContentWrapper:{
            marginTop: '58px',
            overflowY: 'auto',
            width: '100%',
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
        },


        lojinhaHomeProdutosButton:{
            position: 'fixed',
            padding: '5px',
            width: '100%',
            display: 'flex',
        },

        lojinhaHomeProdutosButtonButtonOn:{
            ...getDesign('itemDescription'), 
            backgroundColor: getColors('menu').firstColor,
            textAlign: 'center',
            flex: '1',
            color: '#fff',
            border: 'none',
            padding: '15px',
        },
        lojinhaHomeProdutosButtonButtonOff:{
            ...getDesign('itemDescription'), 
            backgroundColor: getColors('menu').fourthColor,
            textAlign: 'center', 
            flex: '1',
            color: '#fff',
            border: 'none',
            padding: '15px',
        },
        mensagemVazio:{
            ...getDesign('itemText'), 
            color: getColors('menu').firstColor,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '30px',
            textAlign: 'center',
        }
    }
    return Style; 
}


export default getStyle;