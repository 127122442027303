import * as React from 'react';
import { getContent } from '../services/api';

class MessageFull extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            sectionId: parseInt(props.id.match.params.id, 10) || null,
            data: []
        };
    }

    componentDidMount(){
        this.setState({ isLoading: true })
        getContent(this.state.sectionId).then(res => {
            if(res.data.success === false){
                this.setState({ isLoading: false })
                return
            }
            this.setState({ 
                data: res.data.content,
                isLoading: false
             })
        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }


    render() {
        return (
            <div className="message-full-container">
                {
                    this.state.data.map((item, index)  => {
                        return (
                            <div key={index} className="message-full-box">
                                    <div className="message-full-title">
                                        {item.text}
                                    </div>
                                    <div className="message-full-text">
                                        {item.description}
                                    </div>
                                    <div className="box-full-read-time">
                                        Hoje
                                    </div>
                            </div>
                        );
                    })
                }
               </div>
        );
    }
}

export default MessageFull