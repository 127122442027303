import React, { useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./Registro.css";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { getAppType } from "../../services/utils"
import { CircularProgress } from "@material-ui/core";
import styles from "../ActivateAccount/styles.module.scss";
import BreadCrumbPhase2 from '../../assets/images/breadcrumb02.png';

const api_url = process.env.REACT_APP_ENVIRONMENT;

export default function Registro() {
    const history = useHistory();
    
    const [company, setCompany] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [cpf, setCpf] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Erro no envio do registro");
    const fone = localStorage.getItem("fone")
    const pin = localStorage.getItem("pin")
    const aceitaTermos = getAcceptedTerms();
    const communicationAccepted = getCommunicationValue()

    const appDetails = JSON.parse(localStorage.getItem("appDetails")) || {};
    const primaryColor = appDetails?.primaryColor

    function validateForm() {
        const emailValido = email.length > 0;

        return emailValido ;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        try {
            setError(false)
            if (validateForm()) {
              setLoading(true)
                axios
                    .post(`${api_url}/contract/activateuser`, {
                        userName: nome,
                        email,
                        phone: fone.replace(/\D/g,''),
                        pin,
                        idAppType: getAppType(),
                    }, { "Content-Type": "application/json"})
                    .then(({data}) => {
                        if (data.success) {
                            history.push("/obrigado");
                        } else {
                          setErrorMessage(data.message)
                          setError(true)
                        }
                    })
                    .catch((e) => console.log("Erro ao gravar os dados de registro", e))
                    .finally(() => setLoading(false))
            } else {
                console.log("falha ao enviar o registro")
                setError(true)
            }
            
        } catch (e) {
            alert(e.message);
        }
    }

    function getCommunicationValue(){
        let value = localStorage.getItem("aceitaComunica")
        if(value == true || value == 'true'){
            return 1
        }else{
            return 0
        }
    }

    function getAcceptedTerms(){
        let value =  localStorage.getItem("aceitaTermo")
        if(value == true || value == 'true'){
            return 1
        }else{
            return 0
        }
    }


    return (
        <div className="registro-wrapper">
            <Form className="registro-container" onSubmit={handleSubmit}>
                <Form.Text className={styles.titleText}>
                  Criação de Usuário
                </Form.Text>
                <center><Image src={BreadCrumbPhase2} className={styles.imageStyle} fluid /></center>
                <Form.Group controlId="formBasicName">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Digite o seu nome"
                        value={nome}
                        onChange={e => setNome(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="Digite o seu email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Button style={{ background:  primaryColor, borderColor: primaryColor}} variant="primary" disabled={!validateForm()} type="submit">
                    Salvar
                    {
                      loading &&
                      <CircularProgress 
                        style={{
                          height: 20,
                          width: 20,
                          marginLeft: 10,
                          marginBottom: -4,
                          color: 'white',
                        }}
                      />
                    }
                </Button>

            </Form>
            {
              error && 
              <Card 
                className="activate-account-warning-card" 
                style={{ marginTop: 15, padding: '0px 5px' }} 
                bg='warning' 
                text='dark'
              > 
                {errorMessage}
              </Card>
            }
        </div>
    );
}