import React from 'react'
import style from './style.module.scss'
import { getDesign } from '../../../../services/utils'

const AudioInfo = ({ idSection, isDesktop, title, author }) => {

    const titleFontSize = { fontSize: isDesktop ? 40 : 26 }
    const authorFontSize = { fontSize: isDesktop ? 25 : 15 }

    return (
        <div className={style.bodyDescription}>
            <div className={style.bodyTitle} style={{ ...getDesign(idSection, "itemText"), ...titleFontSize}} >
                {title}
            </div>
            <div className={style.bodyAuthor} style={{...getDesign(idSection,"itemDescription"), ...authorFontSize}} >
                Autor: {author}
            </div>
        </div>
    )
}

export default AudioInfo