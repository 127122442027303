import React, { useState, useEffect } from 'react';
import getStyle from './style.js';
import Icon from '../Icon';
import { ReactComponent as ImageCreditCard } from '../../../../../../assets/images/credit-cart.svg';
import { getColors, getDesign } from '../../../../../../services/utils';
import { buyLojinha } from '../../../../../../services/api.js';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import useDesignByIdSectionType from '../../../../hooks/useDesignByIdSectionType';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    getIssuer,
    formatName
  } from "./utils";

const CreditCard = ({update}) => {

    const initialData = {
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
    };

    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign);

    const [dados, setDados] = useState(initialData)

    const handleInputFocus = (event) => {
        setDados({ 
            ...dados,
            ['focus']: event.target.name, 
        });
    }

    const handleInputChange = ({ target }) => {
        if (target.name === "name") {
            target.value = formatName(target.value);
        } else if (target.name === "number") {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiry") {
            target.value = formatExpirationDate(target.value, "expiry");
        } else if (target.name === "cvc") {
            target.value = formatCVC(target.value, dados.number);
        }

        target.setCustomValidity("");
        setDados({ 
            ...dados,
            [target.name]: target.value, 
        });
    };

    useEffect(update,[dados]);
    
    const getIcon = ({ blur, onClickHandler}) => {
        return (
            <Icon
                img={(<ImageCreditCard style={ {fill: getColors('menu').firstColor} }/>)} 
                text={'Cartão de Crédito'} 
                clickHandler={onClickHandler} 
                blur={blur}
            />
        )
    }

    const getForm = () => {

        return (
            <div style={Style.lojinhaPaymentCreditCard}>
                <label style={Style.lojinhaPaymentCreditCardLabel}>Cartão de Crédito</label>
                <Cards
                    cvc={dados.cvc}
                    expiry={dados.expiry}
                    focused={dados.focus}
                    name={dados.name}
                    number={dados.number}
                />
                <form id="PaymentForm">
                    <div style={Style.lojinhaPaymentCreditCardFullWidthForm}>
                        <div style={Style.inputOuterWrapper}>
                            <span> Nome </span>
                            <div style={Style.inputInnerWrapper}>
                                <input style={Style.lojinhaCreditCardInput}
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Nome"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div style={Style.lojinhaPaymentCreditCardFullWidthForm}>
                        <div style={Style.inputOuterWrapper}>
                            <span> Número do cartão </span>
                            <div style={Style.inputInnerWrapper}>
                                <input style={Style.lojinhaCreditCardInput}
                                    type="text"
                                    name="number"
                                    id="number"
                                    placeholder="Número do cartão"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    pattern="[\d| ]{16,22}" 
                                    oninvalid="this.setCustomValidity('Formato inválido')"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div style={Style.lojinhaPaymentCreditCardHalfWidthFormWrapper}>
                        <div style={Style.lojinhaPaymentCreditCardHalfWidthForm}>
                            <div style={Style.inputOuterWrapper}>
                                <span> Data de expiração </span>
                                <div style={Style.inputInnerWrapper}>
                                    <input style={Style.lojinhaCreditCardInput}
                                    type="text"
                                    name="expiry"
                                    id="expiry"
                                    placeholder="Data de expiração"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    oninvalid="this.setCustomValidity('Formato inválido')"
                                    pattern="(0[1-9]|1[012])[- /.]\d\d"//"\d\d/\d\d" 
                                   

                                    required
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={Style.lojinhaPaymentCreditCardHalfWidthForm}>
                            <div style={Style.inputOuterWrapper}>
                                <span> CVV </span>
                                <div style={Style.inputInnerWrapper}>
                                    <input style={Style.lojinhaCreditCardInput}
                                        type="text"
                                        name="cvc"
                                        id="cvc"
                                        placeholder="CVV"
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus}
                                        required
                                        oninvalid="this.setCustomValidity('Formato inválido')"
                                        pattern="\d{3,4}"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const getPayment = async () => { 

        const treatedNumber = dados.number.replace(/\s/g, '');
        const brand = getIssuer(dados.number)

        const sendData = {
            "name": dados.name, 
            "creditCard": treatedNumber,
            "expirationDate": dados.expiry,
            "CVV": dados.cvc,
            "brand": brand
        }

        const result = await buyLojinha(sendData);
        if (result && result.hasOwnProperty('status') && result.status === 200 && result.data.sucess) {
            return true;
        }

        return false;
        
    }

    const validacaoNumero = () => {
        const number = document.getElementById('number');
        const issuer = getIssuer(number.value);
        number.setCustomValidity('');

        if(issuer === null){
            number.setCustomValidity('Bandeira do cartão não encontrada, favor informar outro cartão.');
            return false;
        }

        number.oninvalid = function(e) {
            
            if (!e.target.validity.valid) {
                e.target.setCustomValidity("Favor preencher o formato correto.");
            }
        };
    }

    const validacaoExpiry = () => {
        
        const expiry = document.getElementById('expiry');
        expiry.setCustomValidity("");

        if(expiry.value.length <=4){

            expiry.setCustomValidity("Favor preencher o formato correto. MM/AA");
            expiry.reportValidity();

        } else if(expiry.value.length > 4){

            const month = expiry.value.slice(0, 2)
            const year = expiry.value.slice(3, 5)
            const date = new Date(2000 + parseInt(year), month-1, 1);
            const today = new Date();

            if(date.getTime() < today.getTime()){
                setDados({ 
                    ...dados,
                    [expiry]: '', 
                })

                expiry.setCustomValidity("Cartão expirado, favor mudar o cartão");
                expiry.reportValidity();
            }
        } else{
            expiry.oninvalid = function(e) {

                if (!e.target.validity.valid) {
                    e.target.setCustomValidity("Favor preencher o formato correto. MM/AA");
                }
            };
        }
    }

    const validacaoCvc = () => {

        const cvc = document.getElementById('cvc');

        cvc.oninvalid = function(e) {
            e.target.setCustomValidity("");
            if (!e.target.validity.valid) {
                e.target.setCustomValidity("Favor preencher o formato correto.");
            }
        };

    }

    const isValid = () => { 
        
        validacaoNumero();
        validacaoExpiry();
        validacaoCvc();

        if(!document.getElementById('PaymentForm').checkValidity()){
            document.getElementById('PaymentForm').reportValidity()
            return false
        }

        return true;

    }

    return {
        getIcon, 
        getForm, 
        getPayment,
        isValid
    };
}

export default CreditCard;