import React from 'react';
import getStyle from './style.js';
import HeaderSearch from '../../Components/HeaderSearch';
import HeaderBack from '../../Components/HeaderBack';
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';


const PageDefault = ({ children, search, searchHandler, headerClickPath, quantidadeItensCarrinho}) => {

    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign);

    return(
        <>
            <div style={Style.lojinhaScreenWrapper}>
            { search && (
                    <HeaderSearch 
                        getData={searchHandler} 
                        path={headerClickPath}
                    />
                    )}
                { !search && (
                    <HeaderBack quantidadeItensCarrinho={quantidadeItensCarrinho}/>
                )}
                    { children }
                
            </div>
        </>
    );
}


export default PageDefault;