import React, { useState, useEffect, useCallback,useRef } from 'react';
import { getListCampaigns,listcampaignuserpoints,getListCampaignsDetail,getCampaignsEvents  } from '../../services/api';
import moment from 'moment';
import "moment/locale/pt-br";
import { getColors } from '../../services/utils';
import { ReactSVG } from 'react-svg'
import './index.scss';
import Cup from '../../assets/images/championsCup.svg';

const PointDetail = ({id, setTotalPoints, exist = false}) => {

    const [loadingPoints, setLoadingPoints] = useState(false);
    const [loadingEvents, setLoadingEvents] = useState(false);
    const [pointsUser, setPointsUser] = useState([]);
    const [events, setEvents] = useState([]);
    const [eventPoints, setEventPoints] = useState([]);
    const [_exist, setExist] = useState(exist);
    const [more, setMore] = useState(2);
    useEffect(()=>{
        if(id && events.length && exist){
            setLoadingPoints(true)
            setLoadingEvents(true)
             getListCampaigns().then(async res => {
                if(res.data.success === true){
                    let _pointsUser = [];
                    let _totalPoints = 0;
                    let _eventPoints = [];
                    await res.data.campaigns.forEach(async (elem)=>{
                        if(elem.status === 1){
                           await listcampaignuserpoints({idCampaign:elem.idgamification_campaign,idUser: id}).then(res => {
                               
                                if(res.data.success === true){
                                    res.data.campaignuserpoints.forEach((item)=>{
                                        _pointsUser = [..._pointsUser, 
                                            {
                                                event: item.event, 
                                                date : moment(item.eventDate).format("lll"),
                                                points:item.points 
                                            }
                                        ]
                                        _totalPoints+= parseInt(item.points);
                                    })
                                    setPointsUser(_pointsUser);
                                    setTotalPoints(_totalPoints);
                                }
                            }).catch(()=>{
                                setPointsUser([])
                                setTotalPoints(0)
                            })
                            await getListCampaignsDetail(elem.idgamification_campaign).then( async res => {
                                
                                if(res.data.success === true){
                                    await res.data.events.forEach(item => {
                                        let data = events.find(e => e.idgamification_event === item.idEvent);
                                        if(data){
                                            _eventPoints = [..._eventPoints, {name: data.event, points: item.points, id: data.idgamification_event}]
                                        }
                                    })
                                    setEventPoints(_eventPoints);
                                }
                            }).catch(err => {
                                setEventPoints([]);

                            })
                        }
                    })
                    setLoadingPoints(false)
                    setLoadingEvents(false);
                    setExist(true)
                }
            }).catch(e=> {
                setExist(false)
            })
        }
        
    },[id, setTotalPoints,events,exist])
 useEffect(()=>{
    getCampaignsEvents().then(res => {
        if(res.data.success){
            setEvents(res.data.event)
        } 
        setEvents([])

    }).catch(res => {
        setEvents([])
    })
 },[])
 return (
     <>
        {
            _exist ? (
                <div className="section">
                    <section className="card-points" style={{marginTop: 10}}>
                        <span className="title-profile-edit">
                            Meus Pontos
                        </span>
                        <div className="profile-card column" >
                            <span className="subtitle-profile-edit">
                                Minhas atividades
                            </span>
                            
                            {
                                pointsUser.slice(0, more).map((elem, index) => {
                                    return(
                                        <div key={`activities${index}`} className="subtitle-profile-edit" style={{display:'flex', flexDirection:'column'}}>
                                            <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                                                <div style={{display:'flex', flexDirection:'column'}}>
                                                    <span style={{fontSize:12}}>{elem.event}</span>
                                                    <span style={{color: "#999",fontSize:12}}>{elem.date}</span>
                                                </div>
                                                <span style={{fontWeight: 700}}>{`${elem.points}pts`}</span>
                                            </div>
                                            <div className="hr-profile" />
                                        </div>
                                    )
                                })
                            }
                            {loadingPoints && <div className="loader" style={{display: 'inline-block',height:50,width:50, position:'relative', right:0, left: "45%"}}  />}
                        </div>
                        {
                        pointsUser.length &&   more < pointsUser.length ?
                        (
                            <div className="show-more-profile" onClick={() => setMore(more+5)}>
                                <span className="subtitle-profile-edit" style={{fontWeight: 600, color: getColors('menu').secondColor}}>Mostrar mais</span>
                            </div>
                        ) : (
                            null
                        )
                        }
                    </section>
                    <section  className="card-points" style={{marginTop: 10}}>
                        <div className="profile-card column second-section">
                            <span className="subtitle-profile-edit" style={{marginLeft: 0}}>
                                Como você ganha pontos
                            </span>
                            {!loadingEvents ? 
                            (
                                <div className='table-profile'> 
                                    {
                                        eventPoints.map((elem, index) =>{
                                            return(
                                                <div key={`howto${index}`} className="tr-profile" style={{borderTop: index === 0 ? 0 : "1px solid #DCDCDC" }}>
                                                    <div className="td-icon-profile">
                                                        <ReactSVG
                                                        beforeInjection={(svg) => {
                                                                svg.setAttribute('style', `width: 15px; height: 15px;fill: 'white'`)
                                                                }}
                                                            src={Cup}  alt="cup" 
                                                        />  
                                                        <span className="subtitle-profile-edit" style={{margin:0,  marginLeft:10,fontSize:12}}>{elem.name}</span>
                                                    </div>
                                                    
                                                    <span className="td-points-profile subtitle-profile-edit"  style={{fontSize:12}}>
                                                        {`${elem.points}pts`}
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </div>
                                ) : (
                                    <div className="loader" style={{display: 'inline-block',height:50,width:50, position:'relative', right:0, left: "45%"}}  />
                                )
                            }
                        </div>
                    </section>
                </div>
            ) : null
        }
    </>
 )
}

export default PointDetail;