import React, { Component } from "react";
import "../index.css";
import CloseIcon from "../assets/images/close-icon-menu.svg";
import SearchIcon from "../assets/images/search.svg";
import LogoutIcon from "../assets/images/logout.svg";
import HomeIcon from "../assets/images/home.svg";
import UserIcon from "../assets/images/no-gender-user.svg";
import BellIcon from "../assets/images/bell.svg";
import ArrowRight from "../assets/images/arrowRight.svg";
import { Link, Redirect, NavLink } from "react-router-dom";
import { getMenuData, logout, getToken, listFilter } from "../services/api"; // TODO - getMenuData não utilizado
import { getRoute, getIcon, transformText, getColors } from "../services/utils"; // TODO - getRoute, getIcon não utilizado
import GearIcon from "../assets/images/gear.svg";
import ChatIcon from "../assets/images/chat.svg";
import jwtDecode from "jwt-decode";
import MenuFind from "./MenuFind";
import ChatRoom from "./mini-chat/ChatRoom";
import ChatRoomsList from "./mini-chat/ChatRoomsList";
import { ReactSVG } from "react-svg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { PointsFooter } from "./Points";
import AdminAccess from "./AdminAccess";
import RedirectAccess from "./RedirectAccess";

class MenuPrincipal extends Component {
  constructor(props) {
    super(props);

    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    const platform = window.navigator.platform;

    const isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const isIOS = iosPlatforms.indexOf(platform) !== -1;

    this.state = {
      isDesktop: false,
      user: {},
      contentData: [],
      page: 1,
      totalPages: 0,
      searchTags: "",
      isSearching: false,
      wordRequired: false,
      secondaryExpanded: false,
      needPressEnter: true,
      displayChat: isChrome && isIOS ? false : true,
      hideFooter: false,
      currentroom: null,
      showModal: false,
      showRedirect: false,
      sectionTo: null
    };

    this.resize = this.resize.bind(this);
  }

  componentDidMount() {
    this.resize();
    this.setState({
      user: this.getProfileInfo(),
    });
    window.addEventListener("resize", this.resize);
  }

  getProfileInfo() {
    let token = {};
    try {
      token = jwtDecode(getToken());
    } catch {
      console.log("error on token get");
    }
    return token;
  }

  resize() {
    this.setState({ isDesktop: window.innerWidth > 900 });
  }

  logout() {
    logout();
    this.setState({ redirect: true });
  }

  findContentState(word) {
    if (word.length === 0) {
      this.setState({ isLoading: false, searchTags: word, isSearching: false });
      return;
    }
    if (word.length === 1 || word.length === 2) {
      this.setState({
        isLoading: false,
        searchTags: word,
        isSearching: true,
        wordRequired: true,
        contentData: [],
      });
      return;
    }
    this.setState({ searchTags: word, wordRequired: false, page: 1 }, () => {
      this.findContent();
    });
  }

  findContent() {
    if (this.state.searchTags.length === 0) {
      return;
    }
    if (
      this.state.searchTags.length === 1 ||
      this.state.searchTags.length === 2
    ) {
      return;
    }

    this.setState({
      isLoading: true,
      isSearching: true,
      wordRequired: false,
      page: 1,
      needPressEnter: false,
    });
    listFilter(0, { page: 1, searchTags: this.state.searchTags })
      .then((res) => {
        if (!res || res.data.success === false) {
          this.setState({ isLoading: false, contentData: [], totalPages: 0 });
          return;
        }
        if (res.data.success === false) {
          this.setState({ isLoading: false });
          return;
        }
        this.setState({
          isLoading: false,
          contentData: res.data.content,
          totalPages: Math.ceil(res.data.totalRecord / res.data.content.length), // divide by number of total items by page
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.log("error ", err);
      });
  }

  hideFooter(value) {
    this.setState({ hideFooter: value });
  }

  searchByPage(page) {
    const data = {
      page: page,
      searchTags: this.state.searchTags,
    };

    this.setState({ isLoading: true, page: page });

    listFilter(0, data)
      .then((res) => {
        if (!res || res.data.success === false) {
          this.setState({ isLoading: false, data: [], totalPages: 0 });
          return;
        }
        this.setState({
          isLoading: false,
          contentData: res.data.content,
          totalPages: Math.ceil(res.data.totalRecord / res.data.content.length),
        });
      })
      .catch((err) => {
        console.log("err ", err);
        this.setState({ isLoading: false });
      });
  }

  openChat() {
    this.hideFooter(true);
    this.setState({ secondaryExpanded: true });
  }

  closeChat() {
    this.setState({ secondaryExpanded: false });
    this.hideFooter(false);
  }

  leaveChat() {
    this.setState({ currentroom: null });
    this.hideFooter(false);
  }

  handleChangeRoom(r) {
    this.setState({
      currentroom: r,
      hideFooter: true,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }
    const { isDesktop } = this.state; // TODO - item não utilizado
    const { menuData } = this.props;
    const appDetails = JSON.parse(localStorage.getItem("appDetails"));
    const photo = this.state.user.photo || UserIcon;
    return (
      <div
        className={
          "menu-expand " + (this.props.expanded ? "menu-expand-open" : "")
        }
        style={{
          backgroundColor: getColors("menu").fourthColor,
          overflowY: this.state.isSearching ? "auto" : "hidden",
        }}>
        <div
          className="menu-close-container"
          style={{ backgroundColor: getColors("menu").secondColor }}>
          <div className="menu-close" onClick={() => this.props.expand()}>
            <ReactSVG
              beforeInjection={(svg) => {
                svg.setAttribute(
                  "style",
                  `width: 20px; height: 20px; margin-bottom: 5px; fill: ${
                    getColors("menuItem")?.color || "white"
                  }`
                );
              }}
              src={CloseIcon}
              alt="img"
            />
          </div>
          <div className={"menu-search-button menu-search-button-expand"}>
            <img
              src={SearchIcon}
              style={{
                height: 20,
                width: 20,
                float: "left",
                marginLeft: 3,
                marginTop: 3,
              }}
              alt="img"
            />
            <input
              spellCheck="false"
              className="menu-search-input"
              placeholder="Procurar conteúdo"
              ref={(input) => {
                this.nameInput = input;
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.findContent();
                }
              }}
              onChange={(c) => {
                this.findContentState(c.target.value);
              }}
              value={this.state.searchTags}
              maxLength={25}
              type="search"
            />
            {this.state.isSearching && (
              <div
                className="menu-search-cancel"
                onClick={() => {
                  this.setState({ searchTags: "", isSearching: false });
                }}>
                &#10005;
              </div>
            )}
          </div>
        </div>
        <div className="menu-secondary-container">
          {this.state.isSearching ? (
            <MenuFind
              data={this.state.contentData}
              isLoading={this.state.isLoading}
              expand={() => this.props.expand()}
              totalPages={this.state.totalPages}
              page={this.state.page}
              searchByPage={(page) => this.searchByPage(page)}
              wordRequired={this.state.wordRequired}
              needPressEnter={this.state.needPressEnter}
            />
          ) : this.state.secondaryExpanded ? (
            <ChatRoomsList
              handleChangeRoom={(r) => this.handleChangeRoom(r)}
              closeChat={() => this.closeChat()}
              expand={() => this.props.expand()}
            />
          ) : (
            <div style={{ display: "flex", flex: 1 }}>
              <div
                className="menu-secondary-left"
                style={{ backgroundColor: getColors("menu").firstColor }}>
                <NavLink
                  to="/"
                  exact
                  onClick={() => this.props.expand()}
                  className="menu-secondary-left-item"
                  activeClassName="left-item-selected">
                  <ReactSVG
                    beforeInjection={(svg) => {
                      svg.setAttribute(
                        "style",
                        `width: 25px; height: 25px; fill: ${
                          getColors("menuItem")?.color || "white"
                        }`
                      );
                    }}
                    src={HomeIcon}
                    alt="home"
                  />
                </NavLink>
                {/* <NavLink to="/messages" onClick={() => this.props.expand()} className="menu-secondary-left-item" activeClassName="left-item-selected">
                                            <ReactSVG
                                                beforeInjection={(svg) => {
                                                    svg.setAttribute('style', `width: 25px; height: 25px; fill: ${getColors('menuItem')?.color || 'white'}`)
                                                }}
                                                src={BellIcon}
                                                alt="notif"
                                            />
                                        </NavLink> */}
                <NavLink
                  to="/perfil"
                  onClick={() => this.props.expand()}
                  className="menu-secondary-left-item"
                  activeClassName="left-item-selected">
                  <ReactSVG
                    beforeInjection={(svg) => {
                      svg.setAttribute(
                        "style",
                        `width: 25px; height: 25px; fill: ${
                          getColors("menuItem")?.color || "white"
                        }`
                      );
                    }}
                    src={GearIcon}
                    alt="config"
                  />
                </NavLink>
                <div
                  onClick={() => this.openChat()}
                  className="menu-secondary-left-item">
                  <ReactSVG
                    beforeInjection={(svg) => {
                      svg.setAttribute(
                        "style",
                        `width: 25px; height: 25px; fill: ${
                          getColors("menuItem")?.color || "white"
                        }`
                      );
                    }}
                    src={ChatIcon}
                    alt="chat"
                  />
                </div>
                {appDetails?.store === 1 && (
                  <NavLink
                    to="/lojinha"
                    onClick={() => this.props.expand()}
                    className="menu-secondary-left-item"
                    activeClassName="left-item-selected">
                    <ShoppingCartIcon
                      style={{
                        width: 25,
                        height: 25,
                        color: getColors("menuItem")?.color || "white",
                      }}
                    />
                  </NavLink>
                )}
              </div>
              <div
                className="menu-secondary-right"
                style={{ backgroundColor: getColors("menu").secondColor }}>
                {menuData.length > 0 &&
                  menuData.map((item, index) => {
                    return (
                      <div
                        id="bottomMenu"
                        key={index}
                        className="menu-item-container"
                        onClick={() => {
                          !["Administrador", "Site Redirect"].includes(item.text) && this.props.expand()
                        }}>
                        {!["Administrador", "Site Redirect"].includes(item.text) ? (
                          <Link
                            to={`/${transformText(item.text)}/${
                              item.sectionTo
                            }`}>
                            <div
                              className="menu-item"
                              style={{ ...getColors("menuItem") }}>
                              <img
                                className="lazyload"
                                data-src={item.image}
                                src={item.image}
                                width={20}
                                height={20}
                                style={{
                                  height: 20,
                                  width: 20,
                                  marginRight: 25,
                                  imageRendering: "-webkit-optimize-contrast",
                                  marginLeft: 0,
                                }}
                                alt="img"
                              />
                              <span className="menu-text"> {item.text} </span>
                            </div>
                          </Link>
                        ) : (
                          <div
                            className="menu-item"
                            style={{
                              ...getColors("menuItem"),
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              item.text == "Administrador" ? this.setState({ ...this.state, showModal: true }) : this.setState({ ...this.state, showRedirect: true, sectionTo: item.sectionTo })                              ;
                            }}>
                            <img
                              src={item.image}
                              style={{
                                height: 20,
                                width: 20,
                                marginRight: 25,
                                imageRendering: "-webkit-optimize-contrast",
                                marginLeft: 0,
                              }}
                              alt="img"
                            />
                            <span className="menu-text"> {item.text} </span>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        {!this.state.hideFooter && !this.state.isSearching && (
          <div
            className="menu-footer-container"
            style={{ backgroundColor: `${getColors("menu").thirdColor}` }}
            onClick={() => {
              this.props.expand();
            }}>
            <Link style={{ display: "flex" }} to="/perfil">
              <div
                className="menu-footer-icon"
                style={{ borderColor: getColors("menu").fourthColor }}>
                <img
                  style={{ color: "white", width: 50, height: 50 }}
                  src={photo}
                  alt="img"
                  width={50} 
                  height={50}
                />
              </div>
              <div
                className="menu-footer-name"
                style={{ justifyContent: "center" }}>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: getColors("menuItem")?.color,
                  }}>
                  {this.state.user.name}
                </span>
                <PointsFooter points={200} text={"pontos"} />
              </div>
              <div style={{ marginLeft: 10, alignSelf: "center" }}>
                <ReactSVG
                  beforeInjection={(svg) => {
                    svg.setAttribute(
                      "style",
                      `width: 7px; height: 14px; fill: ${
                        getColors("menuItem")?.color || "white"
                      }`
                    );
                  }}
                  src={ArrowRight}
                  alt="img"
                />
              </div>
            </Link>
            <div
              className="menu-footer-leave-div"
              style={{
                backgroundColor: `${getColors("menu").firstColor.trim()}50`,
              }}
              onClick={(e) => {
                e.stopPropagation();
                this.logout();
              }}
            >
              <div className="menu-footer-leave">
                <ReactSVG
                  beforeInjection={(svg) => {
                    svg.setAttribute(
                      "style",
                      `width: 30px; height: 30px; fill: ${
                        getColors("menuItem")?.color || "white"
                      }`
                    );
                  }}
                  src={LogoutIcon}
                  alt="img"
                />
              </div>
            </div>
          </div>
        )}
        {this.state.showModal && (
          <AdminAccess
            denyAction={() =>
              this.setState({ ...this.state, showModal: false })
            }
            acceptAction={() =>
              this.setState({ ...this.state, showModal: false })
            }
          />
        )}
        {this.state.showRedirect && (
          <RedirectAccess
            idSection={ this.state.sectionTo }
            denyAction={() =>
              this.setState({ ...this.state, showRedirect: false })
            }
            acceptAction={() =>
              this.setState({ ...this.state, showRedirect: false })
            }
          />
        )}
      </div>
    );
  }
}

export default MenuPrincipal;
