import React from 'react';
import getStyle from './style.js';
import cancelIcon from '../../../../assets/images/cancel-icon.svg';
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';

const RemoveButton = ( { clickHandler } ) => {

    const {hasDesign, getDesign} = useDesignByIdSectionType(34);
    const Style = getStyle(getDesign);


    return (
        <button style={Style.cartListItemDataButton} onClick={clickHandler}>
            <img src={cancelIcon} alt="cancel"/>
        </button>
    )
}

const CartList = ({ itens, enableRemove, removeClickHandler, redirectClickHandler }) => {

    const {hasDesign, getDesign} = useDesignByIdSectionType(34);
    const Style = getStyle(getDesign);

    return (
        <>
            
            { itens.map((data) => {
                    return (
                        <div style={Style.cartListItemWrapper} key={data.idOffer}>
                            <img src={data.imageURL}  style={Style.cartListItemImg} alt="product" onClick={ () => { redirectClickHandler(data) } } />
                            <div style={Style.cartListItemData}>
                                <div style={Style.cartListItemDataText}  onClick={ () =>  redirectClickHandler(data)  } >
                                    {data.title}
                                </div>
                                <div  onClick={ () =>  redirectClickHandler(data)  } style={Style.cartListItemDataPrice}>
                                    {Intl.NumberFormat('pt-BR',{ style: "currency", currency: "BRL", useGrouping: true, }).format(data.price)}
                                </div>
                                { enableRemove && <RemoveButton clickHandler={ () => removeClickHandler(data.idOffer) } />}
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
}

export default CartList;