import { getColors } from '../../../../services/utils';

const getStyle = (getDesign, width) => {
    const isMobile = (width < 800);

    const Style = {
        lojinhaCartTotalWrapper:{
            width: '100%',
            backgroundColor: '#fff',
            padding: '20px',
            boxShadow: '0 -6px 8px 0 rgb(0 0 0 / 20%)',
        },
        lojinhaCartTotalPriceWrapper:{
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'row',
            boxShadow: '0px 2px 10px 0 rgb(0 0 0 / 50%)',
            border:`2px solid ${getColors('menu').firstColor}`,
        },
        lojinhaCartTotalDataWrapper:{
            width: '100%',
            justifyContent: isMobile ? 'center' : 'flex-end',
            display: 'flex',
        },
        lojinhaCartTotalDataWrapperButton:{
            ...getDesign('itemDescription'), 
            backgroundColor: getColors('menu').firstColor, 
            color: '#fff',
            height: '100%',
            border: 'none',
            padding: '10px',
            fontWeight: 700,
        },
        
        lojinhaCartTotalPriceNumber:{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            fontWeight: '700',
            boxShadow: '0 6px 8px 0 rgb(0 0 0 / 20%)',
        },
        
        lojinhaNextIcon:{
            transform: 'rotate(180deg)',
            padding: '5px',
            height: '24px',
            width: '24px',
        },

        price:{
            ...getDesign('itemText'),
        },

        styleTotal: {
            paddingRight: '10px', 
            ...getDesign('itemDescription') 
        },

        lojinhaBackIcon:{
            padding: '5px',
        }
    }

    return Style;
}

export default getStyle;