import { getColors } from '../../../../services/utils';

export const getStyle = (getDesign) => {

    const Style = {
        lojinhaHeaderWrapper:{
            backgroundColor: getColors("menu").firstColor,
            position: 'fixed',
            height: '54px',
            width: '100%',
            display: 'flex',
            top: '0',
            zIndex: 1500,
        },
        lojinhaHeaderButton:{
            height: '54px',
            width: '54px',
            backgroundColor: 'transparent',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        lojinhaHeaderSearchWrapper:{
            padding: '9px',
            width: 'inherit',
        },
        lojinhaHeaderSearchInput:{
            ...getDesign('itemDescription'),
            borderRadius: '22px',
            height: '36px',
            border: 'none',
            width: '100%',
            paddingLeft: '40px',
            fontWeight: '700',
            outline: 'none',
        },
        lojinhaHeaderSearchLabel:{
            width: '100%',
            position: 'relative',
        },
        lojinhaHeaderSearchSpan:{
            position: 'absolute',
            left: '11px',
            width: '18px',
            height: '18px',
            top: '9px',
        },
        lojinhaHeaderSearchSpanImg:{
            verticalAlign: 'top',
        }
    }

    return Style;
}

export default getStyle;