import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import getStyle from './style.js'
import Back from '../../../../assets/images/backButton.svg';
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';
import CartComponent from '../CartComponent';

const HeaderBack = ( { quantidadeItensCarrinho }) => {
    const history = useHistory();
    const goBack = () => {history.goBack();}

    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign)
    
    return(
        <div style={Style.lojinhaHeaderWrapper}>
            <button style={Style.lojinhaHeaderButton} onClick={goBack}>
                <img src={Back} alt="back"/>
            </button>
            <div style={Style.lojinhaHeaderSearchWrapper}>
                {getDesign('title')?.text}
            </div>
            <CartComponent quantidadeItensCarrinho={quantidadeItensCarrinho}/>
        </div>
    );
}

HeaderBack.propTypes = {
    path: PropTypes.string,
};
  
HeaderBack.defaultProps = {
    path: '',
    quantidadeItensCarrinho: 0
};


export default HeaderBack;