import { UPDATE_VALUE, UPDATE_HEADER, UPDATE_CONTENT_TYPE,UPDATE_POINTS, UPDATE_SCORE_POINTS } from './actionTypes';

export const updateFooterStatus = value => ({
    type: UPDATE_VALUE,
    footerStatus: value
});

export const updateHeaderStatus = value => ({
    type: UPDATE_HEADER,
    headerStatus: value
});

export const updateContentType = value => ({
    type: UPDATE_CONTENT_TYPE,
    currentContentType: value
});

export const updateScorePoints = value => {
    return ({
        type: UPDATE_SCORE_POINTS,
        scorePoints: value}
       ) 
};
export const updatePoints = value => {
   return ({
    type: UPDATE_POINTS,
    points: value}
   ) 
};