import React, { useEffect, useState } from "react";
import {
  getSectionById,
  getDesign,
} from "../services/utils";
import { updateFooterStatus, updateHeaderStatus } from "../actions";
import { connect } from "react-redux";
import MosaicList from "../components/MosaicList";

function _getMenuData(idSection) {
  let data = getSectionById(idSection);
  return data.menuOption;
}

function Mosaic({ idSection, updateFooterStatus, updateHeaderStatus }) {
  const [menuData, setMenuData] = useState(null);

  useEffect(() => {
    updateFooterStatus(
      getDesign(idSection, "general")?.showBottomMenu || "true"
    );
    updateHeaderStatus(getDesign(idSection, "general")?.showTopBar || "true");
    setMenuData(_getMenuData(idSection));
  }, []);

  return <MosaicList data={menuData} idSection={idSection} />
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(Mosaic);
