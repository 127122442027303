import React from 'react';
import Style from './style.js';
import ProgressBar from '../../Components/ProgressBar';
import Ok from './Component/Ok';
import Error from './Component/Error';

const ShowCartFinish = ({ stage , result, errorHandler }) => {
    return (
        <>
            <ProgressBar stage={stage} />
            <div style={Style.lojinhaCartFinishScreen}>
                <div style={Style.lojinhaCartFinishWrapper}>
                    { (result) ? (<Ok />) : ( <Error errorHandler={errorHandler}/> ) }
                </div>
            </div>
        </>
    )
}

export default ShowCartFinish;