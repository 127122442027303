import React, { useState } from 'react'; // TODO item não utilizado
import ReactStars from 'react-stars';
import { setRating } from '../../services/api';
import StarIcon from '@material-ui/icons/Star';
import Button from '@material-ui/core/Button';
import { getColors } from '../../services/utils';

function Rating(props) {
  async function _setRating(vote) {
    const data = {
      idContentItem: props.id,
      vote: vote,
    };
    setRating(data)
      .then((res) => {})
      .catch((err) => {
        console.log('error: ', err);
      });
  }

  // check if a value of star is already set (used on content first line)
  if (typeof props.stars != 'undefined') {
    return (
      <div
        className="rating-container-existing"
        style={{ justifyContent: 'flex-start', paddingLeft: 0, ...props.style }}
      >
        <div style={{ marginRight: 5, fontFamily: 'PT Sans Narrow' }}> </div>
        <ReactStars
          count={1}
          color1={'lightgray'}
          value={props.stars}
          size={18}
          edit={false}
          color2={'#ffd700'}
        />

        {props.stars > 1 ? (
          <div className="rating-number-votes">
            <span>
              <span
                style={{ color: '#ffd700', fontWeight: 'bold', marginRight: 5 }}
              >
                {props.stars}{' '}
              </span>
              <span style={props.styleStarsAvalie}>
                ( {props.numberVotes} )
              </span>
            </span>
          </div>
        ) : (
          <div
            className="rating-number-votes-zero"
            style={props.styleStarsAvalie}
          >
            <span>( - )</span>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        className="rating-container"
        style={{
          justifyContent: !props.isDesktop ? 'flex-end' : 'flex-start',
          ...props.style,
        }}
      >
        <div
          style={{ marginTop: 2, marginRight: 2, fontFamily: 'PT Sans Narrow' }}
        >
          Avalie este conteúdo:{' '}
        </div>
        <ReactStars
          count={5}
          value={props.userStar}
          half={false}
          onChange={(newRating) => _setRating(newRating)}
          size={20}
          color1={'lightgray'}
          color2={'#ffd700'}
        />
      </div>
    );
  }
}

function RateCard(props) {
  const [isRating, setIsRating] = useState(props.isRating || false);
  const [value, setValue] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [feedback, setFeedback] = useState(false);
  async function _setRating(vote) {
    setFeedback(true);
    const data = {
      idContentItem: props.id,
      vote: vote,
    };
    await setRating(data).then((res) => {
      setTimeout(() => {
        setFeedback(false);
        props.setStars(vote);

        props.setNumberVotes(props.numberVotes);
        props.setIsRating(false);
      }, 2000);
    });
  }
  function rate() {
    return (
      <div
        className="rate-container"
        style={{
          backgroundColor: '#fff',
          width: '120%',
          marginLeft: '-4%',
          justifyContent: 'left',
          ...props.rateContainer,
        }}
      >
        {!feedback ? (
          <>
            <ReactStars
              count={5}
              value={value}
              half={false}
              onChange={(value) => {
                setValue(value);
                setDisabled(false);
              }}
              size={25}
              color1={'lightgray'}
              color2={'#ffd700'}
            />
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginLeft: 4,
                  backgroundColor: disabled
                    ? '#fff'
                    : getColors('menu').firstColor,
                  fontSize: 13,
                  width: '30%',
                  height: '60%',
                }}
                disabled={disabled}
                onClick={() => _setRating(value)}
              >
                Enviar
              </Button>
            </div>
          </>
        ) : (
          <div style={props.buttonPosition}>
            <span
              style={{ color: '#262626', fontSize: 12, ...props.styleFeedBack }}
            >
              Obrigado por avaliar esse contéudo
            </span>
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      {!isRating ? (
        <div
          className="rate-container"
          onClick={(e) => {
            e.stopPropagation();
            setIsRating(true);
          }}
          style={{
            justifyContent: !props.isDesktop ? 'flex-end' : 'flex-start',
            ...props.rateContainer,
          }}
        >
          <StarIcon style={{ color: 'white', ...props.styleStarsAvalie }} />
          <div
            style={{
              marginTop: 2,
              marginRight: 5,
              fontFamily: 'PT Sans Narrow',
              color: 'white',
              ...props.styleStarsAvalie,
            }}
          >
            {`${props.avalie || 'Avalie'}`}{' '}
          </div>
        </div>
      ) : (
        <>{rate()}</>
      )}
    </>
  );
}

export { Rating, RateCard };
