import { UPDATE_CONTENT_TYPE } from '../actions/actionTypes';

const initialState = {
    currentContentType: null,
  };

export const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CONTENT_TYPE:
        return {
            ...state,
            currentContentType: action.currentContentType
        };
        default:
        return state;
    }
};