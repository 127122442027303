import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import ModalWarning from "../../components/ModalWarning";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import MailIcon from '@material-ui/icons/Mail';
import { appDetails as getAppDetails } from "../../services/api";

const LandingPage = () => {
  const [appDetails, setAppDetails] = useState(JSON.parse(localStorage.getItem("appDetails")) || {});
  const history = useHistory();
  const [showContactModal, setShowContactModal] = useState(false)
  const goToLogin = () => {
    history.push("/login?fromLP=true")
  }
  const _appDetails = async () =>  {
    if (Object.keys(appDetails).length === 0) {
      const bucketUrl = window.location.host;
      const staticUrl = "homolog.blast.etc.br";
      // smartapp.digital
      // homolog.bedigital.net.br
      // kryptosapp.com
      const currentUrl =
      process.env.NODE_ENV === "development" ? staticUrl : bucketUrl;
      try {
        const result = await getAppDetails(currentUrl);
        if (result.data.app.length == 0) {
          return;
        }
        localStorage.setItem("appDetails", JSON.stringify(result.data.app[0]));
        document.title = result.data.app[0].name;
      } catch (e) {
        console.log('error', e)
      }
      setAppDetails({...JSON.parse(localStorage.getItem('appDetails'))});
    }
  }

  useEffect(() => {
    _appDetails()
  }, [])

  return (
    <div className="login-landpage-container">
      <ModalWarning
        parentShowModal={showContactModal}
        onCloseParent={() => setShowContactModal(false)}
        acceptAction={() => setShowContactModal(false)}
        hideDeny
        acceptText="Continuar"
        icon={
          <ContactMailIcon 
            style={{ 
              color: 'white',
              fontSize: 75 
            }}
          />
        }
        description={
          <div style={{ textAlign: "center", display: "flex", alignItems: "center" }}>
            <MailIcon style={{ color: 'gray', fontSize: 30, marginRight: 5 }} />
            {appDetails.landingPageContact}
          </div>
        }
      />
      <div className="login-landpage-header">
        <div>
          <img
            className="login-landpage-img"
            src={appDetails?.landingLogo}
            alt="landing-page-logo"
          />
        </div>
        <div className="login-landpage-header-right">
          <div
            onClick={setShowContactModal}
            className="login-landpage-contact-button"
          >
            Contato
          </div>
          <div>|</div>
          <div>
            <button
              className="btn botao-login"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                padding: "5px 30px 7px",
                backgroundColor: appDetails?.primaryColor,
              }}
              type="button"
              onClick={goToLogin}
            >
              <div>Login</div>
            </button>
          </div>
        </div>
      </div>
      <iframe
        src={appDetails.landingPageLocation}
        height="100%"
        width="100%"
        frameBorder="0"
        allowFullScreen
        title="landing-page-iframe"
      />
    </div>
  );
};

export default LandingPage;
