import React, { useState, useEffect } from 'react';
//import CreditCard from './Components/CreditCard';
import './style.css';
import CreditCard from '../../Components/Payment/Components/PaymentMethod/CreditCard';
import PaymentSelector from './Components/PaymentSelector';

const Payment = ({ getPaymentData }) => {
    const [choosenPayment, setChoosenPayment] = useState(-1);
    const useCredit = CreditCard({update: () => {getPaymentData(PaymentMethod[choosenPayment])} });
    const PaymentMethod = [
        useCredit
    ]

    useEffect( () => {
        getPaymentData(PaymentMethod[choosenPayment]);
    },[choosenPayment])

    useEffect( () => {
        if(PaymentMethod.length === 1){
            setChoosenPayment(0);
        }
    },[])
    
    return (
        <>
            <PaymentSelector>
                    {PaymentMethod.map((payment, index) => {
                        const blur = Boolean(choosenPayment !== -1 && choosenPayment !== index);
                        return payment.getIcon({blur: blur, onClickHandler: () => { setChoosenPayment(index) } } )
                    })}
            </PaymentSelector>
            
            { (choosenPayment !== -1) && PaymentMethod[choosenPayment].getForm() }
        </>
    )
}

export default Payment;