import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import '../assets/css/YTPlaylist.css';
import { setCacheData, updateYLDuration } from '../services/api';
import { getListDuration, getVideosFromPlaylist } from '../services/youTubeApi';
import { getVideosFromWistiaPlaylist } from '../services/wistiaApi';
import { getFooterSize } from '../services/utils';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';

import VideoCard from '../components/VideoCard';
import { getDesign } from '../services/utils';

const YTPlaylist = (props) => {
	const [isDesktop, setIsDesktop] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [mediaLocation, setMediaLocation] = useState('youtube')
	const isMounted = useRef(null);

	function resize() {
		setIsDesktop(window.innerWidth > 900);
	}

	const extractHostname = (url) => {
		var hostname;
	
		if (url.indexOf("//") > -1) {
			hostname = url.split('/')[2];
		}
		else {
			hostname = url.split('/')[0];
		}
	
		hostname = hostname.split(':')[0];
		hostname = hostname.split('?')[0];
	
		return hostname;
	}

	const getVideoCallBack = useCallback(async () => {
		setIsLoading(true);
		let site = props.data.contentLocation.search("wistia") !== -1 ? 'wistia' : 'youtube'
		
		if(site === 'youtube')
		{
			const video = await getVideosFromPlaylist(props.data.contentLocation);
			if (isMounted.current) {
				setData(video);
				setCacheData(video, 'YTPlaylist');
			}
		} else {
			let arr = props?.data?.contentLocation?.split('/')
			setMediaLocation('wistia')
			let id = arr[arr.length - 1];
			const video = await getVideosFromWistiaPlaylist(id);
			setData(video.data.medias);
			//setCacheData(video, 'YTPlaylist');
		}
		setIsLoading(false);

	},[props.data.contentLocation]);

	useEffect(() => {
		isMounted.current = true;
		if (isMounted.current) {
			getVideoCallBack();
		}
		return () => {
			isMounted.current = false;
		};
	}, [getVideoCallBack]);

	useEffect(() => {
		const { updateHeaderStatus, updateFooterStatus } = props;
		updateFooterStatus(
			getDesign(props.idSection, 'general')?.showBottomMenu || 'true'
		);
		updateHeaderStatus(
			getDesign(props.idSection, 'general')?.showTopBar || 'true'
		);
		resize();
		window.addEventListener('resize', resize);
		return () => {
			window.removeEventListener('resize', resize);
		};
	}, []);

	useEffect(() => {
		getDuration();
	}, [data]);

	const getDuration = async () => {
		if(mediaLocation === 'youtube')
		{
			const idList = data.map((item) => {
				return item.snippet.resourceId.videoId;
			});
			const duration = await getListDuration(idList);
			if (duration > 0) {
				const result = await updateYLDuration(props.idContentItem, duration);
			}
		} else{
			const reducer = (previousValue, currentValue) => Number(previousValue) + Number(currentValue.duration);
			const duration = data.reduce(reducer,0)
			if (duration > 0) {
				const result = await updateYLDuration(props.idContentItem, duration);
			}
		}
	};

	return (
		<div
			className="container-fluid cards-container ytplaylist"
			style={{ ...getDesign(props.idSection, 'child'), ...getFooterSize() }}
		>
			<div className="video-list">
				{ isLoading ? ( <div className="loader loader-center loader-big loader-purple"></div>) : null}
				{ (!isLoading && data.length === 0) ? ( 
					<> <div></div> <div style={{width:'100%', textAlign:"center"}}> Não há vídeos disponíveis nesta lista </div> </>
					) : null}
				{ (!isLoading && data.length > 0) ? ( 
					data.map((item, index) => {
						const pathname = (mediaLocation === "wistia") ? 
							`/wtplayer/${item.hashed_id}?idSection=${props.data.idSection}&content=${props.data.idContentItem}&type=${props.data.idContentType}` : 
							`/ytplayer/${item.snippet.resourceId.videoId}?idSection=${props.data.idSection}&content=${props.data.idContentItem}&type=${props.data.idContentType}`;
						const image = item?.snippet?.thumbnails?.standard ? item?.snippet?.thumbnails?.standard?.url : item?.snippet?.thumbnails?.default?.url
						const thumbnails = (mediaLocation === "wistia") ? 
							item.thumbnail.url : image
						const title = (mediaLocation === "wistia") ? item?.name : item?.snippet?.title
						const  description = (mediaLocation === "wistia") ? item?.description : item?.snippet?.description


						return (
							<VideoCard
								mediaLocation={mediaLocation}
								key ={item.id}
								pathname={pathname}
								title={title}
								description={description}
								index={index}
								imgsrc={
									thumbnails
										? thumbnails
										: null
								}
								concluded={item.concluded}
								progress={item.progress}
								idSection={props.idSection}
								duration={item.duration}
							/>
						);
					})
				) : null}
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
	updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(YTPlaylist);
