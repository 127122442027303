
import React from 'react'; 
import { ReactComponent as PinProgressOff } from '../../../../../../assets/images/pin-progress-off.svg';
import { ReactComponent as PinProgressOn } from '../../../../../../assets/images/pin-progress-on.svg';
import { ReactComponent as PinProgressSpace } from '../../../../../../assets/images/pin-progress-space.svg';
import Style from './style';

import { getColors } from '../../../../../../services/utils';

const PinStatus = {
    Off: 0,
    Hollow: 1,
    Full: 2
}

const Pin = ( { status, onClick } ) => {
    
    let pin = (<PinProgressSpace />)
    
    if( status === PinStatus.Hollow ){ pin = ( <PinProgressOff style={ {stroke: getColors('menu').firstColor} } /> ) }
    else if( status === PinStatus.Full ){ pin = ( <PinProgressOn  style={ {fill: getColors('menu').firstColor} } /> ) }

    return (
        <div style={Style.lojinhaProgressPinSpace} onClick={onClick}>
            {pin}                    
        </div>
    );
}

export default Pin;