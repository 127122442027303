const Style = {
    lojinhaCartDynamicInfo:{
        width: '100%',
        height: '100%',
        padding: '10px',
        overflowY: 'auto',
        marginTop: '93px',
        maxWidth: '800px',
    }
}

export default Style