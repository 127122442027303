import React from 'react';
import PropTypes from 'prop-types';
import getStyle from './style.js';
import {ReactComponent as HomeCart} from '../../../../assets/images/lojinha-home-cart.svg'
import {ReactComponent as HomeCheck} from '../../../../assets/images/lojinha-home-check.svg'
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';


const ProductList = function({ list, clickHandler }){

    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign);

    return (
        <div style={Style.listWrapper} >
            {list.map((data) => {
                return (
                    <button style={Style.lojinhaItem} key={data.idProduct} onClick={()=>{clickHandler(data)}}  >
                        <div style={Style.lojinhaItemImage}>
                            <img style={Style.lojinhaItemImageImg} src={data.imageURL} alt={`img_${data.idProduct}`}/>
                        </div>
                        <div style={Style.lojinhaItemName}>
                            {data.title}
                        </div>
                        <div style={Style.lojinhaItemDescription}>
                            {data.description}
                        </div>
                        <div style={Style.lojinhaItemHomeDetail}>
                            <div style={Style.lojinhaItemHomeDetailText}>
                                <div style={Style.lojinhaItemPrice}>
                                    {Intl.NumberFormat('pt-BR',{ style: "currency", currency: "BRL", useGrouping: true, }).format(data.price)}
                                </div>
                                <div style={Style.lojinhaItemHomeDetailTextSaiba}>
                                    Saiba +
                                </div>
                            </div>
                            <div style={Style.lojinhaItemHomeDetailImg}>
                                { !Boolean(data.aquired) ? 
                                    ( <HomeCart style={Style.homeCart} /> ) :
                                    ( <HomeCheck /> )
                                }
                            </div>
                        </div>
                    </button>
                );
            })}
        </div>
    );
};

ProductList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.object),
    callback: PropTypes.func,
};
  
ProductList.defaultProps = {
    list: [],
    callback: ()=>{},
};

export default ProductList;