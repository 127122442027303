import React, {useState, useEffect} from 'react';
import CarouselComponent from '../CarouselComponent';
import { getDesign } from '../../services/utils';
import { contentList, findContent } from '../../services/api'
import { getVideosFromPlaylist } from '../../services/youTubeApi';

const CarouselList  = ({ carousel, props, idSection, designSection }) => {

    const [carouselItens, setCarouselItens] = useState([])

    useEffect(()=>{
        getCarouselItens()
    },[carousel])

    const getCarouselItens = async () => {
        let aux = []

        for await (const item of carousel) {
            if( item.contentLocation ){
                const info = await contentList(item.contentLocation)

                let obj = {
                    nome: item.text,
                    idSectionType: info?.data?.idSectionType,
                    idSection: info?.data?.idSection
                };

                let content = [];
                if(info?.data?.idSectionType === 13){
                    for( const item of info?.data?.content){
                        const aux2 = await getVideoListItens(item)
                        content = [ ...content, ...aux2]
                    }

                }else{
                    content = info?.data?.content
                }

                obj.content = content;                
                aux = [...aux, obj]
            }
        }
        setCarouselItens(aux)
    }

    const getVideoListItens = async (item) => {
        let site = item?.contentLocation?.search("wistia") !== -1 ? 'wistia' : 'youtube';
        if(site === 'youtube')
        {
            const video = await getVideosFromPlaylist(item?.contentLocation);
            const videos = video.map((item) => {
                return {
                    ...item.snippet, 
                    thumbnails: item?.snippet?.thumbnails?.standard ? item?.snippet?.thumbnails?.standard?.url : item?.snippet?.thumbnails?.default?.url
                } 
            })
            return videos;
        } else {
            let arr = item?.contentLocation?.split('/')
            //setMediaLocation('wistia')
            let id = arr[arr.length - 1];
            //const video = await getVideosFromWistiaPlaylist(id);
            //setData(video.data.medias);
            //setCacheData(video, 'YTPlaylist');
        }
    }

    const design = getDesign(designSection || idSection, "itemText") || {};

    return(
        <div className='container'>
            {carouselItens?.map( (dados, index) => {
                return (
                    <div key={index}>
                        <div style={{ padding: '20px 0px', ...design }}>
                            {dados.nome}
                        </div>
                        {<CarouselComponent itens={dados.content} idSection={dados.idSection} type={dados.idSectionType}/>}
                    </div>
                )
            })}
        </div>
    );
}

export default CarouselList
