import { getColors } from '../../../../../../services/utils';

const getStyle = (getDesign) => {
    const Style = {
        lojinhaPaymentCreditCard:{
            backgroundColor: '#fff',
            marginTop: '15px',
            padding: '20px',
        },
        lojinhaPaymentCreditCardLabel:{
            ...getDesign('itemText'),
            width: '100%',
            textAlign: 'center',
        },
        lojinhaPaymentCreditCardForm:{

        },
        lojinhaPaymentCreditCardFullWidthForm:{
            ...getDesign('itemText'),
            padding: '5px 0px 0px 0px', 
            textAlign: 'left',
        },
        lojinhaPaymentCreditCardHalfWidthFormWrapper:{
            display:'flex',
        },
        lojinhaPaymentCreditCardHalfWidthForm:{
            ...getDesign('itemText'),
            width: '50%',
            padding: '5px 0px 0px 5px',
            textAlign: 'left',
        },
        inputInnerWrapper:{
            position: 'relative',
        },
        inputOuterWrapper:{
            position: 'relative',
        },
        lojinhaCreditCardInput:{
            ...getDesign('itemDescription'),
            color: '#000',
            display: 'block',
            width: '100%',
            height: '40px',
            fontSize: '16px',
            border: '1px solid #E2E6EE',
            outline: '0',
            borderRadius: '10px',
            paddingLeft: '10px',
        },
    }
    return Style;
}

export default getStyle;