import React, { useState } from "react"; // TODO item não utilizado
import ReactStars from 'react-stars'; // TODO item não utilizado
import { getColors } from '../services/utils';


function Loader({ message = 'Carregando...', customStyle = {}, customTextStyle = {} }) {
        return (
            <div className="loader-container" style={customStyle}>
                <div style={{ 
                    borderTopColor: getColors('menu').fourthColor,
                    position: 'initial',
                    margin: 'initial'
                    }} 
                    className="loader loader-center loader-big">
                </div>
                <span style={{
                    marginTop: 10,
                    color:'rgba(0,0,0,0.7)',
                    ...customTextStyle
                }}>
                    { message }
                </span>
            </div>
        );
    
}

export default Loader;
