import React, { useState } from 'react';
import {Rating, RateCard} from "../Rating";
import styles from './styles.module.scss'


export default function Rate ({
    stars, 
    numberVotes, 
    id, 
    avalie=null, 
    styleStarsAvalie=null, 
    click=false, 
    rateContainer={},
    rateAction={}, 
    styleFeedBack={}, 
    classNameContainer="",
    classNameRatingContainer=""
}){
    const [_stars, setStars] = useState(stars)
    const [isRating, setIsRating] = useState(false)
    const [_numberVotes, setNumberVotes] = useState(numberVotes)
    const style = {
       divButton: {
            paddingTop: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
       } ,
       rating:{
            height: '40px',
            float:  _stars  && !isRating ? 'right' : 'none',
       }
    }

    return (
        <div className={`rating ${classNameContainer}`} style={style.rating} onClick={(e) => {
            e.stopPropagation()
            if(click){
                setIsRating(true)
            } 
            }}
        >
            {
                _stars  && !isRating? 
                (
                    <div className={`${classNameRatingContainer} ${styles.ratingContainer}`}>
                        <Rating
                            stars={_stars}
                            numberVotes={_numberVotes}
                            styleStarsAvalie={styleStarsAvalie}
                        />
                    {
                        avalie && 
                            (
                                <div style={{  
                                    marginTop: 2, 
                                    marginRight: 5, 
                                    fontFamily: 'PT Sans Narrow',
                                    color:'white' ,
                                    display: "flex", 
                                    alignItems: 'center',
                                    ...styleStarsAvalie}} >
                                    {avalie}
                                </div>
                            )
                    }  
                    </div>
                ) :  
                ( 
                    <RateCard
                        id={id}
                        numberVotes={_numberVotes}
                        stars={_stars}
                        setStars={setStars}
                        setNumberVotes={setNumberVotes}
                        buttonPosition={style.divButton}
                        styleStarsAvalie={styleStarsAvalie}
                        styleFeedBack={styleFeedBack}
                        avalie={avalie}
                        rateContainer={rateContainer}
                        rateAction={rateAction}
                        isRating={isRating}
                        setIsRating={setIsRating}

                    />
                ) 
            }  
        </div>
    )
  
}