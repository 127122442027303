import React, { useState, useMemo } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios';
import { getToken } from '../services/api';
import { getColors, getDesign, getFooterSize } from '../services/utils';

import '../assets/css/UploadTemplate.scss'
import uploadIcon from '../assets/images/cloud-uploading.svg'

const UploadTemplate = ({ idSection }) => {
  const [values, setValues] = useState({ title: '', file: null, description: '' })
  const [isLoading, setIsLoading] = useState(false)

  const preview = useMemo(() => {
    if (values.file && values.file.type.includes('image')) {
      return values.file ? URL.createObjectURL(values.file) : null;
    }
  }, [values.file])

  const handleValueChange = (prop) => (event) => {
    setValues({ ...values, [prop]: prop !== 'file' ? event.target.value : event.target.files[0] })
  }

  const resetValues = () => setValues({ title: '', file: null, description: '' })

  async function sendFiles(event) {
    event.preventDefault()

    if(isLoading) return false

    if(!values.title) { 
      Swal.fire({ icon: 'warning', text: 'Título é um campo obrigatório.' })
      return false
    } 

    if(!values.description) { 
      Swal.fire({ icon: 'warning', text: 'Descrição é um campo obrigatório.' })
      return false
    } 

    if(!values.file) { 
      Swal.fire({ icon: 'warning', text: 'É necessário fazer o upload de um arquivo.' })
      return false
    } 
    
    setIsLoading(true)

    const formData = new FormData()
    formData.append('idSection', idSection)
    formData.append('fileData', values.file)
    formData.append('title', values.title)
    formData.append('description', values.description)

    const token = getToken()
    const params = { 
      method: 'POST',
      url: `${process.env.REACT_APP_UPLOAD}/uploads/usercontent`,
      data: formData,
      headers: { 
        'Content-Type': 'multipart/form-data', 
        'Authorization': `Bearer ${token}`  
      }
    }
  
    await axios(params).then(() => {
      Swal.fire({ icon: 'success', text: 'Dados enviados com sucesso!' })
      resetValues()
      setIsLoading(false)
    }).catch(error => {
      Swal.fire({ icon: 'error', text: error.response.data.error || error.response.data.message })
      setIsLoading(false)
    })
  }

  const parseType = (type) => {
    const validTypes = [
      'jpg', 'jpeg', 'png', 'pdf', 'mp4', 'mp3',
      'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'
    ]
    const lastIndex = type.split('.').length - 1
    const extension = type.split('.')[lastIndex]
    return validTypes.includes(extension) ? extension : 'invalid-type'
  }

  return (
    <div className="upload-template" style={{ ...getFooterSize(), backgroundColor: getColors('menu')?.fourthColor || '#d0d0d0' }} >
      <form className="upload-template__form" onSubmit={sendFiles} style={{ color: '#333', ...getDesign(idSection, 'general') }}>

        <div className="upload-template__wrapper">
          <label htmlFor="title">Título</label>
          <input
            value={values.title}
            onChange={handleValueChange('title')}
            className="upload-template__title"
            type="text" id="title"
          />

          <label htmlFor="desc">Descrição</label>
          <textarea
            value={values.description}
            onChange={handleValueChange('description')}
            className="upload-template__desc"
            type="text" id="desc"
          />
        </div>

        <div className="upload-template__wrapper file">
          <label htmlFor="file">Arquivo</label>
          <div className="file__wrapper" style={{ backgroundImage: `url(${preview})` }} >
            <img src={uploadIcon} alt="" />
            { values.file ? 
              <div className="file__infos">
                <i className={parseType(values.file.name)}></i>
                <span>{values.file.name}</span>
              </div>
            :
              <div className="file__infos empty">
                <span>jpg, png, pdf, doc, xls, ppt, mp3, mp4, avi.</span>
              </div>
            }
            <input
              className="file__input"
              type="file" id="file"
              onChange={handleValueChange('file')}
              encType="multipart/form-data"
            />
          </div>

          <input
            className="upload-template__submit"
            style={{ 
              backgroundColor: getColors('menu')?.fourthColor || '#d0d0d0',
              borderColor: getColors('menu')?.secondColor || '#d0d0d0'
             }}
            type="submit" value={isLoading ? 'AGUARDE...' : 'ENVIAR' }
          />
        </div>
      </form>
    </div>
  )
}

export default UploadTemplate