import { getColors } from '../../../../services/utils';

const getStyle = (getDesign) => {

    const isMobile = (window.width < 800);

    const Style = {
        listWrapper:{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        lojinhaItem:{
            backgroundColor: '#fff',
            width: '100%',
            margin: '0 20px 9px 20px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            border: 'none',
            padding: '0',
            textAlign: 'left',
            maxWidth: '378px',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
        lojinhaItemImage:{
            width: '100%',
            height: 'auto',
        },
        lojinhaItemImageImg:{
            width: '100%',
        },
        lojinhaItemName:{
            ...getDesign('itemText'),
            fontWeight: 'bold',
            padding: '15px',
            width: '100%',
        },
        lojinhaItemDescription:{
            ...getDesign('itemDescription'),
            padding: '0px 15px 15px 15px',
            flex: '1 1 auto'
        },
        lojinhaItemPrice:{
            ...getDesign('itemText'),
            fontWeight: 'bold',
        },
        lojinhaItemHomeDetail:{
            padding: '0px 15px 15px 15px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        lojinhaItemHomeDetailText:{
        
        },
        lojinhaItemHomeDetailImg:{
        },
        lojinhaItemHomeDetailTextSaiba:{
            ...getDesign('itemDescription'),
            textDecoration: 'underline',
        },
        homeCart:{
            fill: getColors('menu').firstColor,
        },
    }

    return Style;
}

export default getStyle;