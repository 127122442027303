import React from 'react';
import getStyle from './style.js';
import BackButton from '../../../../assets/images/darkBackButton.svg'
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const CartTotal = ( { data, nextCallback }) => {
    
    const { width } = useWindowDimensions();
    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign, width);
    const hasPrice = Boolean(data.totalPrice)

    return (
        <div style={Style.lojinhaCartTotalWrapper}>
            <div style={Style.lojinhaCartTotalDataWrapper} >
                <div style={Style.lojinhaCartTotalPriceWrapper}>
                    <div style={Style.lojinhaCartTotalPriceNumber}>
                        <div style={Style.styleTotal}>
                            TOTAL 
                        </div>
                        <div style={Style.price}>
                            {Intl.NumberFormat('pt-BR',{ style: "currency", currency: "BRL", useGrouping: true, }).format(data.totalPrice)}
                        </div>
                    </div>
                    { hasPrice && (
                        <button onClick={nextCallback} style={Style.lojinhaCartTotalDataWrapperButton} > 
                            Próximo 
                            <img src={BackButton} style={Style.lojinhaNextIcon} alt="next-icon"/>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CartTotal;