import React, { useState, useRef } from "react"; // TODO - item não utilizado
import { Switch, Route, withRouter, Redirect, useLocation } from "react-router-dom"; // TODO - item não utilizado
import { TransitionGroup, CSSTransition } from "react-transition-group";
import TelaPrincipal from "./TelaPrincipal";
import Header from "./Header";
import MenuExpand from "./MenuExpand";
import Footer from "./Footer";
import NotFound from "./NotFound";
import { isAuthenticated, getMenuData } from "../services/api"; // TODO - item não utilizado
import { getSectionById, transformText, getAllMenuOptions, getColors,getPoints } from '../services/utils'; // TODO - item não utilizado

import Login from "../container/Login";
import Profile from "../container/Profile";
import TemplateSelector from "../container/TemplateSelector";
import ContentSelector from "../container/ContentSelector";
import EditarPerfil from "../container/EditarPerfil";
import YTPlayer from "../container/YTPlayer";
import WTPlayer from "../container/WTPlayer";
import LivePlayer from "../components/LivePlayer";
import UploadTemplate from "./UploadTemplate";
import UploadUser from "./UploadUser";

import queryString from 'query-string';
import Messages from "../container/Messages";
import MessageFull from "../container/MessageFull";
import LojinhaHome from "../container/Lojinha/Pages/Home";
import LojinhaDetail from "../container/Lojinha/Pages/Detail";
import LojinhaCart from "../container/Lojinha/Pages/Cart";
import { connect } from 'react-redux';
import { compose } from 'redux';
import ChatRoom from "./mini-chat/ChatRoom";
import Library from "../components/Library";
import ListarPerfis from "./ListarPerfis";
import PerfilUsusario from "../container/PerfilUsuario";
import { updatePoints} from '../actions';
import CardMenu from "../container/CardMenu";
import LandingPage from "../container/LandingPage";
import ActivateAccount from "../container/ActivateAccount";
import Termos from "../container/Termos";
import Registro from "../container/Registro";
import Obrigado from "../container/Obrigado";



function showFooter(location) {
  // Define quais rotas não deve mostrar o footer
  const showOnRoutes = [
    "/login",
    "/landing-page",
    "/activate-user",
    "/termos",
    "/registro",
    "/obrigado",
    "/selfregister"
  ]

  let search = queryString.parse(location.search)

  let showFooter = showOnRoutes.includes(location.pathname) || search.showBottomMenu === "0"

  return !showFooter
}

function showHeader(location) {
  // Define quais rotas NÃO deve mostrar o header
  const showOnRoutes = [
    "/login",
    "/landing-page",
    "/activate-user",
    "/termos",
    "/registro",
    "/obrigado",
    "/selfregister"
  ]

  let search = queryString.parse(location.search)

  let showHeader = showOnRoutes.includes(location.pathname) || search.showTopBar === "0"

  return !showHeader
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      )
    }
    }
  />
);

function getAppTitle() {
  let appDetails = JSON.parse(localStorage.getItem('appDetails'))
  return appDetails?.name || 'Login'
}
function _getPoints(){
  let value = getPoints();
  return value;
}

function RouterContainer(props) {
  const { footerStatus, headerStatus,updatePoints  } = props
  document.title = getAppTitle()
  const menuExpandRef = useRef();
  let data = getAllMenuOptions() // TODO: fazer validacao se tem dadaos
  return (
    <div style={{ height: '100%' }}>
      <div className="scroll-support" style={{ backgroundColor: getColors('menu').fourthColor }} />
      {
        showHeader(props.location) && headerStatus === 'true' ? <Header /> : null
      }
      {
        showHeader(props.location) && <MenuExpand ref={menuExpandRef} />
      }
      <TransitionGroup className="transition-group" style={{ height: '100%' }}>
        <CSSTransition
          key={props.location.pathname}
          timeout={200}
          classNames={'fade'}>
          <section className={'route-section'}>
            <Switch location={props.location}>
              <Route path="/login" component={() => <Login />} />
              <Route path="/landing-page" component={() => <LandingPage />} />
              <Route path="/activate-user" component={() => <ActivateAccount />} />
              <Route exact path="/termos" component={Termos} />
              <Route exact path="/registro" component={Registro} />
              <Route exact path="/obrigado" component={Obrigado} />
              <PrivateRoute path="/editar-perfil" component={() => <EditarPerfil />} />
              <PrivateRoute path="/userupload/:id" component={(props) => <UploadUser {...props} />} />
              <PrivateRoute path="/content/:id" component={() => <ContentSelector />} />
              <PrivateRoute path="/ytplayer/:id" component={() => <YTPlayer />} />
              <PrivateRoute path="/wtplayer/:id" component={() => <WTPlayer />} />
              <PrivateRoute path="/liveplayer/:id" component={() => <LivePlayer />} />
              <PrivateRoute path="/cardMenu/:id" component={(props) => <TemplateSelector  {...props} />} />
              <PrivateRoute path="/messages/:id" component={(id) => <MessageFull id={id} />} />
              <PrivateRoute path="/messages" component={() => <Messages />} />
              <PrivateRoute path="/perfil" component={(props) => <Profile  {...props} />} />
              <PrivateRoute path="/lojinha" component={() => <LojinhaHome />} />
              <PrivateRoute path="/detalheslojinha" component={() => <LojinhaDetail />} />
              <PrivateRoute path="/cartlojinha" component={() => <LojinhaCart />} />
              <PrivateRoute path="/uploads/userContent" component={() => <UploadTemplate />} />
              <PrivateRoute path="/chat/:room" component={(props) => <ChatRoom {...props} />} />
              <PrivateRoute path="/PerfilUsuario" component={(props) => <PerfilUsusario />} />
              {
                data.map((item, index) => {
                  if (item.text === "") { return } // If path name is empty, return nothing
                  return (
                    <PrivateRoute key={index} path={`/${transformText(item.text)}/${item.sectionTo}`} component={() => <TemplateSelector />} />
                  )
                })
              }
              <PrivateRoute exact path={["/", ""]} component={() => <TelaPrincipal />} />
              <PrivateRoute path="*" component={() => <NotFound />} />
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
      {
        showFooter(props.location) && footerStatus === 'true' ? (
          <Footer menuExpandRef={menuExpandRef} />
        ) : (
          null
        )
      }
    </div>
  );
}

const mapStateToProps = store => ({
  footerStatus: store.footerState.footerStatus,
  headerStatus: store.footerState.headerStatus
});
const mapDispatchToProps = (dispatch) => ({
  updatePoints: (item) => dispatch(updatePoints(item)),
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RouterContainer);