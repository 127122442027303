
const Style = {
    lojinhaHeaderButton:{
        height: '54px',
        width: '54px',
        backgroundColor: 'transparent',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    lojinhaHeaderButtonCartImage:{

    },
    lojinhaHeaderButtonInnerWrapper:{
        position: 'relative',
    },
    lojinhaHeaderIndicatorOuterWrapper:{
        position: 'absolute',
        left: '-5px',
        bottom: '-5px',
        height: '18px',
        width: '18px',
    },
    lojinhaHeaderIndicatorInnerWrapper:{
        position: 'relative',
        height: '18px',
    },
    redDotImage:{
        position: 'absolute',
        left: '0px',
        top: '0px',
    },
    lojinhaHeaderIndicatorText:{
        position: 'absolute',
        color: '#fff',
        height: '18px',
        width: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
    }
}

export default Style;