import React , { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {getStyle} from './style.js'
import Search from '../../../../assets/images/search-gray.svg';
import Cart from '../../../../assets/images/cart.svg';
import Home from '../../../../assets/images/lojinha-home.svg';
import CartComponent from '../CartComponent';
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';

const HeaderSearch = ({ getData, path, icon, label }) => {
    const history = useHistory();
    const [searchText, setSearchText] = useState('');
    const [timer,setTimer] = useState( ()=>{} );
    
    const {hasDesign, getDesign} = useDesignByIdSectionType(34);
    const Style = getStyle(getDesign);

    const goPath = () => {
        if(path !== ''){
            history.push(path);
        }
    }
    const goHome = () => {
        history.push('/');
    }

    function handlerChangeSearchText(e) {
        const valor = e.target.value;
        setSearchText(valor);
    }

    function activateGetData(text){
        clearTimeout(timer);
        setTimer( setTimeout(() => {
            if(text === searchText){
                getData(searchText);
            }
        }, 1000));
    }

    useEffect(() => {
        activateGetData(searchText);
    }, [searchText])


    return(
        <div style={Style.lojinhaHeaderWrapper}> 
            <button style={Style.lojinhaHeaderButton} onClick={goHome}>
                <img src={Home} alt="home" />
            </button>   
            <div style={Style.lojinhaHeaderSearchWrapper}>
                <label style={Style.lojinhaHeaderSearchLabel}>
                    <input style={Style.lojinhaHeaderSearchInput} placeholder={label} value={searchText} onChange={handlerChangeSearchText} />
                    <span style={Style.lojinhaHeaderSearchSpan}>
                        <img style={Style.lojinhaHeaderSearchSpanImg} src={Search} alt="search"/>
                    </span>
                </label>
            </div>
            <CartComponent />
        </div>
    );
}

HeaderSearch.propTypes = {
    getData: PropTypes.func,
    path: PropTypes.string,
    label: PropTypes.string,
};
  
HeaderSearch.defaultProps = {
    getData: ()=>{},
    path: '',
    icon: Cart,
    label: 'Buscar Produtos',
};

export default HeaderSearch;