import * as React from 'react'
import GearIcon from '../assets/images/gear.svg'
import { getToken, getUserInfo, setUserInfo } from '../services/api'
import { getColors } from '../services/utils'
import jwtDecode from 'jwt-decode'
import { Link } from 'react-router-dom'
import Form from "react-bootstrap/Form";
import { cpf } from 'cpf-cnpj-validator'; 
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputMask from 'react-input-mask';
import { Button ,Modal } from 'react-bootstrap';
import ImageUpload from '../components/ImageUpload';
import { BsCamera } from 'react-icons/bs';
import UserIcon from '../assets/images/no-gender-user.svg';

class EditarPerfil extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            show:false
        }

        this.state = {
            user: {
                "idUser": 1,
                "idCompany": 1,
                "email": "",
                "name": "",
                "phone": "",
                "cpf": "",
                "userType": 1,
                "userLicenceTermsAccepted": 1,
                "communicationAccepted": 1,
                "emailNotification": "",
                "smsNotification": "",
                "gestCompany": "",
                "gestCnpj": "",
                "admin": 0,
                "active": 1,
                "photo": ""
            },
            isLoading: false,
            error: false,
            isLoadingSave: false,
            errorSave: false,
            saveSuccess: false,
            invalidCpf: false,
            invalidPhone: false
        };
    }

    getProfileInfo(){
        let token = {}
        try {
            token = jwtDecode(getToken())
        }catch{
            console.log('error on token get')
        }
        return token
    }

    _getUserInfo(id){
        this.setState({ isLoading: true })
        getUserInfo(id).then(res => {
            if(res.data.success == false){
                this.setState({ isLoading: false, error: true })
                return
            }
            let userData = {
                active: res.data.user.active,
                admin: res.data.user.admin,
                askPWA: res.data.user.askPWA,
                communicationAccepted: res.data.user.communicationAccepted,
                emailNotification: res.data.user.emailNotification,
                smsNotification: res.data.user.smsNotification,
                cpf: res.data.user.cpf,
                department: res.data.user.department,
                email: res.data.user.email,
                guestCnpj: res.data.user.guestCnpj,
                guestCompany: res.data.user.guestCompany,
                name: res.data.user.name,
                phone: res.data.user.phone,
                photo: res.data.user.photo,
                role: res.data.user.role,
                userLicenceTermsAccepted: res.data.user.userLicenceTermsAccepted,
                userType: res.data.user.userType
            }
            this.setState({ user: userData, isLoading: false, error: false })
        }).catch(err => {
            this.setState({ isLoading: false, error: true })
        })
    }

    saveProfile(){
        //check if CPF is valid
        if(cpf.isValid(this.state.user.cpf)){
            this.setState({ invalidCpf: true })
            return
        }

        //check if phone is valid
        const isPhoneValid = /^\([1-9]{2}\) (?:[2-8]|[1-9][1-9])[0-9]{3}\-[0-9]{4}$/.test(this.state.user.phone)
        if (!isPhoneValid) {
            this.setState({ invalidPhone: true })
            return
        }

        this.setState({ isLoadingSave: true, saveSuccess: false, errorSave: false, invalidCpf: false, invalidPhone: false })
        setUserInfo(this.getProfileInfo().idUser, this.state.user).then(res => {
            if(res.data.success == false){
                this.setState({ isLoadingSave: false, errorSave: true, saveSuccess: false })
                return
            }
            this.setState({ isLoadingSave: false, errorSave: false, saveSuccess: true })
        }).catch(err => {
            this.setState({ isLoadingSave: false, errorSave: true, saveSuccess: false })
        })
    }

    componentDidMount(){
        this._getUserInfo(this.getProfileInfo().idUser)
    }

    handleModal()
    {
        this.setState({show:!this.state.show})
    }
      

    render() {
    
        const callbackImage = (url) =>{
            this.setState({user:{...this.state.user,photo:url}})
            this.saveProfile()
        }
        
        return (
            
            <div className="profile-edit-container">
                <div className="profile-top-bar" style={{ paddingTop: 15,  backgroundColor: getColors('menu').fourthColor }}>
                    <div className="conteudo-icon">
                        <BsCamera size={30} className="bs-camera" />
                        <div className="editar-perfil-icon" id="root">
                            <img
                                style={{ color: 'white', width: 75, height: 75 }}
                                src={this.state.user.photo || UserIcon}
                                alt="img"
                            /> 
                            <Button title="Carregar Foto" className="button-carrega-modal-avatar" onClick={()=>{this.handleModal()}} >Teste</Button>
                            <Modal show={this.state.show} onHide={()=>this.handleModal()} >
                                <ImageUpload callbackImage={callbackImage} />
                            </Modal> 
                        </div>
                        <div style={{paddingTop: 20, marginRight: 26, fontSize: 24, marginBottom: 15, fontWeight: 500, color: 'white', textAlign: 'center'}}>Editar Perfil</div>
                    </div>
                    {
                        this.state.isLoading ? 
                        <div className="loader loader-center loader-big"></div>
                        :
                        this.state.error ?
                        <strong style={{ color: 'orangered' }}> Error retrieving user data </strong>
                        :
                        <div className="profile-edit">
                            <div className="profile-edit-item">
                                <span>E-mail</span>
                                <input 
                                    disabled
                                    value={this.state.user.email}
                                    className="form-control edit-login-input button-disabled" 
                                    style={{ marginBottom: 10 }}
                                />
                            </div>
                            <div className="profile-edit-item">
                                <span>Nome</span>
                                <input 
                                    value={this.state.user.name}
                                    className="form-control edit-login-input" 
                                    onChange={(e) => this.setState({ user: {...this.state.user, name: e.target.value } }) } 
                                    style={{ marginBottom: 10 }}
                                />
                            </div>
                            <div className="profile-edit-item">
                                <span>CPF</span>
                                <CpfCnpj
                                    className="form-control edit-login-input"
                                    value={this.state.user.cpf}
                                    placeholder="Digite o CPF"
                                    onChange={(e) => this.setState({ user: {...this.state.user, cpf: e.target.value } }) } 
                                    style={{ marginBottom: 10 }}
                                />
                            </div>
                            <div className="profile-edit-item">
                                <span>Telefone</span>
                                <InputMask
                                    mask="(99) 99999-9999" 
                                    value={this.state.user.phone}
                                    className="form-control edit-login-input" 
                                    onChange={(e) => this.setState({ user: {...this.state.user, phone: e.target.value } }) } 
                                    style={{ marginBottom: 10 }}
                                />
                            </div>
                            <div className="profile-edit-item">
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check
                                    className="espaco-checkbox-principal"
                                    checked={this.state.user.communicationAccepted == 1 ? true : false}
                                    onChange={(e) => this.setState({ user: {...this.state.user, communicationAccepted: e.target.checked ? 1 : 0} }) } type="checkbox" label="Aceito receber notificações." />
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check id="teste1"
                                    className="espaco-checkbox"
                                    checked={this.state.user.emailNotification == 1 ? true : false}
                                    disabled={this.state.user.communicationAccepted == 0 || this.state.user.smsNotification == 1}
                                    onChange={(e) => this.setState({ user: {...this.state.user, emailNotification: e.target.checked ? 1 : 0} })} type="checkbox" label="Notificar por e-mail." />
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check id="teste2"
                                    className="espaco-checkbox td-mg"
                                    checked={this.state.user.smsNotification == 1 ? true : false}
                                    disabled={this.state.user.communicationAccepted == 0 || this.state.user.smsNotification == 0}
                                    onChange={(e) => this.setState({ user: {...this.state.user, smsNotification: e.target.checked ? 1 : 0} })} type="checkbox" label="Notificar por SMS." />
                                </Form.Group>
                            </div>
                            <div className="edit-button-group">
                                <Link to="/perfil" style={{ flex: 1, marginRight: 15 }} className="btn btn-light">Cancelar</Link>
                                <button onClick={() => this.saveProfile()} 
                                style={{ flex: 1, position: 'relative', backgroundColor: '#e9c46a', borderColor: 'gray', color: 'black' }} className="btn btn-success">
                                    <span>Salvar</span> 
                                    { this.state.isLoadingSave && <div className="loader" style={{ top: 8 }} /> } 
                                </button>
                            </div>
                            {
                                this.state.errorSave &&
                                <div>
                                    Erro ao salvar dados, tente novamente mais tarde.
                                </div>
                            }
                            {
                                this.state.invalidCpf &&
                                <div>
                                    CPF invalido, por favor formate corretamente.
                                </div>
                            }
                            {
                                this.state.invalidPhone &&
                                <div>
                                    Telefone invalido, por favor digite corretamente. Exemplo: (99) 99999-9999
                                </div>
                            }
                            {
                                this.state.saveSuccess &&
                                <div style={{ color: 'lightgreen' }}>
                                    Salvo com sucesso!
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default EditarPerfil