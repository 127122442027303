import { getColors } from '../../../../services/utils';

const getStyle = (getDesign) => {
    const Style = {
        lojinhaItemFullHeight: {
            display: 'flex',
            position: 'relative',
            height: '100%',
            backgroundColor: '#fff',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            maxWidth: '1250px',
        },
        lojinhaItemImage:{
            width: '100%',
            height: 'auto',
        },
        lojinhaItemImageImg:{
            width: '100%',
            maxHeight: '415px',
            objectFit: 'contain',
        },
        lojinhaItemName:{
            ...getDesign('itemText'),
            fontWeight: 'bold',
            padding: '15px',
        },
        lojinhaItemDescription:{
            ...getDesign('itemDescription'),
            padding: '0px 15px 15px 15px',
            overflowY: 'auto',
        },
        lojinhaItemPrice:{
            ...getDesign('itemText'),
            color: getColors('menu').secondColor,
            fontWeight: 'bold',
            padding: '5px 15px 65px 15px',
        }
    }

    return Style;
}

export default getStyle