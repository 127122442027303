import React from 'react';
import { Link }  from 'react-router-dom';
import { getDesignById } from '../../services/utils';
import style from './styles.module.scss';
import { Icon } from '@iconify/react';
import playCircle from '@iconify/icons-mdi/play-circle';
import ProgressBar from '../../components/ProgressBar';

const AudioListCard = ({ index, idSection, item }) => {

    function _getDesign(item, only = false, valueOnly = false){
        let design = getDesignById(idSection)
    
        if(!design[item]){return null} // return null if theres no item specified
    
        if(only){ // parameter only means it will only return the property specified
          if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified
    
          design = { [only]:design[item][only] }
          return design
        }
    
        return design[item]
    }

    const colorStyle = _getDesign('itemText','color')
    const fontFamilyStyle = _getDesign('itemText','fontFamily')

    return(
        <Link key={index} to={`/content/${item.idContentItem}?idSection=${idSection}`} className={style.wrapper}>
            <div className={style.imgWrapper}>
                <img className={style.img} src={`${item.image}`} alt="" />
            </div>
            <div className={style.textWrapper}>
                <div className={style.titleText} >
                    <div className={style.title} style={{...fontFamilyStyle, ...colorStyle}}>
                        {item.text}
                    </div>
                    <div className={style.author} style={fontFamilyStyle}>
                        Autor: {item.author}
                    </div>
                </div>
                <div className={style.infoWrapper}>
                    {
                        (item.duration > 0) ? (
                            <ProgressBar percent={item.progress} completed={item.completed} legend={false} />
                        ) : null
                    }
                </div>
            </div>
            <div className={style.iconWrapper}>
                <Icon icon={playCircle} style={{ fontSize: 50, opacity: 1, width: '100%', height: '100%', ...colorStyle }} />
            </div>
        </Link>
    )
}

export default AudioListCard