import React, { createRef } from 'react';
import YouTube from 'react-youtube';
import '../assets/css/YTPlaylist.css';
import { withRouter } from 'react-router-dom'
import { getCacheData } from '../services/api'
import {Rating} from '../components/Rating';
import { getDesignById, getColors } from '../services/utils'
import {compose } from 'redux';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';
import moment from "moment";
import IdleTimer from 'react-idle-timer/dist/modern'
import { recordTracking } from '../services/api'
moment.locale('pt-br');
class YTPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.playerRef = createRef(null);
    this.state = {
      isDesktop: false,
      isLoading:false,
      data: [],
      timeRefresh : 60000,
      timeStart: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      idContentItem: 0,
      idContentType: 0,
      ignoreRecordTracking: false,
      youTubeInfo:{
        isPlaying: false, 
        currentTime:0,
        totalTime:0
      }

    };
    this.resize = this.resize.bind(this);
  }

  handleOnActive (event) {
      this.setState({timeStart: moment().utc().format('YYYY-MM-DD HH:mm:ss')})
      this.setState({ignoreRecordTracking: false})
  }

  handleOnIdle (event) {
      if(!this.state.youTubeInfo.isPlaying ){
        this._recordTracking();
        this.setState({ignoreRecordTracking: true})
      }
  }
  splitContentLocantion(contentLocation) {
    let res = contentLocation.split("=");
    return res[1]
  }

  resize() {
    this.setState({ isDesktop: window.innerWidth > 800 });
  }

  getData() {
    const url = window.location.href;
    const video = []
    getCacheData('YTPlaylist').map((item, index) => {
      if (item.snippet.resourceId.videoId === url.split("ytplayer/")[1]) {
        video.push(item)
      }
    })


    return video[0]
  }
 
  _getContent(){
    this.setState({ isLoading: true })
    let pathParams =  new URLSearchParams(this.props.location.search)
    let idSection = pathParams.get("idSection")
    let contentItem = pathParams.get("content")
    this.setState({idContentItem: contentItem, idContentType: idSection,  isLoading: false })
  }
  setLocalStorageCurrentTime(currentTime){
    localStorage.setItem('currentTimeYouTubeList', JSON.stringify({
      currentTime : parseInt(currentTime),
      contentItem: this.state.idContentItem,
      idVideo: this.props.match.params.id
    }))
  }
  async _recordTracking(){
 
    //const ignoreRecordByIdContentType = [1,2];
   // if( !ignoreRecordByIdContentType.includes(this.state.idContentType)){
        recordTracking(this.state.timeStart, this.state.idContentItem, 1, this.state.idContentType).then(res => {
            }).catch(err => {
                this.setState({ isLoading: false })
            })
      //adiciona tempo de execução do video no local storage
      let currentTime =  await this.playerRef.current?.internalPlayer.getCurrentTime();
      let storage  = localStorage.getItem('currentTimeYouTubeList') ;
      const objCurrentTime  = storage ? JSON.parse(storage) : false
      if(objCurrentTime && objCurrentTime.idSection === this.state.idContentType){
        currentTime = this.props.match.params.id === objCurrentTime.idVideo ? 
        currentTime :  currentTime  + objCurrentTime.currentTime;
      }
      this.setLocalStorageCurrentTime(currentTime)

   // }
}
  componentDidMount() {
    this.resize();
    window.addEventListener("resize", this.resize);
    this._getContent()
    const { updateHeaderStatus, updateFooterStatus } = this.props
    updateFooterStatus(this._getDesign('general')?.showBottomMenu || 'true')
    updateHeaderStatus(this._getDesign('general')?.showTopBar || 'true')
   
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.resize);

    if(!this.state.ignoreRecordTracking){
      this._recordTracking()
    }
  }

  customGetFooterSize(){
    const { footerStatus } = this.props
    if(footerStatus === 'true'){
        return { minHeight: 'calc(100vh - 54px - 5rem' }
    }else{
        return {}
    }
  }

  _getDesign(item, only = false, valueOnly = false){
    let pathParams =  new URLSearchParams(this.props.location.search) // search for sectionId on path
    let parentSection = pathParams.get("idSection")
    let design = getDesignById(parentSection || 0)
  
      if(!design[item]){
        return { 
          showBottomMenu: getColors('footer').showfooter,
          showTopBar: 'true' 
        } 
      }
  
      if(only){ // parameter only means it will only return the property specified
        if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified
  
        design = { [only]:design[item][only] }
        return design
      }
  
      return design[item]
    }
  updateYouTubeInfo(event, isPlaying){
  
    this.setState({youTubeInfo: {
      isPlaying: isPlaying,
      currentTime:event.target.getCurrentTime(),
      totalTime:event.target.getDuration()
    }})
  }
  render() {
    const getVideo = this.getData()
    const { isDesktop } = this.state

    const opts = {
      height: isDesktop ? 500 : 320,
      width: '100%',
      playerVars: {
        autoplay: 1,
      }
    }

    return (
      <>
        <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              timeout={this.state.timeRefresh}
              onActive={this.handleOnActive}
              onIdle={this.handleOnIdle}
              debounce={250}
          />
           {this.state.isLoading ? (
            <div className="loader loader-center loader-big loader-purple"></div>
          ) : (
            <div className="container-fluid live-container" style={{ backgroundColor: '#FFF', ...this.customGetFooterSize() }}>
              {/* <Rating stars={item.rating} numberVotes={item.numberVotes} /> */}
              <div
                className="ytplayer-container"
                style={{
                  paddingLeft: isDesktop ? '20%' : 2,
                  paddingRight: isDesktop ? '20%' : 2,
                  paddingTop: isDesktop ? '3%' : 10,
                  paddingBottom: isDesktop ? '5%' : 10,
                  marginBottom: 25
                }}
              >
                <div className="ytplayer-title">
                  {getVideo != undefined && 
                    <>
                      <h2>getVideo.snippet.title</h2>
                      <hr className="ytplayer-barra" />
                      <br/>
                    </>
                  }
                </div>
                <div>
                  <YouTube 
                    ref={this.playerRef} 
                    videoId={this.props.match.params.id} 
                    opts={opts} onPause={(event) => this.updateYouTubeInfo(event, false)} 
                    onPlay={(event) =>  this.updateYouTubeInfo(event, true)} 
                  />
                </div>
              </div>
              <div style={{ position: 'absolute', right: 10, bottom: 0 }}>
                {/* <Rating /> */}
              </div>
            </div>
          )}
        
      </>
    )
  }

}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

const mapStateToProps = store => ({
    footerStatus: store.footerState.footerStatus
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(YTPlayer);