// Basic
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// UI and icons
import { Button, Form, Card, Image, Row, Col } from "react-bootstrap";
import MaskInput from 'react-maskinput';
import styles from "./styles.module.scss";
import { FaArrowLeft, FaSpinner } from 'react-icons/fa';
import BreadCrumbPhase0 from '../../assets/images/breadcrumb00.png';
import BreadCrumbPhase1 from '../../assets/images/breadcrumb01.png';

// API
import { appDetails as getAppDetails } from "../../services/api";
import axios from "axios";
const api_url = process.env.REACT_APP_ENVIRONMENT;

export default function ActivateAccount() {
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) || {};
  const [fone, setFone] = useState("");
  const [pin, setPin] = useState("");
  const [pinValido, setPinValido] = useState("");
  const [habilitaTela2, setHabilitaTela2] = useState("");
  const [erro, setErro] = useState("");
  const [mensagem, setMensagem] = useState("Erro ao validar PIN.");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function validateForm1() {
    return fone.length > 0 ;
  }

  function validateForm2() {
    return pin.length > 0 ;
  }

  async function handleSubmit1(event) {
    event.preventDefault();

    try {
      if (validateForm1()) {
        setLoading(true);

        axios
          .post(`${api_url}/contract/sendpin`, {
              phone: fone.replace(/\D/g, ""),
              idAppType: "1"
          }, { "Content-Type": "application/json"}
          )
          .then(({data}) => {
              if (data.success) {
                setHabilitaTela2(true);
                setPinValido(data.pin);
              } else {
                setErro(true);
                setMensagem(data.message);
              }
          })
          .catch((e) => {
              console.log("Erro ao enviar o numero do telefone")
          })
          .finally(() => {
            setLoading(false);
          })
      }
    } catch (e) {
      alert(e.message);
    }
  }

  async function handleSubmit2(event) {
    event.preventDefault();

    try {
      if (validateForm2()) {
        setLoading(true);

        axios.post(`${api_url}/contract/checkpin`, {
            phone: fone.replace(/\D/g, ""),
            pin: ""+pin+""
          }, { "Content-Type": "application/json"})
          .then(({data}) => {
            if (data.success) {
              localStorage.setItem("fone", fone)
              localStorage.setItem("pin", pin)
              history.push("/registro");
            } else {
              setErro(true);
              setMensagem(data.message);
            }
          })
          .catch((e) => {
              console.log("Erro ao enviar o numero do telefone")
          })
          .finally(() => {
            setLoading(false);
          })
      }

    } catch (e) {
      alert(e.message);
    }
  }

  const _appDetails = async () =>  {
    if (Object.keys(appDetails).length === 0) {
      const bucketUrl = window.location.host;
      const staticUrl = "homolog.blast.etc.br";
      // smartapp.digital
      // homolog.bedigital.net.br
      // kryptosapp.com
      const currentUrl =
      process.env.NODE_ENV === "development" ? staticUrl : bucketUrl;
      try {
        const result = await getAppDetails(currentUrl);
        if (result.data.app.length == 0) {
          return;
        }
        localStorage.setItem("appDetails", JSON.stringify(result.data.app[0]));
        document.title = result.data.app[0].name;
      } catch (e) {
        console.log('error', e)
      }
    }
  }

  useEffect(() => {
    _appDetails()
  }, [])

  return (
    <div className={styles.activeAccountPage}>
      <Row className={styles.rowCentered}>
        <Col className="activate-account-col" lg="6" md="6" xs="12">
          <Card className="activate-account-card">
            <Card.Body className="activate-account-body" style={{ backgroundColor: 'white' }}>
              { erro && <Card className="activate-account-warning-card" bg='warning' text='dark'> {mensagem}</Card> }

              {
                habilitaTela2
                ? (
                <Form onSubmit={handleSubmit2}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Text className={styles.titleText}>
                      Ativação de Usuário
                    </Form.Text>
                    <center><Image src={BreadCrumbPhase1} className={styles.imageStyle} fluid /></center>
                    <Form.Text className={styles.normalText}>
                      Digite o PIN recebido pelo seu celular, com este PIN seu cadastro estará apto a ser criado.
                    </Form.Text><br></br>
                    <Form.Group className={styles.infoBox}>
                      <Form.Label>PIN</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o número do PIN"
                        value={pin}
                        onChange={e => setPin(e.target.value)}
                        disabled={loading}
                        />
                      <Form.Text className="text-muted">
                          Digite o PIN enviado por SMS para o seu celular.
                      </Form.Text>
                    </Form.Group>
                  </Form.Group>

                  <div className={styles.boxActions}>
                    <div style={{ cursor: 'pointer' }} onClick={() => setHabilitaTela2(false)}><FaArrowLeft /> Voltar</div>
                    <Button style={{ background: '#861414', borderColor: '#861414' }} disabled={!validateForm2() || loading}  type="submit">
                      {loading && <FaSpinner color="#FFF" size={16} className={styles.faSpinner} />} Validar PIN
                    </Button>
                  </div>
                </Form>
                )
                : (
                  <Form onSubmit={handleSubmit1}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Text className={styles.titleText}>
                          Ativação de Usuário
                      </Form.Text>
                      <center><Image src={BreadCrumbPhase0} className={styles.imageStyle} fluid /></center>
                      <Form.Text className={styles.normalText}>
                          Para criar seu usuário informe o numero de telefone celular que foi utilizado para aquisição do BeDigital. Seu cadastro será consultado e se encontrado, será enviado um SMS com um PIN para ser digitado no próximo passo.
                      </Form.Text><br></br>
                      <Form.Group className={styles.infoBox}>
                        <Form.Label>Telefone</Form.Label>
                        <br />
                        <MaskInput
                          disabled={loading}
                          alwaysShowMask
                          mask={'(00) 00000-0000'}
                          size={20}
                          showMask
                          maskChar="_"
                          placeholder="Digite o número do seu celular"
                          value={fone}
                          onChange={e => setFone(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                            Use o telefone que consta no seu cadastro.
                        </Form.Text>
                      </Form.Group>
                    </Form.Group>

                    <div className={styles.boxActions}>
                      <div/>
                      <Button style={{ background: '#861414', borderColor: '#861414' }} disabled={!validateForm1() || loading}  type="submit">
                        {loading && <FaSpinner color="#FFF" size={16} className={styles.faSpinner} />} Enviar
                      </Button>
                    </div>
                  </Form>
                )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
