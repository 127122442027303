import React , { useState, useEffect } from 'react';
import Style from './style.js'
import Cart from '../../../../assets/images/cart.svg';
import { useHistory } from 'react-router-dom';
import { getCartItensLojinha } from '../../../../services/api.js';
import RedDot from '../../../../assets/images/red-dot.svg';
import PropTypes from 'prop-types';

const CartComponent = ( {quantidadeItensCarrinho} ) => {

    const detalhesIniciais = {
        idShoppingCart: '', 
        idUser: '', 
        totalPrice: '', 
        numItens: 0
    }
    
    const history = useHistory();
    const [cartDetail, setCartDetails] = useState(detalhesIniciais);
    const [useQuantidadeItensCarrinho, setUseQuantidadeItensCarrinho] = useState(false);
    const number = useQuantidadeItensCarrinho ? quantidadeItensCarrinho : cartDetail.numItens;
    const hasProduct = Boolean(number);
    
    const goPath = () => {
        history.push('/cartlojinha');
    }

    function loadCartData(){
        getCartItensLojinha().then( result => {
            if(result && result.hasOwnProperty('status') && result.status === 200 && result.data.success){
                setCartDetails(
                    result.data.shoppingCart,
                );
            }
        });
    }

    useEffect( () => {
        loadCartData()
    },[]);
    
   useEffect( () => {
        if(quantidadeItensCarrinho > 0){ setUseQuantidadeItensCarrinho(true) }
    }, [quantidadeItensCarrinho]);

    return (
        <button style={Style.lojinhaHeaderButton} onClick={goPath}>
            <div style={Style.lojinhaHeaderButtonInnerWrapper}>
                <img src={Cart} alt="cart" style={Style.lojinhaHeaderButtonCartImage}/>
                { hasProduct && (
                    <div style={Style.lojinhaHeaderIndicatorOuterWrapper}>
                        <div style={Style.lojinhaHeaderIndicatorInnerWrapper}>
                            <img style={Style.redDotImage} src={RedDot} alt="cart"/>
                            <div style={Style.lojinhaHeaderIndicatorText}>
                                {number}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </button>
    );
}

CartComponent.propTypes = {
    quantidadeItensCarrinho: PropTypes.number,
};
  
CartComponent.defaultProps = {
    quantidadeItensCarrinho: 0
};


export default CartComponent