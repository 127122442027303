
import { getColors } from '../../../../services/utils';

const getStyle = (getDesign) => {
    const Style = {
        lojinhaHeaderWrapper:{
            backgroundColor:getColors('menu').firstColor,
            position: 'fixed',
            height: '54px',
            width: '100%',
            display: 'flex',
            top: 0,
            zIndex: 1500,
        },
        lojinhaHeaderButton:{
            height: '54px',
            width: '54px',
            backgroundColor: 'transparent',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        lojinhaHeaderSearchWrapper:{
            ...getDesign('itemText'),
            color: '#fff',
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
        },
    } 
    return Style;
}

export default getStyle;