import React from 'react'
import PostListCard from '../PostListCard'
import HighlightedPostListCard from '../HighlightedPostListCard'
import Loader from '../Loader'
import { getDesign, getFooterSize } from '../../services/utils'


const ListCard = ({ itens, highlight, isLoading, section }) => {

    const highLightedItens = itens?.filter((item, index) => { return index < highlight})
    const usualItens = itens.filter((item, index) => { return index >= highlight})
    const highlightedStyle = { justifyContent: 'space-around' ,...getDesign(section, 'general'), ...getFooterSize(), padding: '0px', minHeight: 'min-content' }
    const usualStyle =       { justifyContent: 'space-around' ,...getDesign(section, 'general'), ...getFooterSize(), minHeight: 'min-content' }

    return(
        <div>
          {
            isLoading ?
            <Loader />
            :
            <div>
                <div className="post-list-container" style={ highlightedStyle }>
                {
                    highLightedItens.map((item, index) => {
                        return (<HighlightedPostListCard key={item.idContentItem} idSection={section} item={item} index={index} quantity={highlight}/>)
                    })
                }
                </div>
                <div className="post-list-container" style={ usualStyle }>
                    {
                        usualItens.map((item, index) => {
                            return (<PostListCard idSection={section} key={item.idContentItem} item={item} index={index}/>) 
                        })
                    }
                </div>
            </div>
          }
        </div>
      )

}

export default ListCard