import { getColors } from '../../../../services/utils';

const getStyle = (getDesign) => {
    const Style = {
        cartListItemWrapper:{
            maxHeight: '85px',
            backgroundColor: '#fff',
            display: 'flex',
            width: '100%',
            marginTop: '5px',
            maxWidth: '1059px',
        },
        cartListItemImg:{
            maxHeight: '85px',
        },
        cartListItemData:{
            width: '100%',
            position: 'relative',
        },
        cartListItemDataText:{
            ...getDesign('itemText'),
            height: '50%',
            paddingLeft: '10px',
            fontWeight: '700',
            display: 'flex',
            alignItems: 'center',
        },
        cartListItemDataPrice:{
            ...getDesign('itemText'), 
            color:getColors('menu').firstColor,
            height: '50%',
            paddingLeft: '10px',
            fontWeight: '700',
            textAlign: 'left',
        },
        cartListItemDataButton:{
            padding: '0',
            display: 'flex',
            position: 'absolute',
            right: '10px',
            bottom: '10px',
            backgroundColor: 'transparent',
            border: 'none',
        },
    }

    return Style;
}

export default getStyle;