import React, { Component } from "react";
import "../index.css";
import { withRouter, Redirect, NavLink, Link } from "react-router-dom";
import BackLogo from "../assets/images/back-button.png";
import { getMenuData, logout } from "../services/api";
import {
  transformText,
  getAllMenuOptions,
  getDesignById,
  getSectionByType,
  getColors,
  getPathBySectionId,
  getGamigication,
} from "../services/utils";
import MenuPrincipal from "./MenuPrincipal";
import Hamburguer from "../assets/images/hamburguer.svg";
import HomeIcon from "../assets/images/home.svg";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";
import LogoutIcon from "../assets/images/logout.svg";
import { PointsHeader } from "./Points";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      expanded: false,
      searchExpanded: false,
      onFocus: false,
    };
  }

  componentDidMount() {
    this.setStatusBarColor();
  }

  expand() {
    this.setState({ expanded: !this.state.expanded });
  }

  getImageOrientation() {
    const headerInfo = getColors("header");
    return headerInfo?.iconOrientation || "horizontal";
  }

  getShowBack() {
    if (
      getDesignById(this._getSectionToByName()) &&
      Object.keys(getDesignById(this._getSectionToByName())).length > 0
    ) {
      return getDesignById(this._getSectionToByName());
    } else {
      return { header: { showBack: "true" } };
    }
  }

  getMenuOptions() {
    const menuOptions = this._getMenuData()?.menuOption;

    return menuOptions
      ? menuOptions.map((obj) => `/${transformText(obj.text)}/${obj.sectionTo}`)
      : [];
  }
  showBackOption(showBack) {
    // Define quais rotas NAO deve mostrar o botao de voltar
    const showOnRoutes = ["/"];
    const showRotesInclude = showOnRoutes.includes(
      this.props.location.pathname
    );

    // Define quais rotas devem mostrar o botao home
    const showHomeOnRoutes = this.getMenuOptions();
    const showHomeRotesInclude = showHomeOnRoutes.includes(
      this.props.location.pathname
    );

    if (showHomeRotesInclude) {
      return (
        <NavLink to="/" exact>
          <ReactSVG
            beforeInjection={(svg) => {
              svg.setAttribute(
                "style",
                `width: 25px; margin-bottom: 5px; fill: ${
                  getColors("menuItem")?.color || "white"
                }`
              );
            }}
            src={HomeIcon}
            alt="home"
          />
        </NavLink>
      );
    }

    // if (!showOnRoutes.includes(this.props.location.pathname) ) {
    if (
      !showRotesInclude &&
      showBack &&
      (showBack === "true" || showBack === "1")
    ) {
      // check if current content type is 4 (iframe)
      if (this._shouldGetSectionBack()) {
        return (
          <Link replace to={this._getSectionPath()}>
            <div>
              <img
                src={BackLogo}
                style={{
                  height: 35,
                  width: 20,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                alt="img"
              />
            </div>
          </Link>
        );
      } else {
        return (
          <div>
            <div
              onClick={() => {
                this.props.history.goBack();
              }}>
              <img
                src={BackLogo}
                style={{
                  height: 35,
                  width: 20,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                alt="img"
              />
            </div>
          </div>
        );
      }
    }
    return (
      <img
        src={this.getAppIcon()}
        alt="Nexi Blast"
        title="Nexi Blast"
        width={this.getImageOrientation() == "horizontal" ? 66.66 : 30}
        height={this.getImageOrientation() == "horizontal" ? 20 : 30}
        className="blast-topbar-img"
      />
    );
  }

  _getSectionToByName() {
    if (this.props.location.pathname == "/") {
      return 1;
    }

    // gets the current path name
    let pathName = this.props.location.pathname.replace("/", "");

    // gets all menu options stored on cache
    let allMenuOptions = getAllMenuOptions();
    // gets the menu object according to the path name
    let menuItem = allMenuOptions.filter((obj) => {
      return transformText(obj.text) + "/" + obj.sectionTo === pathName;
    })[0];
    if (!menuItem) {
      return null;
    }
    return menuItem.sectionTo;
  }

  _shouldGetSectionBack() {
    if (this.props?.currentContentType === 4 && this._getSectionPath()) {
      return true;
    } else {
      return false;
    }
  }

  _getSectionPath() {
    let pathParams = new URLSearchParams(this.props.location.search); // search for sectionId on path
    let idSection = pathParams.get("idSection");
    let path;

    if (window.location.hash.includes("/content/")) {
      path = getPathBySectionId(idSection);
    } else {
      const allMenu = getAllMenuOptions();
      const parentIdSection = allMenu.find(
        (e) => e.sectionTo === parseInt(idSection)
      )?.parentIdSection;
      path = getPathBySectionId(parentIdSection);
    }
    return path;
  }

  _getTitleDesign() {
    let pathParams = new URLSearchParams(this.props.location.search); // search for sectionId on path
    let parentSection = pathParams.get("idSection");

    let design = getDesignById(this._getSectionToByName() || parentSection);

    if (!design) {
      return null;
    } // return null if theres no item specified

    return design.title;
  }

  getHeaderColorBySection() {
    const pathParams = new URLSearchParams(this.props.location.search); // search for sectionId on path
    const parentSection = pathParams.get("idSection");

    const format = getDesignById(this._getSectionToByName() || parentSection);
    if (!format) return null; // return null if theres no item specified

    const { header } = format;
    if (!header) return null;

    const { color } = header;
    if (!color) return null;
    return color;
  }

  hasTitle() {
    if (!this._getTitleDesign()) {
      return false;
    }
    if (this._getTitleDesign().showTitle == "false") {
      return false;
    }
    if (this._getTitleDesign().showTitle == "true") {
      return true;
    }
    return false;
  }

  getAppIcon() {
    const appDetails = JSON.parse(localStorage.getItem("appDetails"));
    const menuHeaderIcon = getColors("menu").headerIcon
    return menuHeaderIcon || appDetails?.headerIcon;
  }

  logout() {
    logout();
    this.setState({ redirect: true });
  }

  _getMenuData() {
    return getSectionByType(1);
  }

  setStatusBarColor() {
    const color = getColors("statusBar").color;
    const metaStatusBarAndroid = document.querySelector(
      "meta[name=theme-color]"
    );
    const metaStatusBarIos = document.querySelector(
      "meta[name=apple-mobile-web-app-status-bar-style]"
    );

    if (metaStatusBarAndroid) {
      metaStatusBarAndroid.setAttribute("content", color);
      metaStatusBarIos.setAttribute("content", color);
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/login" />;
    }

    const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;
    const showBack = this.getShowBack()?.header?.showBack;
    let menuData = this._getMenuData();
    return (
      <div style={{ height: 54, zIndex: 100 }}>
        <div
          className="navbar fixed-top navbar-expand-md navbar-dark blast-topbar"
          style={{
            backgroundColor:
              this.getHeaderColorBySection() || getColors("header").color || getColors("menu").firstColor,
          }}>
          {this.showBackOption(showBack)}
          {this.hasTitle() && (
            <div className="header-title" style={this._getTitleDesign()}>
              <span> {this._getTitleDesign().text.toUpperCase()} </span>
            </div>
          )}
          <PointsHeader
            action={() => this.props.history.push(`/perfil/`)}
            backgroundColor={`${
              getColors("menu")?.firstColor.trim() || "white"
            }`}
          />
          {appDetails?.hideMenu === 1 ? (
            <div className="logout-button" onClick={() => this.logout()}>
              <ReactSVG
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "style",
                    `width: 24px; height: 24px; margin-bottom: 5px; fill: ${
                      getColors("menuItem")?.color || "white"
                    }`
                  );
                }}
                src={LogoutIcon}
                alt="img"
              />
            </div>
          ) : (
            <div
              onClick={() => this.setState({ expanded: !this.state.expanded })}
              className="logout-button">
              <ReactSVG
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "style",
                    `width: 24px; height: 24px; margin-bottom: 5px; fill: ${
                      getColors("menuItem")?.color || "white"
                    }`
                  );
                }}
                src={Hamburguer}
                style={{ height: 24, width: 24, marginBottom: 5 }}
                alt="img"
              />
            </div>
          )}
          <MenuPrincipal
            expanded={this.state.expanded}
            expand={() => this.setState({ expanded: !this.state.expanded })}
            menuData={menuData.menuOption}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  currentContentType: store.contentState.currentContentType,
});

const withRouterAndRef = (Wrapped) => {
  const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
    <Wrapped ref={forwardRef} {...otherProps} />
  ));
  const WithRouterAndRef = React.forwardRef((props, ref) => (
    <WithRouter {...props} forwardRef={ref} />
  ));
  const name = Wrapped.displayName || Wrapped.name;
  WithRouterAndRef.displayName = `withRouterAndRef(${name})`;
  return WithRouterAndRef;
};

export default withRouterAndRef(connect(mapStateToProps, null)(Header));
