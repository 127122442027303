import React from 'react';
import getStyle from './style.js';
import useDesignByIdSectionType from '../../../../hooks/useDesignByIdSectionType';

const PaymentSelector = ({ children }) => {

    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign);

    return (
        <div style={Style.lojinhaPaymentWays} >
            <span style={Style.lojinhaPaymentWaysSpan}>
                Forma de Pagamento
            </span>
            <div style={Style.lojinhaPaymentWaysList} >
                { children }
            </div>
        </div>
    )
}

export default PaymentSelector;