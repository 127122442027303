import React, { useEffect, useState } from 'react';
import usePWA from 'react-pwa-install-prompt';
import ModalWarning from './ModalWarning';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getToken, setUserField } from '../services/api'
import jwtDecode from 'jwt-decode'
import PwaImage from '../assets/images/pwa-icon.png'
import CloseIcon from '@material-ui/icons/Close';

const AskPWA = () => {
    const { isInstallPromptSupported, promptInstall } = usePWA()
    const [safariShow, setSafariShow] = useState(true)

    function updateLocalAskPWA(){
        let menuData = JSON.parse(localStorage.getItem('menu-data'))
        menuData.askPWA = 0

        localStorage.setItem('menu-item', JSON.stringify(menuData))
    }

    function isStandalone(){
        return window.matchMedia('(display-mode: standalone)').matches
    }

    const handleSafariClose = () => {
        saveProfile()
        updateLocalAskPWA()
        setSafariShow(false)
    }

    function detectMobile() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
    
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    const showPWA = () => {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

        if(isInstallPromptSupported && detectMobile() && !isStandalone()){
            return (
                <ModalWarning
                    acceptText="Instalar aplicativo PWA"
                    denyText="Continuar no browser"
                    title={'Deseja instalar este aplicativo em sua tela inicial para uma melhor experiência?'}
                    acceptAction={() => onClickInstall()}
                    denyAction={() => saveProfile()}
                    icon={
                        <GetAppIcon 
                            style={{ color: 'white', height: 75, width: 75, opacity: 0.8 }} 
                        />
                    }
                />
            )
        } else if(iOSSafari && !isStandalone()) {
            return (
                <div className="pwa-safari-indicator" onClick={() => handleSafariClose() }>
                    <span>Instale este aplicativo no seu iPhone, clique no botão</span>
                    <img src={PwaImage} style={{ marginLeft: 10, marginRight: 10, height: 25 }} alt="img" />
                    <span>e depois adicionar a Tela Inicial.</span>
                    <div className="pwa-safari-close">
                        <CloseIcon style={{ fontSize: 15, color: 'gray' }} />
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    const onClickInstall = async () => {
        updateLocalAskPWA()
        saveProfile()
        promptInstall()
    }

    const getProfileInfo = () => {
        let token = {}
        try {
            token = jwtDecode(getToken())
        }catch{
            console.log('error on token get')
        }
        return token
    }

    const saveProfile = () => {
        const id = getProfileInfo()?.idUser
        const data = {
            field: "askPWA",
            value: 0
        }
        setUserField(id, data)
    }

    const _setPwaAppIcons = () => {
        const data = JSON.parse(localStorage.getItem('appDetails'))
        let dynamicManifest = {
            "name": data.name,
            "short_name": data.name,
            "description": data.name,
            "start_url": window.location.href,
            "theme_color": "#000000",
            "background_color": "#ffffff",
            "display": "standalone",
            "prefer_related_applications": false,
            "icons": [
                {
                    "src": data.logo,
                    "sizes": "192x192",
                    "type": "image/png"
                },
                {
                    "src": data.logo,
                    "sizes": "256x256",
                    "type": "image/png"
                },
                {
                    "src": data.logo,
                    "sizes": "512x512",
                    "type": "image/png"
                },
            ]
          }
        const stringManifest = JSON.stringify(dynamicManifest);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
    }
    
    useEffect(() => {
        _setPwaAppIcons()
    }, [])

    return (
        safariShow && showPWA()
    )
}

export default AskPWA