import { getDesignBySectionType } from '../../../services/utils';

const useDesignByIdSectionType = (typeSection) => {

    let design = getDesignBySectionType(typeSection);
    const hasDesign = Boolean(design);

    const getDesign = (item, only = false, valueOnly = false) => {
    
        if(!design){ return null}
        if(!design[item]){ return null} // return null if theres no item specified
    
        if(only){ // parameter only means it will only return the property specified
            if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified
        
            const desiredDesign = { [only]:design[item][only] }
            return desiredDesign
        }
    
        return design[item];
    }

    return {
        hasDesign,
        getDesign,
    }

}

export default useDesignByIdSectionType;