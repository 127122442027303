import React from 'react';
import getStyle from './style.js';
import ProgressBar from '../../Components/ProgressBar';
import CartList from '../../Components/CartList';
import CartTotal from '../../Components/CartTotal';
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';



const ShowCartItens = ({stage, itens, removeClickHandler, redirectClickHandler, data, nextCallback}) => {

    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign);

    const hasItens = Boolean(itens.length);
    return (
        <>
            <ProgressBar stage={stage} />
            <div style={Style.lojinhaCartDynamicInfo}>
                { hasItens ? 
                (    <CartList itens={itens} 
                        removeClickHandler={removeClickHandler} 
                        redirectClickHandler={redirectClickHandler}
                        enableRemove={true}
                    />) : 
                (
                    <div style={Style.carrinhoVazio}>
                        Seu carrinho está vazio
                    </div>
                )}
            </div>
            <CartTotal data={data} nextCallback={nextCallback}/>
        </>
    )
}

export default ShowCartItens;