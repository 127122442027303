import React from "react";
import { getDesignById, getFooterSize, getAllMenuOptions } from '../services/utils'
import { getToken, getAssessment } from '../services/api'
import jwtDecode from 'jwt-decode'
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';
import LoaderWhite from "../components/LoaderWhite";

class AssessmentRedirect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           url:'',
           error: false,
        };
    }

    _getDesign(item, only = false, valueOnly = false){
        let design = getDesignById(this.props.idSection)
      
        if(!design[item]){return null} // return null if theres no item specified
      
        if(only){ // parameter only means it will only return the property specified
          if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified
      
          design = { [only]:design[item][only] }
          return design
        }
      
        return design[item]
      }

    getProfileInfo(){
      let token = {}
      try {
          token = jwtDecode(getToken())
      }catch{
          console.log('error on token get')
      }
      return token
    }

    _getAssessmentUrl(){
      const menuData = getAllMenuOptions()
      const assessmentMenu = menuData.find(e => e.sectionTo === this.props.idSection)

      return assessmentMenu?.url
    }

    _getAssessment(email){
      this.setState({ isLoading: true })
      let data = {
        email: email
      }
      const url = this._getAssessmentUrl()
      getAssessment(url, data).then(res => {
          if(res.data.success === false){
              this.setState({ isLoading: false, error: true })
              return
          }
          this.setState({ url: res.data.url, isLoading: false, error: false })
      }).catch(err => {
          this.setState({ isLoading: false, error: true })
      })
    }
    
    componentDidMount(){
      this._getAssessment(this.getProfileInfo().email)
      const { updateHeaderStatus, updateFooterStatus } = this.props
      updateFooterStatus(this._getDesign('general')?.showBottomMenu || 'true')
      updateHeaderStatus(this._getDesign('general')?.showTopBar || 'true')
    }

    render(){
        let vh = window.innerHeight * 0.01;
        return(
          <div style={{...this._getDesign('general'), ...{ height:`calc(${vh * 100}px - 54px)`}}}>
            {
              this.state.error ? (
                <div style={{ paddingTop: '45vh', textAlign: 'center', color: 'lightgray' }}>
                  Erro ao carregar conteúdo
                </div>
              ) : (
                <iframe
                   title="Assessment"
                   src={this.state.url} 
                   style={{ height:'100%', ...getFooterSize()}}
                   width="100%"
                   frameBorder="0"
                 />
              )
            }
             {
              this.state.isLoading &&
              <div style={{ position: 'absolute', top: 0, width: '100%' }} >
                <LoaderWhite/>
              </div>
             }
          </div>
       )
    }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(AssessmentRedirect);