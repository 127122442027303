import React from 'react';
import getStyle from './style.js';
import useDesignByIdSectionType from '../../../../hooks/useDesignByIdSectionType';

const Icon = ({ img, text, clickHandler, blur}) => {

    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign, blur);

    return (
        <button style={Style.lojinhaPaymentWaysItem} 
            onClick={clickHandler}
        >
            {img}
            <div style={Style.lojinhaPaymentWaysItemText}>
                {text}
            </div>
        </button>
    )
}

export default Icon