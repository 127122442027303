import React,{useState,useEffect} from 'react';
import { ReactSVG } from 'react-svg'
import Cup from '../../assets/images/championsCup.svg';
import './index.scss'
import FlipNumbers from 'react-flip-numbers';
import { connect } from 'react-redux';
import { updatePoints, updateScorePoints} from '../../actions';
import { getGamigication} from '../../services/utils';

const  _PointsHeader = ({currentPoints, scorePoints, updatePoints, updateScorePoints,   action={}, backgroundColor = '#FFF'}) => {
    const [points, setPoints] = useState(currentPoints)
    const [isGamification, setGamification] = useState()
    const [animationPoints, setPointsAnimations] = useState(currentPoints);
    const [pulse, setPulse] = useState(false);
    
    useEffect(() => {
        setGamification(getGamigication)
    },[])
   
    useEffect(()=>{
        if(scorePoints){
            for( let i = points;i<=points+scorePoints;i++){
                setTimeout(() => {
                    setPointsAnimations(i)
                    if(i=== points+scorePoints){
                        setPulse(true)
                        setTimeout(() => {
                            setPulse(false);
                        }, 1500)
                   }
                }, 500)               
            }
            setPoints(points+scorePoints)
            updatePoints(points+scorePoints)
            updateScorePoints(0)
        }   
    },[scorePoints])
    useEffect(()=>{
        updateScorePoints(0)
    },[updateScorePoints])
    
   
    return(
        <>
            {isGamification ? 
                (
                    <div className="pointing" onClick={action} style={{boxShadow: `inset 4px 4px 16px 14px ${backgroundColor}`}}>
                        {pulse && <div className="cup"> </div>}
                        <ReactSVG
                            beforeInjection={(svg) => {
                                svg.setAttribute('style', `width: 15px; height: 15px; margin-bottom: 5px; fill: 'white'`)
                                }}
                            src={Cup}  alt="cup" 
                        />
                        <div className="div-points">
                            <div className="div-points">
                                <FlipNumbers height={10} width={6} color="white" numberStyle={{fontWeight:700, fontSize:11}} background="transparent" play  numbers={`${animationPoints}`} />
                            <span style={{margin:2}} >pts</span>
                            </div>
                        
                        </div>
                    
                    </div>
                ) : (
                    null
                )
            }
        </>
    )

}

const  _PointsFooter = ({ currentPoints, points = 0, action={},text= 'pts', style = {}}  ) => {
    const [isGamification, setGamification] = useState()
    useEffect(() => {
        setGamification(getGamigication)
    },[])
    return(
        <>
            { isGamification ? 
                (
                    <div className="coins" >
                        <ReactSVG
                            beforeInjection={(svg) => {
                                svg.setAttribute('style', `width: 14px; height: 14px; margin-bottom:3px; fill: 'white'`)
                                }}
                            src={Cup}  alt="cup" 
                        />
                        <div className="div-points" >
                            <span style={style}>{currentPoints} {text}</span>
                        </div>
                  
                    </div>
                ) 
                : (
                    null
                )
            }
        </>
     
    )

}
const mapDispatchToProps = (dispatch) => ({
    updatePoints: (item) => dispatch(updatePoints(item)),
    updateScorePoints: (item) => dispatch(updateScorePoints(item)),
});
const mapStateToProps = store => ({
    currentPoints: store.pointsState.points,
    scorePoints: store.pointsState.scorePoints,
    change: store.pointsState.change
});
const PointsHeader =  connect(mapStateToProps, mapDispatchToProps)(_PointsHeader);
const PointsFooter =  connect(mapStateToProps, mapDispatchToProps)(_PointsFooter);

export {PointsHeader,PointsFooter};