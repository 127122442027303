import React, { Component } from 'react';
import '../index.css';
import { NavLink } from "react-router-dom";
import Ellipsis from '../assets/images/ellipsis.svg';
import MenuPrincipal from './MenuPrincipal';
import { getRoute, getIcon, getSectionByType, getColors, transformText, getDesignById } from '../services/utils';
import { ReactSVG } from 'react-svg'

class Footer extends Component {
    constructor(props){
        super(props)
        this.state={
            expanded: false,
            currentIndexSelected: 0
        }
    }

    _getMenuData(){
        return getSectionByType(1)
    }

    _goToLink(sectionTo){
        try{
            if(getRoute(sectionTo) == '/'){
                return '/'
            }else{
                return getRoute(sectionTo)+'/'+sectionTo
            }
        }catch{
            return '/'
        }
    }

    render(){
        let menuData = this._getMenuData()
        return(
            <div className="navbar navbar-dark fixed-bottom blast-footer" 
            style={{ 
                zIndex: 10, 
                backgroundColor:  getColors('footer').color || getColors("menu").firstColor
                }}
            >
              <div className="blast-footer-container">
                {
                    menuData.menuOption.slice(0,3).map((item, index) => {
                        return(
                            <NavLink 
                                key={index} 
                                to={`/${transformText(item.text)}/${item.sectionTo}`} 
                                exact={true} 
                                className={"foot-icon-container"}
                                activeStyle={{ backgroundColor: getColors('footer').hoverColor || 'rgba(255,255,255,0.1)', borderRadius: 15 }}
                                onClick={()=> this.setState({ expanded: false, currentIndexSelected: index })}
                            >
                                <img src={item.image} style={{ height: 24, width: 24, marginBottom: 5 }} alt="img" />
                                <span 
                                    style={{ 
                                        whiteSpace: 'normal', 
                                        textAlign: 'center', 
                                        color: getColors('footer').fontColor 
                                    }}
                                >
                                        { item.text }
                                </span>
                            </NavLink>
                        )
                    })
                }
                <div className="foot-icon-container more-icon-container" 
                onClick={()=> this.props.menuExpandRef.current.expand() }>
                    <ReactSVG
                        beforeInjection={(svg) => {
                            svg.setAttribute('style', `width: 24px; height: 24px; margin-bottom: 5px; fill: ${getColors('menuItem')?.color || 'white'}`)
                        }} 
                        src={Ellipsis} alt="img"
                    />
                    <span style={{ color: getColors('menuItem')?.color || 'white' }} >Mais</span>
                </div>
                <MenuPrincipal 
                    expanded={this.state.expanded}
                    expand={()=> this.setState({ expanded: !this.state.expanded })}
                    menuData={menuData.menuOption.slice(4)}
                />
              </div>
            </div>
        )
    }
}

export default Footer