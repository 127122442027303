import React from 'react';
import getStyle from './style.js';
import { useHistory } from 'react-router-dom';
import { addProductsToCartLojinha } from '../../../../services/api';
import Button from './Component/Button';
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';

const ProductDetails = function({choosen, isAcquired, isInCart}){
    
    const history = useHistory();
    
    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign);

    const addToCart = async () => {
        const result = await addProductsToCartLojinha(choosen.idOffer);
        if (result.status === 200 && result.data.sucess) {
            history.replace('/cartlojinha');
        }
    }

    const goToCart =  () => {
        history.push('/cartlojinha');
    }

    return (
        <>
            <div style={Style.lojinhaItemFullHeight}>
                <div style={Style.lojinhaItemImage}>
                    <img style={Style.lojinhaItemImageImg} src={choosen.imageURL} alt="" />
                </div>
                <div style={Style.lojinhaItemName}>
                    {choosen.title}
                </div>
                <div style={Style.lojinhaItemDescription}>
                    {choosen.content}
                </div>
                <div style={Style.lojinhaItemPrice}>
                    {Intl.NumberFormat('pt-BR',{ style: "currency", currency: "BRL", useGrouping: true, }).format(choosen.price)}
                </div>
                { isAcquired && (
                    <Button> 
                        Produto adquirido 
                    </Button>
                )}
                { isInCart && (
                    <Button clickHandler={goToCart} >
                        Ir para carrinho 
                    </Button>
                )}
                { (!isAcquired && !isInCart) && (
                    <Button clickHandler={addToCart} > 
                        Adicionar ao carrinho 
                    </Button>
                )}
            </div>
        </>
    );
};

export default ProductDetails;