import { getColors } from '../../../../services/utils';

const getStyle = (getDesign) => {
    const Style = {
        lojinhaCartDynamicInfo:{
            width: '100%',
            height: '100%',
            padding: '10px',
            overflowY: 'auto',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'center',

        },
        
        cartListOverflowWrapper: {
            overflowY: 'auto',
        },
        carrinhoVazio:{
            ...getDesign('itemText'), 
            color: getColors('menu').firstColor,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }
    return Style;
}

export default getStyle;