import React from 'react';
import getStyle from './style.js';
import { ReactComponent as ErrorIcon} from '../../../../../../assets/images/error-icon.svg';
import useDesignByIdSectionType from '../../../../hooks/useDesignByIdSectionType';

const Error = ( { errorHandler } ) => {

    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign);

    return (
        <>
            <div style={Style.lojinhaCartFinishContent}>
                Pagamento não autorizado!
            </div>
            <div style={Style.lojinhaCartFinishContent}>
                <ErrorIcon/>
            </div>
            <div style={Style.lojinhaCartFinishContent}>
                Ops, houve uma falha no processamento da sua compra. Tente novamente. 
            </div>
            <div style={Style.lojinhaCartFinishContent}>
                <button style={Style.lojinhaCartErrorButton} onClick={errorHandler}>
                    Voltar
                </button>
            </div>
        </>
    )
}

export default Error