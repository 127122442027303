import React from 'react';
import { getColors, getDesign } from "../../services/utils";
import { Link } from "react-router-dom";
import playCircle from "@iconify/icons-mdi/play-circle";
import filmstripIcon from "@iconify/icons-mdi/filmstrip";
import pageNext from "@iconify/icons-mdi/page-next";
import pageLayoutHeader from "@iconify/icons-mdi/page-layout-header";
import arrowExpand from "@iconify/icons-mdi/arrow-expand";
import { Icon } from "@iconify/react";


const MultipleFormatComponent = ({item, idSection}) => {

    const SwitchIcon = ({ type }) => {
        let icon = null,
          text = "";
    
        switch (type) {
          case 1:
            icon = playCircle;
            text = "Ouvir";
            break;
          case 2:
            icon = filmstripIcon;
            text = "Assistir";
            break;
          case 3:
            icon = pageLayoutHeader;
            text = "Abrir";
            break;
          case 4:
            icon = pageNext;
            text = "Abrir";
            break;
          case 5:
            icon = pageLayoutHeader;
            text = "Abrir";
            break;
    
          default:
            icon = arrowExpand;
            text = "Abrir";
            break;
        }
    
        return (
          <>
            <Icon className="icon" icon={icon} />
            &nbsp;
            <span>{text}</span>
          </>
        );
      };

    return(
        <Link
            className="multiple-format-list__item--link"
            to={`/content/${item.idContentItem}?idSection=${idSection}`}
            replace
        >
            <div className="multiple-format-list__item--image">
                <img src={item.image} alt="" />
            </div>

            <div className="multiple-format-list__item--info">
                <h3
                    className="title"
                    style={getDesign(idSection, "itemText")}
                >
                    {item.text}
                </h3>
                <p
                    className="author"
                    style={getDesign(idSection, "itemDescription")}
                >
                    <b>Autor:</b> {item.author}
                </p>
                <div
                    className="type-icon"
                    style={{
                    color:
                        getDesign("itemText")?.color ??
                        getColors("menu").fourthColor,
                    }}
                >
                    <SwitchIcon type={item.idContentType} />
                </div>
            </div>
        </Link>
    )
}

export default MultipleFormatComponent