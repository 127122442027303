import React, { useState, useEffect } from 'react';
import ReactStars from 'react-stars';
import { setRating } from '../../../../services/api'
import style from './style.module.scss'
import { getColors } from '../../../../services/utils';

const Rate = ({ isDesktop, userStar, id }) => {

    const [currentUpdate, setCurrentUpdate] = useState('beforeRate');
    const [rate, setRate] = useState(0);
    const userRated = Boolean(rate);

    const startRate = () => {
        setCurrentUpdate('rateAvailable')
    }

    const buttonStyle = {
        backgroundColor: userRated ? getColors('menu').firstColor : "#CCCCCC",
    }
    
    const states = {
        beforeRate: () => {
            return (
                <button className={style.showText} onClick={startRate}>
                    Avalie este conteúdo
                </button>
            );
        },
        rateAvailable: () => {
            return (
                <>
                    <ReactStars
                        count={5}
                        value={rate}
                        half={false}
                        onChange={(newRating) => setRate(newRating)}
                        size={20}
                        color1={'lightgray'}
                        color2={'#ffd700'} 
                    />
                    <button className={style.rateButton} style={buttonStyle} onClick={_setRating}> Enviar </button>
                </>
            )
        },
        thanks: () => {
            return (
                <div className={style.showText}>
                    Obrigado por avaliar este conteúdo 
                </div>
            );
        },
        alreadyRated: () => {
            return (
                <div className={style.showText}>
                    Conteúdo Avaliado
                </div>
            );
        }
    }

    async function _setRating() {
        const data = {
            idContentItem: id,
            vote: rate
        }
        setRating(data).then(res => {
            setCurrentUpdate('thanks')
        }).catch(err => {
            console.log('error: ', err)
        })
    }

    useEffect(() => {
        const rated = Boolean(userStar);

        if(rated){ 
            setCurrentUpdate('alreadyRated') 
            setRate(userStar)
        }
    },[])


    return (
        <div className="rating-container" style={{justifyContent: !isDesktop ? 'flex-end' : 'flex-start'}} >
            { states[currentUpdate]() }
        </div>
    )
}

export default Rate;