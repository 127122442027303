import React, { useState, useEffect } from "react";
import Loader from "../components/Loader";
import api from "../services/api";
import { getDesign, getFooterSize } from "../services/utils";
import "../assets/css/MultipleFormatList.scss";
import MultipleFormatComponent from "../components/MultipleFormatComponent"

function MultipleFormatList({ idSection }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorOnLoading, setErrorOnLoading] = useState(false);

  const getMultipleFormatList = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/content/list/${idSection}`);
      setData(data.content);
    } catch (err) {
      console.log("Error:", err);
      setErrorOnLoading(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMultipleFormatList();
  }, []);

  

  return (
    <div
      className="multiple-format-list"
      style={{ ...getDesign(idSection, "general"), ...getFooterSize() }}
    >
      {loading ? (
        <Loader />
      ) : errorOnLoading ? (
        <div className="multiple-format-list-error">
          Houve algum erro durante o carregamento das informações
        </div>
      ) : (
        <ul className="multiple-format-list__content">
          {data.length > 0 ? (
            data.map((item, index) => (
              <li className="multiple-format-list__item" key={index}>
                <MultipleFormatComponent item={item} idSection={idSection}/>
              </li>
            ))
          ) : (
            <div className="no-content">Nenhum conteúdo disponível</div>
          )}
        </ul>
      )}
    </div>
  );
}

export default MultipleFormatList;
