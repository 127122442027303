import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { contentList } from "../services/api";
import ModalWarning from "./ModalWarning";
import { useHistory } from "react-router-dom"

function RedirectAccess({idSection, denyAction, acceptAction, fromSection}) {
  const [loading, setLoading] = useState(true);
  const [currentLink, setCurrentLink] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getContentList();
  }, []);

  const getContentList = () => {
    contentList(idSection)
      .then((res) => {
        if (res.data.success) {
          setCurrentLink(res.data["content"][0]["contentLocation"]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalWarning
      description={
        loading ? (
          <CircularProgress />
        ) : (
          <div style={{ textAlign: "center" }}>
            Você será redirecionado para {" " + currentLink}
          </div>
        )
      }
      disableAccept={loading}
      acceptAction={() => {
        window.open(currentLink);
        acceptAction();
      }}
      denyAction={fromSection ? history.goBack : denyAction}
    />
  );
}

export default RedirectAccess;
