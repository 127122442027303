import React, { useState } from 'react'
//import * as firebase from 'firebase/app'
import { uploadImageApi } from '../services/api'
import ImageCropper from './ImageCropper'
import { Link } from 'react-router-dom'
import UserIcon from '../assets/images/no-gender-user.svg';





const ImageUpload = ({closeHandle, callbackImage}) => {
    const [blob, setBlob] = useState(null)
    const [inputImg, setInputImg] = useState('')
    const [imgFile, setImgFile] = useState('')

    const getBlob = (blob) => {
        // pass blob up from the ImageCropper component
        setBlob(blob)
    }

    const onInputChange = (e) => {
        // convert image file to base64 string
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            setInputImg(reader.result);
            setImgFile(reader.result);
        }, false)

        if (file) {
           // setImgFile(file);
            reader.readAsDataURL(file)
        }
    }

    const handleSubmitImage = (e) => {
        e.preventDefault();
        uploadFile();
    }

    const uploadFile = () => {
        let addFormat = blob.type;
        if (blob.type.includes("image")) {
            addFormat = blob.type.replace("image/", ".");
        } else {
            console.log('error');
            return;
        }
    
        const filename = "fe_thumb" + Date.now() + addFormat;
        
        let data = new FormData();
        data.append("fileData", blob);
        data.append("fileName", filename);
        
        uploadImageApi(data)
        .then((response) => {
            if (200 === response.status) {
                callbackImage(response.data.url)
            }
        })
        .catch((error) => {
            console.log("error", error);
        });
    }


    return (
        <form onSubmit={handleSubmitImage}>
            <div>
                <input
                    className="input-form-file"
                    type='file'
                    accept='image/*'
                    onChange={onInputChange}
                />
            </div>
            <div className="conteudo-img">
                <img
                    style={{ color: 'white', width: 150, height: 150, position: 'relative', top: 20, left: 105 }}
                    src={UserIcon}
                    alt="img"
                /> 
                {
                    inputImg && (
                        <ImageCropper 
                            getBlob={getBlob}
                            inputImg={inputImg}
                        />
                    )
                }
                <div className="clear-b"></div>
            </div>
            <div className="btn-footer">
                <button 
                    type="button"
                    onClick={closeHandle}
                    style={{ flex: 1, marginRight: 15, borderColor: 'gray' }} 
                    className="btn btn-light"
                >
                    Cancelar
                </button>
                <button 
                    type="submit"
                    style={{ flex: 1, position: 'relative', backgroundColor: '#e9c46a', borderColor: 'gray', color: 'black', float: 'right' }} 
                    className="btn btn-success"
                >
                    Salvar
                </button>
            </div>
        </form>
    )
}

export default ImageUpload;