import SmartphoneIcon from '../assets/images/smartphone.svg'
import Maratonas from '../assets/images/popcorn.png'
import Valentina from '../assets/images/valentina.png'
import Gurus from '../assets/images/gurus.png'
import MapaIcon from '../assets/images/compass.png'
import Podcast from '../assets/images/podcast.png'
import Nerd from '../assets/images/nerd.png'
import Spoiler from '../assets/images/spoiler.png'
import Cabecao from '../assets/images/Cabeção-livro-01.png'
import Lives from '../assets/images/lives.png'
import Usuarios from '../assets/images/usuarios.png'
import Store from '../assets/images/store.png'
import Perfil from '../assets/images/perfil.png'
import { getMenuData, getToken } from '../services/api'
import jwtDecode from 'jwt-decode'
import { Store as ReduxStore } from '../store'

export function hasFooter() {
  const state = ReduxStore.getState();
  const footerStatus = state.footerState.footerStatus
  const headerStatus = state.footerState.headerStatus
  
  if(footerStatus == 'true' && headerStatus == 'true'){
      return true
  }else{
      return false
  }
}

export function getFooterSize() {
    const state = ReduxStore.getState();
    const footerStatus = state.footerState.footerStatus
    const headerStatus = state.footerState.headerStatus
    
    if(footerStatus == 'true' && headerStatus == 'true'){
        return { paddingBottom: '5rem' }
    }else{
        return {}
    }
  }

export function getIcon(name) {
    switch (name) {
        case "Início":
            return SmartphoneIcon
        case "Mapa da Mina":
            return MapaIcon
        case "Nerd":
            return Nerd
        case "Spoiler":
            return Spoiler
        case "Maratonas":
            return Maratonas
        case "Gurus":
            return Gurus
        case "Arya":
            return Valentina
        case "Cabeção":
            return Cabecao
        case "Podcasts":
            return Podcast
        case "Live":
            return Lives
        case "Usuários":
            return Usuarios
        case "Store":
            return Store
        case "Perfil":
            return Perfil
        default:
            return SmartphoneIcon
    }
}

export function getAppId() {
    try {
        let token = getProfileInfo()
        let tokenId = token.idApp || 1
        const digitos = 7;
        let len = tokenId.toString().length;
        let zeros = "0".repeat(digitos - len);

        return `app${zeros}${tokenId}`
    } catch {
        return "app0000001"
    }
}

export function getRoute(id) {
    switch (id) {
        case 1:
            return "/"
        case 2:
            return "/maratonas"
        case 3:
            return "/gurus"
        case 4:
            return "/arya"
        case 5:
            return "/mapa-da-mina"
        case 6:
            return "/nerd"
        case 7:
            return "/spoiler"
        case 8:
            return "/cabecao"
        case 9:
            return "/podcast"
        case 10:
            return "/live"
        case 11:
            return "/usuarios"
        case 12:
            return "/store"
        case 13:
            return "/perfil"
        case 14:
            return "/ytplaylist"
        default:
            return "/"
    }
}

export function getSectionById(id) {
    let menuData = JSON.parse(localStorage.getItem('menu-data'))

    // return empty object if theres no data 
    if (!menuData) { return { menuOption: [] } }

    let section = menuData.menu.filter(obj => {
        return obj.idSection === parseInt(id)
    })[0]

    // let section = menuData.menu[0]

    // return empty object if theres no data on section
    if (!section) { return { menuOption: [] } }

    return section
}

export function getColors(type) {

    let design = getDesignBySectionType(1)
    if (design) {
        switch (type) {
            case 'image':
                if (design.backgroundImage) {
                    return design.backgroundImage
                } else {
                    return {
                        image: "https://blast.etc.br/common/app0000001/section/backGroudBlast.jpg",
                        showImage: true
                    }
                }
            case 'footer':
                if (design.footer) {
                    return design.footer
                } else {
                    return {
                        color: "#933939",
                        showfooter: false
                    }
                }

            case 'header':
                if (design.header) {
                    return design.header
                } else {
                    return {
                        color: "#861414"
                    }
                }

            case 'home':
                if (design.home) {
                    return design.home
                } else {
                    return { homeType: null }
                }

            case 'statusBar':
                if (design.statusBar) {
                  return design.statusBar
                } else {
                  return {
                    color: "#000"
                  }
                }

            case 'menu':
                if (design.menu) {
                    return design.menu
                } else {
                    // return default bedigital colors in case none is set
                    return {
                        "firstColor": "#620606",
                        "fourthColor": "#f2f2f2",
                        "secondColor": "#861414",
                        "thirdColor": "#861414"
                    }
                }

                
            case 'menuItem':
                if (design.menuItem) {
                    return design.menuItem
                } else {
                    // return default bedigital colors in case none is set
                    return {
                    }
                }

            default:
                return {}
        }
    } else {
        return {}
    }
}

export function getSectionByType(type) {
    let menuData = JSON.parse(localStorage.getItem('menu-data'))

    // return empty object if theres no data 
    if (!menuData) { return { menuOption: [] } }
    if (!menuData.menu) { return { menuOption: [] } }

    let section = menuData.menu.filter(obj => {
        return obj.idSectionType === parseInt(type)
    })[0]

    // let section = menuData.menu[0]

    // return empty object if theres no data on section
    if (!section) { return { menuOption: [] } }

    return section
}

export function getProfileInfo(urlToken) {
  let token = {};
  try {
    token = jwtDecode(urlToken || getToken());
  } catch {
    console.log("error on token get");
  }
  return token;
}

export function getPoints() {
    /*let token = {}
    try {
        token = jwtDecode(getToken())
    } catch {
        console.log('error on token get')
    }*/
    return 100;
}

 export function getGamigication(){
    return getMenuData()?.loginInfo?.gamification === 1
}

export function getAppType() {
  const appDetails = JSON.parse(localStorage.getItem('appDetails'))
  return appDetails?.idAppType
}

export function getAllDesign() {
    let menuData = JSON.parse(localStorage.getItem('menu-data'))
    return menuData.design
}

export function getDesignBySectionType(idType) {
    let menuData = JSON.parse(localStorage.getItem('menu-data'))

    // return empty object if theres no data 
    if (!menuData) { return {} }
    if (!menuData.design) { return {} }

    let section = menuData.design.filter(obj => {
        return obj.idSectionType === parseInt(idType)
    })[0]

    // return empty object if theres no data on section
    if (!section) { return {} }

    return section.format
}


export function getDesignById(id) {
    let menuData = JSON.parse(localStorage.getItem('menu-data'))

    // return empty object if theres no data 
    if (!menuData) { return {} }
    if (!menuData.design) { return {} }

    let section = menuData.design.filter(obj => {
        return obj.idSection === parseInt(id)
    })[0]

    // return empty object if theres no data on section
    if (!section) { return {} }

    return section.format
}

export function transformText(text) {
    return text?.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export function getPathBySectionId(id) {
    const menus = getAllMenuOptions()

    const sectionName = menus?.find(e => e.sectionTo === parseInt(id))?.text
    if (!sectionName) return null
    return `/${transformText(sectionName)}/${id}` || null
}

export function getAllMenuOptions() {
    let allMenu = getMenuData()
    if (allMenu === null || typeof allMenu === "undefined") { return [] }
    if (!allMenu.menu) { return [] }
    allMenu = allMenu.menu
    let allMenuOptions = []

    allMenu.forEach(element => {
        element.menuOption.forEach(elem => {
            allMenuOptions.push({...elem, parentIdSection: element.idSection})
        })
    });
    return allMenuOptions
}

export function getDesign(idSection, item, only = false, valueOnly = false){
  let design = getDesignById(idSection)

  if(!design[item]){return null} // return null if theres no item specified

  if(only){ // parameter only means it will only return the property specified
      if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified

      design = { [only]:design[item][only] }
      return design
  }

  return design[item]
}

export function getInactiveTime(){
    let menuData = JSON.parse(localStorage.getItem('menu-data'))

    // default value 5 minutes
    if (!menuData) { return 5 * 1000 * 60 }
    if (!menuData.loginInfo) { return 5 * 1000 * 60 }   
    return menuData.loginInfo.inactiveTime ?  parseInt(menuData.loginInfo.inactiveTime) * 1000 * 60 : 5 * 1000 * 60;
}

export function validImage(image){
    return (image && image !== 'null')
}
