import React, { useEffect } from "react";
import { transformText, getSectionById, getDesignById, getColors, getFooterSize } from '../services/utils';
import { withRouter, Link, Route } from 'react-router-dom';
import ReactPlayer from "react-player";
import PlayCircle from '../assets/images/play-circle.svg';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';

function _getMenuData(idSection) {
    let data = getSectionById(idSection)
    return data.menuOption
}

function _getDesign(id, item, only = false, valueOnly = false){
    let design = getDesignById(id)

    if(!design[item]){return null} // return null if theres no item specified

    if(only){ // parameter only means it will only return the property specified
        if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified

        design = { [only]:design[item][only] }
        return design
    }

    return design[item]
}

// return the main item style if the first is not defined
function getFirstDesign(idSection, firstItem, item){
    if(_getDesign(idSection, firstItem) == null){
        return _getDesign(idSection, item)
    }else{
        return _getDesign(idSection, firstItem)
    }
}

function MosaicWithVideo({ idSection, updateFooterStatus, updateHeaderStatus }) {
    useEffect(() => {
        updateFooterStatus(_getDesign(idSection, 'general').showBottomMenu)
        updateHeaderStatus(_getDesign(idSection, 'general').showTopBar)
    }, [])
    return (
        <div className="container-mosaic with-video" style={ {..._getDesign(idSection, 'general'), ...getFooterSize()} }>
            {
                _getMenuData(idSection).map((item, index) => {
                    return (
                        item.content && (item.content != 'null' || item.content == null) ?
                        <div className="tile" key={index}>
                            {
                                item.showText == 1 &&
                                <div className="mosaic-text">
                                    <div style={ index == 0 ? getFirstDesign(idSection, 'firstItemText', 'itemText') :  _getDesign(idSection, 'itemText') } >
                                        <span> {item.text} </span>
                                    </div>
                                    <div style={ index == 0 ? _getDesign(idSection, 'firstItemDescription') :  _getDesign(idSection, 'itemDescription') }>
                                        <span> {item.description}</span>
                                    </div>
                                </div>
                            }
                            {
                                <ReactPlayer
                                    url={item.content}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                    className="video-container-mosaic"
                                    light
                                    playIcon={
                                        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
                                            <img src={PlayCircle} className="video-player-thumb" alt="img" />
                                            <img src={item.image} style={{ height: '100%', width: '100%' }} alt="img" />
                                        </div>
                                    }
                                />
                            }
                            
                        </div>
                        :
                        item.imageStyle == 'tile' ?
                        <Link to={`/${transformText(item.text)}/${item.sectionTo}?idSection=${item.sectionTo}`} className="tile" key={index}>
                            <div className="gradient-support" />
                            {
                                item.showText == 1 &&
                                <div className="mosaic-text">
                                    <div style={ index === 0 ? getFirstDesign(idSection, 'firstItemText', 'itemText') :  _getDesign(idSection, 'itemText') } >
                                        <span> {item.text} </span>
                                    </div>
                                    <div style={ index === 0 ? _getDesign(idSection, 'firstItemDescription') :  _getDesign(idSection, 'itemDescription') }>
                                        <span> {item.description}</span>
                                    </div>
                                </div>
                            }
                            <img src={item.image} style={{ minWidth: 165, minHeight: 200, backgroundColor: 'lightgray' }} alt="img" />
                            
                        </Link>
                        :
                        // icon imageStyle
                        <Link to={`/${transformText(item.text)}/${item.sectionTo}?idSection=${item.sectionTo}`} className="mosaic-icon" key={index}>
                            <div className="mosaic-support" style={{ backgroundColor: item.color && item.color !== 'null' ? item.color : getColors('menu').fourthColor }} />
                            {
                                item.showText == 1 &&
                                <div className="mosaic-text">
                                    <div style={ index === 0 ? getFirstDesign(idSection, 'firstItemText', 'itemText') :  _getDesign(idSection, 'itemText') } >
                                        <span> {item.text}</span>
                                    </div>
                                    <div style={ index === 0 ? _getDesign(idSection, 'firstItemDescription') :  _getDesign(idSection, 'itemDescription') }>
                                        <span> {item.description}</span>
                                    </div>
                                </div>
                            }
                            <img src={item.image} alt="img" />
                        </Link>
                    )
                })
            }

        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
  });

export default connect(null, mapDispatchToProps)(MosaicWithVideo);
