import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import styles from "./styles.module.scss"

const ImageLoader = ({ 
  src, 
  secondarySrc = null, 
  srcStyle = {},
  loadingStyle = {},
}) => {
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 7000);
  }, []);

  return (
    <>
      <img
        className="image-loader"
        onLoad={() => setLoading(false)}
        alt="Imagem do mosaico"
        src={src}
        style={srcStyle}
      />
      {loading && (
        <div className={styles.loadingDiv} style={loadingStyle}>
          <CircularProgress color="inherit" />
        </div>
      )}
      {secondarySrc && (
        <img
          src={secondarySrc}
          className={styles.secondaryImg}
          style={{
            width: "auto",
            height: "auto",
            display: loading ? "none" : "inline",
          }}
          alt="Secondary"
        />
      )}
    </>
  );
};

export default ImageLoader;
