import React from 'react';
import '../assets/css/VideoList.css';
import { Link } from 'react-router-dom';
import { contentList } from '../services/api';
import { getDesignById, getFooterSize } from '../services/utils'
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';

class VideoList extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = true;
        this.state = {
            isLoading: false,
            data: [],
            noData: false
        };
    }

    _getDesign(item, only = false, valueOnly = false){
        let design = getDesignById(this.props.idSection)
    
        if(!design[item]){return null} // return null if theres no item specified
    
        if(only){ // parameter only means it will only return the property specified
          if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified
    
          design = { [only]:design[item][only] }
          return design
        }
    
        return design[item]
      }

    async _getContent(id) {
        this.setState({ isLoading: true })
        const result  = await contentList(id);
        if (this._isMounted) {
          try {
            if(!result.data.success){
              this.setState({ isLoading: false, noData: true })
              return
            }
            this.setState({
              isLoading: false,
              data: result.data.content,
              noData: false
          })
        
          } catch (err) {
            this.setState({ isLoading: false, noData: true })

          } 
        }
    }

    componentDidMount() {
        const { updateHeaderStatus, updateFooterStatus } = this.props
        this._getContent(this.props.idSection)
        updateFooterStatus(this._getDesign('general')?.showBottomMenu || 'true')
        updateHeaderStatus(this._getDesign('general')?.showTopBar || 'true')
    }
    componentWillUnmount(){
      this._isMounted = false;
    }
    getCardlineDesing(index) {
      const items = this.state.data.length
      const screenWidth = window.screen.width

      if(screenWidth <= 768) {
        return { "minWidth": "calc(100% - 8px)" }
      } else {
        if (items === 1 && index === 0) {
          return { "height": "calc(100vh - 142px)" }
        }
        else if (items < 4 && index === 0) {
          return { "minWidth": "100%" }
        } else if (items > 4 && index === 0) {
          return { "minWidth": "calc(66.66% - 4px)" }
        }
      }
    }

    render() {
        return (
            <div className="container-fluid cards-container" style={ {...this._getDesign('general'), ...getFooterSize()} }>
              {
                this.state.noData ?
                  <div className="post-list-no-data">
                      <span> Nenhum conteúdo disponível </span>
                  </div>
                :
                  <div className="row">
                    {
                      this.state.isLoading ?
                        <div className="loader loader-center loader-big"></div>
                      :
                        this.state.data.map((item, index) => (
                          <Link 
                            className="cardline item" key={index}
                            style={{ backgroundImage: `url(${item.image})`, ...this.getCardlineDesing(index), position:'relative' }} 
                            to={`/content/${item.idContentItem}?idSection=${this.props.idSection}`} 
                          >
                            <div className="title-front-card gradient-background" style={ { ...this._getDesign('itemText'), margin: 0 }}>
                              <span> {item.text} </span>
                            </div>
                          </Link>
                        ))
                    }
                  </div>
              }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
  });

export default connect(null, mapDispatchToProps)(VideoList);