
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import AddCircleIcon from "@material-ui/icons/AddCircle";

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import jwtDecode from 'jwt-decode';
import {
  // getGroupList,
  getSchedules,
  getSchedulesTypes,
  getToken
}
from "../../services/api";

import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles';

import './styles.scss';

import 'moment/locale/pt-br';

const DragAndDropCalendar = withDragAndDrop(Calendar)


function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


let allViews = Object.keys(Views).map(k => Views[k])


// function getProfileInfo(){
//   let token = {}
//   try {
//       token = jwtDecode(getToken())
//   }catch{
//       console.error('error on token get')
//   }
//   return token
// }

const localizer = momentLocalizer(moment)

const Agenda = (props) => {

  // const dataUser = getProfileInfo();

  const appDetails = JSON.parse(localStorage.getItem('appDetails'));

  const eventStyleGetter = (event) => {
      var style = {
        backgroundColor: appDetails.primaryColor,
        color: appDetails.loginTextColor,
      };
      return {
          style: style
      };
  };

  const [contentModal, setContentModal] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    p: 4,
  };

  // const [listGroupList,setListGroupList] = useState('');
  // useEffect(() => {  
  //   if( listGroupList == '' ){
  //     getGroupList()
  //     .then((response) => {

  //       let listagem = [];
  //       response.map( (value, index) => { listagem[value.idGroups] = value } );
  //       setListGroupList(listagem);

  //     })
  //     .catch((error) => {
        
  //     });
  //   }
  // }, [ listGroupList ]);


  const [listTypes,setListTypes] = useState('');
  useEffect(() => {  
    if( listTypes == '' ){
      getSchedulesTypes()
      .then((response) => {

        let listagem = [];
        response.map( (value, index) => { listagem[value.idScheduleType] = value } );
        setListTypes(listagem);

      })
      .catch((error) => {
        
      });
    }
  }, [ listTypes ]);

  const [eventsList,setEventsList] = useState([]);  
  useEffect(() => {
    getSchedules(setEventsList);
  }, []);

  const [state,setState] =  useState({
      events: eventsList,
      displayDragItemInCell: true,
  });


  useEffect(() => {

    if( eventsList ){
      eventsList.map( (item, index )  => {
        
        let dt = (item.startDate).replace('Z','').replace('z','');
        let dateItemStart = new Date(dt);
        eventsList[index].start = dateItemStart;

        let dtEnd = (item.endDate).replace('Z','').replace('z','');
        let dateItemEnd = new Date(dtEnd);
        eventsList[index].end = dateItemEnd;

        eventsList[index].id = item.idSchedule;

      });
      setState({...state,events: eventsList});
    }
 

}, [eventsList]);


const infoEvent = (e) => {
  let title = e.title;
  let tipo = ( e.idScheduleType ? ' - Tipo: ' + listTypes[e.idScheduleType].description : null );
  // let user = "";
  // if( e.idUser  ){
  //   user =' - Usuário: ' + dataUser.name;
  // }
  // let grupos = ' - Grupos:' + ( (e.groups).length > 0 ? (e.groups).map( (value,index)=> ( Object.keys(listGroupList).includes( value.toString() ) ? ' ' + listGroupList[value].title : null ) ) : ' Todos' );
  let url = ( typeof e.url != 'undefined' && e.url ? ' - Url: ' + e.url : '' );
  let descricao = ( typeof e.description != 'undefined' && e.description && e.description != "null" ? ' - Descrição: ' + e.description : '' );
  return title + tipo + url + descricao;
}

const infoEventModal = (e) => {
  let title = e.title;
  let tipo = ( e.idScheduleType ? listTypes[e.idScheduleType].description : null );
  // let grupos = ( (e.groups).length > 0 ? (e.groups).map( (value,index)=> ( Object.keys(listGroupList).includes( value.toString() ) ? ' ' + listGroupList[value].title : null ) ) : ' Todos' );
  let url = ( typeof e.url != 'undefined' && e.url ? e.url : '' );
  let descricao = ( typeof e.description != 'undefined' && e.description && e.description != "null" ?  e.description : '' );
  let data = moment(e.startDate).format('DD/MM/YYYY HH:mm:ss') +' - '+ moment(e.endDate).format('DD/MM/YYYY HH:mm:ss');

  // let user = "";
  // if( e.idUser ){
  //   user = dataUser.name;
  // }
  return {title: title, tipo: tipo, descricao: descricao, url: url, data: data};
}


const defaultMessages = {
  date: "Data",
  time: "Hora",
  event: "Evento",
  allDay: "Dia Todo",
  week: "Semana",
  work_week: "Eventos",
  day: "Dia",
  month: "Mês",
  previous: "Anterior",
  next: "Próximo",
  yesterday: "Ontem",
  tomorrow: "Amanhã",
  today: "Hoje",
  agenda: "Agenda",
  noEventsInRange: "Não há eventos no período.",
  showMore: function showMore(total) {
    return "+" + total + " mais";
  }
};




 
  return (
    <div style={{padding: 20,}}>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-description" style={{ textDecoration: 'underline', fontWeight: 'bold', fontSize: 20 }}>
            <i>{contentModal.title}</i>
          </Typography>

        <Typography id="modal-modal-description" style={{ marginTop: 10, fontWeight: 'bold' }}>
          Data
        </Typography>
        <Typography id="modal-modal-description">
          {contentModal.data}
        </Typography>

        <Typography id="modal-modal-description" style={{ marginTop: 10, fontWeight: 'bold' }}>
          Tipo
        </Typography>
        <Typography id="modal-modal-description">
          {contentModal.tipo}
        </Typography>
          
          { contentModal.url &&
            (
              <>
                <Typography id="modal-modal-description" style={{ marginTop: 10, fontWeight: 'bold' }}>
                  Url
                </Typography>
                <Typography id="modal-modal-description">
                  <a href={contentModal.url} target="_blank">{contentModal.url}</a>
                </Typography>
              </>
            )
          }

          { contentModal.descricao &&
            (
              <>
                <Typography id="modal-modal-description" style={{ marginTop: 10, fontWeight: 'bold' }}>
                  Descrição
                </Typography>
                <Typography id="modal-modal-description">
                  {contentModal.descricao}
                </Typography>
              </>
            )
          }

          <Button onClick={handleClose} style={{ background: appDetails.primaryColor, marginTop: 10, color: appDetails.loginTextColor }}>Ok</Button>

        </Box>
      </Modal>

      <Calendar

        eventPropGetter={(e) => eventStyleGetter(e) }

        messages={defaultMessages}

        localizer={localizer}
        events={state.events}
        showMultiDayTimes={true}

        startAccessor="start"
        endAccessor="end"
        
        tooltipAccessor={(e) => infoEvent(e) }
        titleAccessor={(e) => infoEvent(e) }

        onSelectEvent={ (e)=>{ setContentModal(infoEventModal(e)); setOpen(true); }}

        style={{ height: 500 }}

      />


    </div>
  );
};

export default withRouter(Agenda);
