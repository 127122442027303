import React from 'react'

import {ReactComponent as NoGenderIcon} from '../assets/images/no-gender-user.svg';

const UserPhoto = ({ photo }) => {

    const havePhoto = Boolean(photo)
    
    return (havePhoto) ? 
    (
        <img className="foto-capa-perfil"
            style={{ color: 'white', width: "100%" }}
            src={photo}
            alt="img"
        /> 
    )
    :
    (
        <NoGenderIcon style={{ color: 'white', width:"100%"  }} />
    );

}

export default UserPhoto;